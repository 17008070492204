import React from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { change, submit } from 'redux-form'
import UserForm from '../form/UserForm'
import { deleteUser, loadUser, updateUser } from '../../../actions/userActions'
import { USER_FORM } from '../../../services/forms'
import { uploadAvatar } from '../../../services/http'
import FormTop from '../../form/FormTop'
import DataProviderFunctional from '../../loader/DataProviderFunctional'
import TextButton from '../../general/TextButton'

const UserEditPresentation = () => {
  const { id } = useParams()
  const initialValues = useSelector((state) => state.users.items[id])
  const saveState = useSelector((state) => state.user.saveState)
  const dispatch = useDispatch()
  const onSubmit = (data) => dispatch(updateUser(data))
  const remoteSubmit = () => dispatch(submit(USER_FORM))
  const upload = uploadAvatar
  const remove = () => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteUser(id))
    }
  }
  const removePhoto = () => dispatch(change(USER_FORM, 'avatar', ''))

  return (
    <div>
      <div className="controls">
        <div className="form-top left-right-block flex-box-centered shadow">
          <div className="left-title"></div>
          <div className="flex-box-centered">
            {remove && <TextButton label="Remove" onClick={remove} remove={true} />}
          </div>
        </div>
        <FormTop
          title="Edit user profile"
          button="Save Profile"
          remote={remoteSubmit}
          remove={remove}
          saveState={saveState}
        />
      </div>
      <UserForm onSubmit={onSubmit} initialValues={initialValues} upload={upload} removePhoto={removePhoto} />
    </div>
  )
}

const UserEdit = () => {
  const loading = useSelector((state) => state.user.state)
  const error = useSelector((state) => state.user.error)
  const dispatch = useDispatch()
  const { id } = useParams()
  const load = () => dispatch(loadUser(id))

  return <DataProviderFunctional error={error} load={load} loading={loading} component={UserEditPresentation} />
}

export default UserEdit
