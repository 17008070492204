import React from 'react'
import ExhibitionForm from '../form/ExhibitionForm'
import { deleteExhibition, updateExhibition } from '../../../actions/exhibitionsActions'
import { loadExhibition } from '../../../actions/exhibitionsActions'
import { loadPersons } from '../../../actions/personsActions'
import { EXHIBITION_FORM } from '../../../services/forms'
import { change, isDirty, submit } from 'redux-form'
import NavigationPrompt from '../../general/NavigationPrompt'
import { exhibitionModel } from '../../../models/exhibitionModel'
import ExhibitionControls from './ExhibitionControls'
import { loadInstitutions } from '../../../actions/institutionsActions'
import DataProviderFunctional from '../../loader/DataProviderFunctional'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

const ExhibitionEditPresentation = () => {
  const { id } = useParams()
  const initialValues = useSelector((state) => getInitialValues(state, id))
  const lang = useSelector((state) => state.translations.language)
  const saveState = useSelector((state) => state.exhibitions.saveState)
  const dirty = useSelector((state) => isDirty(EXHIBITION_FORM)(state))
  const persons = useSelector((state) => state.persons.items)
  const institutions = useSelector((state) => state.institutions.items)
  const dispatch = useDispatch()
  const onSubmit = (data) => dispatch(updateExhibition(data))
  const remove = () => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteExhibition(id))
    }
  }
  const changeFieldValue = (field, value) => dispatch(change(EXHIBITION_FORM, field, value))
  const remoteSubmit = () => dispatch(submit(EXHIBITION_FORM))

  return (
    <div>
      <ExhibitionControls
        remoteSubmit={remoteSubmit}
        remove={remove}
        saveState={saveState}
        changeFieldValue={changeFieldValue}
        initialValues={initialValues}
      />

      <div className="Exhibition-grid">
        <NavigationPrompt when={dirty} />

        <ExhibitionForm
          lang={lang}
          onSubmit={onSubmit}
          changeFieldValue={changeFieldValue}
          initialValues={initialValues}
          persons={persons}
          institutions={institutions}
        />
      </div>

      <div />
    </div>
  )
}

const getInitialValues = (state, id) => {
  let data = state.exhibitions.items[id] ? state.exhibitions.items[id] : exhibitionModel

  return data
}

const ExhibitionEdit = () => {
  const loading = useSelector((state) => state.exhibitions.state)
  const error = useSelector((state) => state.exhibitions.error)
  const dispatch = useDispatch()
  const { id } = useParams()
  const load = () => {
    dispatch(loadPersons())
    dispatch(loadInstitutions())
    dispatch(loadExhibition(id))
  }
  return <DataProviderFunctional error={error} load={load} loading={loading} component={ExhibitionEditPresentation} />
}

export default ExhibitionEdit
