import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { change, Field, FieldArray } from 'redux-form'
import { IconButton, Tooltip } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Select from 'react-select'
import propOr from 'crocks/helpers/propOr'
import Modal from 'react-modal'
import { loadExhibitionsBasic } from '../../actions/exhibitionsActions'
import { ReferenceField } from './ReferenceField'
import { ARTWORK_FORM } from '../../services/forms'
import OpenClose from '../general/OpenClose'
import DataProviderFunctional from '../loader/DataProviderFunctional'
import OutlinedButton from '../general/OutlinedButton'
import TextButton from '../general/TextButton'
import { COLOR_LIGHT_TEXT } from '../../config/colors'
import { CustomTextField } from '../general/CustomTextField'

const filterOptions = (options, filterString, values) => {
  const str = filterString.toLowerCase()
  return options.filter(
    (x) =>
      (x.title && x.title.toLowerCase().includes(str)) ||
      (x.location && x.location.toLowerCase().includes(str)) ||
      (x.from_year && x.from_year.toString().includes(str)) ||
      (x.institution && x.institution.name && x.institution.name.toLowerCase().includes(str))
  )
}

const SearchField = ({ input, items, changeFieldValue, fields, close }) => (
  <div className="select-exhibition">
    <Select
      wrapperStyle={{ width: '100%' }}
      //id="persons"
      name="persons"
      placeholder="Search"
      options={items}
      filterOptions={filterOptions}
      labelKey="label"
      valueKey="id"
      value={null}
      onChange={(value) => {
        //changeFieldValue(input.name, propOr(undefined, 'id', value))
        fields.push(items.filter((item) => item.id === propOr(undefined, 'id', value))[0])
        close()
      }}
      clearable={false}
      searchable={true}
      arrowRenderer={null}
    />
  </div>
)

const SearchBlock = ({ fields, close }) => {
  let items = useSelector((state) => state.exhibitions.items)
  const exhibitions = Object.keys(items).map((id) => {
    return { ...items[id], label: `${items[id].title} - ${items[id].location}` }
  })
  const dispatch = useDispatch()
  const changeFieldValue = (field, value) => dispatch(change(ARTWORK_FORM, field, value))

  return (
    <div className="flex-box artwork-row select-reference">
      <Field
        name={`search.id`}
        fullWidth={true}
        fields={fields}
        hintText="Exhibition"
        changeFieldValue={changeFieldValue}
        items={Object.keys(exhibitions).map((id) => exhibitions[id])}
        component={SearchField}
        close={close}
      />
    </div>
  )
}

const ExhibitionField = ({ member, index, fields, lang, exhibitions, changeFieldValue }) => (
  <div key={index} className="flex-box artwork-row select-reference repeater-field">
    <div className="repeater-field">
      <div className="flex-box-centered reference-holder">
        <span style={{ lineHeight: '24px' }} className="reference-span">
          {fields.get(index).title +
            ', ' +
            fields.get(index).from_day +
            '.' +
            fields.get(index).from_month +
            '.' +
            fields.get(index).from_year +
            ' - ' +
            fields.get(index).to_day +
            '.' +
            fields.get(index).to_month +
            '.' +
            fields.get(index).to_year +
            ', ' +
            fields.get(index).location +
            ', '}
        </span>
      </div>
    </div>
    <div>
      <div className="field-label--side label--basic" style={{ width: '60px' }}>
        Page:
      </div>
      <Field
        id={`exhibitions[${index}].page-input`}
        name={`exhibitions[${index}].page`}
        width="short"
        component={CustomTextField}
        label="Page"
        hideLabel={true}
      />
    </div>
    <div>
      <div className="field-label--side label--basic" style={{ width: '140px', margin: '0 32px 0 55px' }}>
        Catalogue number:
      </div>
      <Field
        id={`exhibitions[${index}].catalogue_no-input`}
        name={`exhibitions[${index}].catalogue_no`}
        width="short"
        component={CustomTextField}
        label="Catalogue number"
        hideLabel={true}
      />
    </div>
    <ReferenceField name={`references.exhibitions_${index}`} formType={ARTWORK_FORM} />
    <Tooltip title="Remove">
      <IconButton className="repeater-remove" onClick={() => fields.remove(index)}>
        <Close style={{ color: COLOR_LIGHT_TEXT }} />
      </IconButton>
    </Tooltip>
  </div>
)

const ExhibRepeater = (Component) => (props) =>
  (
    <div>
      <div className="section-title">Exhibitions</div>
      <div className="repeater">
        {props.fields.map((member, index, fields) => Component({ member, index, fields, ...props }))}
      </div>
    </div>
  )

const Exhibitions = ExhibRepeater(ExhibitionField)

const ExhibitionsFieldsPlus = (props) => (
  <OpenClose>
    {({ isOpen, open, close }) => (
      <div>
        <Exhibitions {...props} />
        <div className="reference-holder">
          <OutlinedButton color="secondary" onClick={open} label="Add exhibition" />

          <Modal
            isOpen={isOpen}
            onRequestClose={close}
            className={{
              base: 'modal',
              afterOpen: 'modal-open exhibition-modal',
              beforeClose: 'modal-close',
            }}
            overlayClassName={{
              base: 'modal-overlay',
              afterOpen: 'modal-overlay-open',
              beforeClose: 'modal-overlay-close',
            }}
          >
            <div className="field-label">Search exhibitions</div>
            <SearchBlock {...props} close={close} />
            <div className="right-controls">
              <TextButton color="primary" label="Close" onClick={() => close()} type="button" />
            </div>
          </Modal>
        </div>
      </div>
    )}
  </OpenClose>
)

const WithExhibitions = (props) => {
  const loading = useSelector((state) => state.exhibitions.state)
  const error = useSelector((state) => state.exhibitions.error)
  const dispatch = useDispatch()
  const load = () => dispatch(loadExhibitionsBasic(1)) // hardcoded page

  return (
    <DataProviderFunctional error={error} load={load} loading={loading} component={ExhibitionsFieldsPlus} {...props} />
  )
}

export const ArtworkExhibitions = () => <FieldArray name="exhibitions" component={WithExhibitions} />
