import React from 'react'
import { useSelector } from 'react-redux'
import {  Avatar, IconButton } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { useStyles } from '../users/user/User'
import { UserMenu } from './UserMenu'

export const Logged = () => {
  const user = useSelector((state) => state.users.items[state.auth.user.id])
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    user !== undefined && (
      <div className="user-menu">
        <IconButton aria-controls="user-menu" aria-haspopup="true" onClick={handleClick}>
          <Typography color="secondary" className={classes.userLabel}>{user.name}</Typography>
          <Avatar className={classes.small} src={user.avatar} />
        </IconButton>
        <UserMenu setAnchorEl={setAnchorEl} anchorEl={anchorEl} />
      </div>
    )
  )
}