import React from 'react'
import { MenuItem } from '@material-ui/core'
import { fieldStyle } from '../../form/style'
import { Field, reduxForm } from 'redux-form'
import { DOCUMENT_FORM, documentFormSelector } from '../../../services/forms'
import DocumentPages from './DocumentPages'
import { DOCUMENT_SUBTYPES, DOCUMENT_TYPES } from '../../../config/config'
import { useSelector } from 'react-redux'
import './DocumentForm.css'
import { DocumentRecipients, DocumentRecipientsInst } from './DocumentsRecipients'
import { DocumentAuthors, DocumentAuthorsInst } from './DocumentsAuthors'
import { AutoComplete } from './AutoComplete'
import { CustomSelect } from '../../general/CustomSelect'
import { CustomTextField } from '../../general/CustomTextField'

const DocumentFormPresentation = ({
  handleSubmit,
  lang,
  upload,
  changeFieldValue,
  initialValues,
  type = 'manuscript',
}) => {
  const currentType = useSelector((state) => documentFormSelector(state, 'type'))
  const persons = useSelector((state) => state.provenancePersons.items)
  const documentPersons = Object.values(persons).map((i) => ({
    id: i.id,
    name: `${i.firstname} ${i.lastname} (b. ${i.birth_year})`,
  }))

  return (
    <form className="document-form" onSubmit={handleSubmit}>
      <div className="section-inside">
        <h1>Basic information: </h1>
        <div className="row flex-box">
          <div className="repeater-field">
            <div className="field-label">Type</div>
            <Field name="type" component={CustomSelect}>
              {DOCUMENT_TYPES.map((item, i) => (
                <MenuItem key={i} value={item.value}>
                  {item.title}
                </MenuItem>
              ))}
            </Field>
          </div>
          <div className="repeater-field">
            <div className="field-label">Subtype</div>
            <Field name="subtype" component={CustomSelect}>
              {DOCUMENT_SUBTYPES[type].map((item, i) => (
                <MenuItem key={i} value={item.value}>
                  {item.title}
                </MenuItem>
              ))}
            </Field>
          </div>
        </div>
        {currentType === 'manuscript' ? (
          <div className="row flex-box">
            <div className="repeater-field">
              <Field id="title-input" name="title" label="title" component={CustomTextField} type="text" />
            </div>
            <div className="repeater-field">
              <Field
                id="title_en-input"
                name="title_en"
                label="Title translation"
                component={CustomTextField}
                type="text"
              />
            </div>
          </div>
        ) : null}

        <div className="row flex-box">
          <div className="repeater-field">
            <div className="field-label">Date</div>
            <p className="field-description">Eg.: "1923-08-03", "1923-08", "1923 (sommar)", "1923-1933"</p>
            <Field id="date-input" name="date" label="Date" hideLabel={true} component={CustomTextField} type="text" />
          </div>
          <div className="repeater-field">
            <div className="field-label">Date EN</div>
            <p className="field-description">Eg.: "1923-08-03", "1923-08", "1923 (summer)", "1923-1933"</p>
            <Field
              id="date_en-input"
              name="date_en"
              label="Date EN"
              hideLabel={true}
              component={CustomTextField}
              type="text"
            />
          </div>
        </div>
        <div className="row flex-box">
          <DocumentAuthors />
          <DocumentAuthorsInst title="Author (Institution)" />
        </div>
        <div className="row flex-box">
          {currentType === 'personal_communication' && <DocumentRecipients title="Recipient" />}
          {currentType === 'personal_communication' && <DocumentRecipientsInst title="Recipient (Institution)" />}
        </div>
        <div className="row">
          <div className="field">
            <Field id="pages-input" name="pages" min="1" label="Pages" component={CustomTextField} type="number" />
          </div>
        </div>
        <div className="row">
          <div className="field">
            <Field id="language-input" name="language" label="Language" component={CustomTextField} type="text" />
          </div>
        </div>
        <div className="row">
          <div className="field">
            <Field
              id="accession_number-input"
              name="accession_number"
              label="Accession Number"
              component={CustomTextField}
              type="text"
              width="full"
            />
          </div>
          <div className="field">
            <Field id="label-input" name="label" label="Label" component={CustomTextField} type="text" width="full" />
          </div>
          <div className="field">
            <Field
              id="name_of_database-input"
              name="name_of_database"
              label="Name of Database"
              component={CustomTextField}
              type="text"
              width="full"
            />
          </div>
          <div className="field">
            <Field id="url-input" name="url" label="URL" component={CustomTextField} type="text" width="full" />
          </div>
        </div>

        <div className="row">
          <div className="field">
            <div className="field-label">Transcriber</div>
            <Field
              name={'transcriber'}
              persons={documentPersons}
              changeFieldValue={changeFieldValue}
              {...fieldStyle}
              component={AutoComplete}
            />
          </div>
          <div className="field">
            <div className="field-label">Translator</div>
            <Field
              name={'translator'}
              persons={documentPersons}
              changeFieldValue={changeFieldValue}
              {...fieldStyle}
              component={AutoComplete}
            />
          </div>
        </div>
      </div>
      <div className="section-inside">
        <DocumentPages upload={upload} lang={lang} changeFieldValue={changeFieldValue} />
      </div>
    </form>
  )
}

const formProps = {
  form: DOCUMENT_FORM,
  destroyOnUnmount: false,
  enableReinitialize: true,
  //validate,
}

const DocumentForm = reduxForm(formProps)(DocumentFormPresentation)

export default DocumentForm
