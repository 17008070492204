import React from 'react'
import { Select, makeStyles } from '@material-ui/core'
import { COLOR_BLACK_LIGHT, COLOR_GRAY_DARK, COLOR_GRAY_MID, COLOR_WHITE } from '../../config/colors'

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    width: props.styles?.width || 256,
    '&.MuiInput-underline:before': {
      borderBottom: '1px solid transparent',
      padding: '0 16px',
    },
    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '3px solid transparent',
    },
    '& .MuiInputBase-input': {
      border: `1px solid ${COLOR_GRAY_DARK}`,
      height: 48,
      lineHeight: '48px',
      padding: '0 0 0 16px',
      background: COLOR_GRAY_MID,
    },
    '& .MuiSelect-icon': {
      fill: theme.palette.text.primary,
      right: 12,
    },
    '&.MuiInput-underline:after': {
      borderBottom: `3px solid transparent`,
    },
    ...props.styles,
  }),
  menu: {
    color: COLOR_BLACK_LIGHT,
    backgroundColor: COLOR_WHITE,
    borderRadius: 2,
  },
}))

export const CustomSelect = ({ input, label, meta: { touched, error }, children, styles, ...custom }) => {
  const classes = useStyles({ styles })

  return (
    <Select
      className={classes.root}
      {...input}
      {...custom}
      inputProps={{
        name: input.name,
        id: `${input.name}-select`,
      }}
      MenuProps={{
        classes: {
          paper: classes.menu,
        },
      }}
      //value={type}
      // onChange={() => {
      //   changeFieldValue('type', input.value)
      // }}
    >
      {children}
    </Select>
  )
}
