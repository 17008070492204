import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AUTH_STATE_LOGGED_IN, AUTH_STATE_UNKNOWN } from '../../reducers/authReducer'
import { authInit } from '../../actions/authActions'
import { useHistory, useLocation } from 'react-router-dom'
import * as router from '../../services/router'

const AuthProvider = ({ loggedIn, loggedOut }) => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state.auth.state)
  const history = useHistory()
  const location = useLocation()
  
  useEffect(() => {
    dispatch(authInit())
  }, [dispatch])

  useEffect(() => {
    if (state === AUTH_STATE_LOGGED_IN) {
      const authRoutes = ["/", router.MATCH_FORGOT_PASSWORD, router.MATCH_LOGIN]
      const isAuthRoute = authRoutes.includes(location.pathname) || location.pathname.startsWith(router.ROUTE_PASSWORD_RESET)
      if (isAuthRoute) {
        history.push(router.ROUTE_ARTWORKS)
      }
    }
  }, [state, location, history])

  return state === AUTH_STATE_UNKNOWN ? null : state === AUTH_STATE_LOGGED_IN ? loggedIn : loggedOut
}

export default AuthProvider
