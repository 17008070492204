import React from 'react'
import { FieldArray } from 'redux-form'
import { RepeaterDefaultAdd } from '../../form/Repeater'
import { AddInstitutionsField } from './AddInstitutionsField'
import { AddPersonsField } from './AddPersonsField'

const RecipientContainer = ({ fields, changeFieldValue, title = '' }) => (
  <div className="repeater-field">
    <div className="field-label">{title}</div>
    {fields.map((member, index) => (
      <AddPersonsField
        key={index}
        member={member}
        index={index}
        fields={fields}
        //items={items}
        changeFieldValue={changeFieldValue}
      />
    ))}
    <RepeaterDefaultAdd fields={fields} addFn={() => fields.push({})} label={`Add ${title}`} />
  </div>
)

const RecipientInstContainer = ({ fields, changeFieldValue, title = '' }) => (
  <div className="repeater-field">
    <div className="field-label">{title}</div>
    {fields.map((member, index) => (
      <AddInstitutionsField
        key={index}
        member={member}
        index={index}
        fields={fields}
        //items={items}
        changeFieldValue={changeFieldValue}
      />
    ))}
    <RepeaterDefaultAdd fields={fields} addFn={() => fields.push({})} label={`Add ${title}`} />
  </div>
)

export const DocumentRecipients = (props) => (
  <FieldArray name="documents_recipients" component={RecipientContainer} {...props} />
)

export const DocumentRecipientsInst = (props) => (
  <FieldArray name="documents_recipients_institutions" component={RecipientInstContainer} {...props} />
)
