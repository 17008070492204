import * as React from 'react'
import Dropzone from 'react-dropzone'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import Modal from '@material-ui/core/Modal/Modal'
import Button from '@material-ui/core/Button/Button'
import { Typography } from '@material-ui/core'
export class AvatarUploader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      uploading: false,
    }
  }
  render() {
    const { upload, onStart, onSuccess, onError } = this.props
    const onDrop = (file) => {
      this.setState({ uploading: true })
      onStart()
      upload(file[0]).subscribe({
        next: (file) => {
          this.setState({ uploading: false })
          onSuccess(file)
        },
        error: (error) => {
          this.setState({ uploading: false })
          onError(error)
        },
      })
    }
    const Loader = this.props.loader
    return this.state.uploading && Loader !== undefined ? (
      <Loader />
    ) : (
      <Dropzone onDrop={onDrop}>
        {this.props.image === undefined ? (
          <p>Try dropping some files here, or click to select files to upload.</p>
        ) : (
          <img src={this.props.image} alt="avatar" />
        )}
      </Dropzone>
    )
  }
}

export class Uploader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      uploading: false,
    }
  }
  render() {
    const { upload, onStart, onSuccess, onError } = this.props
    const onDrop = (file) => {
      this.setState({ uploading: true })
      onStart()
      upload(file[0]).subscribe({
        next: (file) => {
          this.setState({ uploading: false })
          onSuccess(file)
        },
        error: (error) => {
          this.setState({ uploading: false })
          onError(error)
        },
      })
    }
    const Loader = this.props.loader
    return this.state.uploading && Loader !== undefined ? (
      <Loader />
    ) : (
      <Dropzone onDrop={onDrop}>
        {this.props.image === undefined ? (
          <p>Try dropping some files here, or click to select files to upload.</p>
        ) : (
          <img src={this.props.image} alt="avatar" />
        )}
      </Dropzone>
    )
  }
}

export class ReUploader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      uploading: false,
    }
  }
  render() {
    const { upload, onStart, onSuccess, onError } = this.props
    const onDrop = (file) => {
      this.setState({ uploading: true })
      onStart()
      upload(file[0]).subscribe({
        next: (file) => {
          this.setState({ uploading: false })
          onSuccess(file)
        },
        error: (error) => {
          this.setState({ uploading: false })
          onError(error)
        },
      })
    }
    const Loader = this.props.loader
    return this.state.uploading && Loader !== undefined ? (
      <Loader />
    ) : (
      <Dropzone onDrop={onDrop} className={'reDropzone'}>
        <Typography color="secondary">REPLACE</Typography>
      </Dropzone>
    )
  }
}

export class CropUploader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      uploading: false,
      image: '',
      cropperOpen: false,
      croppedImage: null,
    }
  }

  render() {
    const { upload, onStart, onSuccess, onError } = this.props
    const onDrag = (file) => {
      this.setState({ image: file[0].preview, cropperOpen: true })
    }
    const closeModal = () => {
      this.setState({ cropperOpen: false })
    }
    const dataURLtoFile = (base64, filename) => {
      let arr = base64.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    }

    const uploadPhoto = () => {
      this.setState({ uploading: true, cropperOpen: false })
      onStart()
      let base64 = this.refs.cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.9)
      let file = dataURLtoFile(base64, 'test.jpg')

      upload(file).subscribe({
        next: (file) => {
          this.setState({ uploading: false })
          onSuccess(file)
        },
        error: (error) => {
          this.setState({ uploading: false })
          onError(error)
        },
      })
    }

    const Loader = this.props.loader
    return this.state.uploading && Loader !== undefined ? (
      <Loader />
    ) : (
      <div>
        <Dropzone onDrop={onDrag}>
          <p>Try dropping some files here, or click to select files to upload.</p>
        </Dropzone>
        {this.state.image ? (
          <Modal
            className="uploader-modal"
            open={this.state.cropperOpen}
            style={{
              top: '50%',
              left: '50%',
              margin: '-250px 0 0 -250px',
              width: '500px',
              height: '600px',
              background: '#333333',
            }}
          >
            <div>
              <div className="flex flex-box-centered centered">
                <Cropper
                  ref="cropper"
                  src={this.state.image}
                  style={{ height: 500, width: 500 }}
                  autoCropArea={1}
                  // Cropper.js options
                  aspectRatio={1}
                  autoCrop={true}
                  guides={false}
                />
              </div>
              <div className="my-controls flex" style={{ 'text-align': 'right', padding: '10px' }}>
                <span>
                  <Button color="primary" onClick={closeModal}>
                    Cancel
                  </Button>
                  <Button variant="contained" color="primary" onClick={uploadPhoto}>
                    Done
                  </Button>
                </span>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    )
  }
}

export default Uploader
