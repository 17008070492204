export const MATCH_ARTWORKS = '/artworks/:page?'
export const ROUTE_ARTWORKS = '/artworks/'
export const ROUTE_DASHBOARD = '/dashboard/'
export const MATCH_ARTWORK_EDIT = '/artworks/edit/:id'
export const ROUTE_ARTWORK_EDIT = '/artworks/edit/'
export const MATCH_ARTWORK_CREATE = '/artworks/create'
export const ROUTE_ARTWORK_CREATE = '/artworks/create'

export const MATCH_LOGIN = '/login'
export const ROUTE_LOGIN = '/login'
export const MATCH_LOGOUT = '/logout'
export const ROUTE_LOGOUT = '/logout'

export const MATCH_FORGOT_PASSWORD = '/forgot-password'
export const ROUTE_FORGOT_PASSWORD = '/forgot-password'

export const MATCH_PASSWORD_RESET = '/reset-password/:token'
export const ROUTE_PASSWORD_RESET = '/reset-password/'

export const MATCH_USERS = '/users/:page?'
export const ROUTE_USERS = '/users/'
export const MATCH_USER_CREATE = '/users/create'
export const ROUTE_USER_CREATE = '/users/create'
export const MATCH_USER_EDIT = '/users/edit/:id'
export const ROUTE_USER_EDIT = '/users/edit/'

export const MATCH_USER_PROFILE = '/user/:id'
export const ROUTE_USER_PROFILE = '/user/'

export const MATCH_PROFILE = '/profile'
export const ROUTE_PROFILE = '/profile'

export const MATCH_EXHIBITIONS = '/exhibitions'
export const ROUTE_EXHIBITIONS = '/exhibitions/'
export const MATCH_EXHIBITION_CREATE = '/exhibitions/create'
export const ROUTE_EXHIBITION_CREATE = '/exhibitions/create'
export const MATCH_EXHIBITION_EDIT = '/exhibitions/edit/:id'
export const ROUTE_EXHIBITION_EDIT = '/exhibitions/edit/'

export const MATCH_ARCHIVES = '/archives'
export const ROUTE_ARCHIVES = '/archives/'
export const MATCH_ARCHIVE_CREATE = '/archives/create'
export const ROUTE_ARCHIVE_CREATE = '/archives/create'
export const MATCH_ARCHIVE_EDIT = '/archives/edit/:id'
export const ROUTE_ARCHIVE_EDIT = '/archives/edit/'

export const MATCH_LITERATURES = '/literatures'
export const ROUTE_LITERATURES = '/literatures/'
export const MATCH_LITERATURE_CREATE = '/literatures/create'
export const ROUTE_LITERATURE_CREATE = '/literatures/create'
export const MATCH_LITERATURE_EDIT = '/literatures/edit/:id'
export const ROUTE_LITERATURE_EDIT = '/literatures/edit/'

export const MATCH_PERSONS = '/persons'
export const ROUTE_PERSONS = '/persons/'
export const MATCH_PERSON_CREATE = '/persons/create'
export const ROUTE_PERSON_CREATE = '/persons/create'
export const MATCH_PERSON_EDIT = '/persons/edit/:id'
export const ROUTE_PERSON_EDIT = '/persons/edit/'

export const MATCH_INSTITUTIONS = '/institutions'
export const ROUTE_INSTITUTIONS = '/institutions/'
export const MATCH_INSTITUTION_CREATE = '/institutions/create'
export const ROUTE_INSTITUTION_CREATE = '/institutions/create'
export const MATCH_INSTITUTION_EDIT = '/institutions/edit/:id'
export const ROUTE_INSTITUTION_EDIT = '/institutions/edit/'

export const MATCH_REFERENCES = '/references'
export const ROUTE_REFERENCES = '/references/'
export const MATCH_REFERENCE_CREATE = '/references/create'
export const ROUTE_REFERENCE_CREATE = '/references/create'
export const MATCH_REFERENCE_EDIT = '/references/edit/:id'
export const ROUTE_REFERENCE_EDIT = '/references/edit/'

export const MATCH_KEYWORDS = '/keywords'
export const ROUTE_KEYWORDS = '/keywords/'
export const MATCH_KEYWORD_CREATE = '/keywords/create'
export const ROUTE_KEYWORD_CREATE = '/keywords/create'
export const MATCH_KEYWORD_EDIT = '/keywords/edit/:id'
export const ROUTE_KEYWORD_EDIT = '/keywords/edit/'

export const MATCH_DOCUMENTS = '/documents'
export const ROUTE_DOCUMENTS = '/documents/'
export const MATCH_DOCUMENT_CREATE = '/documents/create'
export const ROUTE_DOCUMENT_CREATE = '/documents/create'
export const MATCH_DOCUMENT_EDIT = '/documents/edit/:id'
export const ROUTE_DOCUMENT_EDIT = '/documents/edit/'

export const MATCH_CATALOGUES = '/catalogues'
export const ROUTE_CATALOGUES = '/catalogues/'
export const MATCH_CATALOGUE_CREATE = '/catalogues/create'
export const ROUTE_CATALOGUE_CREATE = '/catalogues/create'
export const MATCH_CATALOGUE_EDIT = '/catalogues/edit/:id'
export const ROUTE_CATALOGUE_EDIT = '/catalogues/edit/'

export const USERS_ROUTE_REGEXP = 'users*'
export const ARTWORKS_ROUTE_REGEXP = 'artworks*'
export const ARCHIVES_ROUTE_REGEXP = 'archives*'
export const EXHIBITIONS_ROUTE_REGEXP = 'exhibitions*'
export const LITERATURES_ROUTE_REGEXP = 'literatures*'
export const PERSONS_ROUTE_REGEXP = 'persons*'
export const INSTITUTIONS_ROUTE_REGEXP = 'institutions*'
export const REFERENCES_ROUTE_REGEXP = 'references*'
export const DASHBOARD_ROUTE_REGEXP = 'dashboard*'
export const ROFESSIONS_ROUTE_REGEXP = 'professions*'
export const COUNTRIES_ROUTE_REGEXP = 'countries*'
export const KEYWORDS_ROUTE_REGEXP = 'keywords*'
export const DOCUMENTS_ROUTE_REGEXP = 'documents*'
export const CATALOGUE_ROUTE_REGEXP = 'catalogue*'

export const MATCH_PROFESSIONS = '/professions'
export const ROUTE_PROFESSIONS = '/professions/'

export const MATCH_COUNTRIES = '/countries'
export const ROUTE_COUNTRIES = '/countries/'
