import React from 'react'
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core'
import { COLOR_GREY, COLOR_LIGHT_TEXT } from '../../config/colors'
import { PUBLISHED, REVIEWED } from '../../utils/constants'
import { useSelector } from 'react-redux'
import { formValueSelector } from 'redux-form'

const useStyles = makeStyles((theme) => ({
  chip: {
    backgroundColor: COLOR_GREY,
    '& .MuiChip-label': {
      color: COLOR_LIGHT_TEXT,
    },
  },
  checkbox: {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
    padding: 2,
    marginRight: 16,
  },
  labelCheckbox: {
    color: COLOR_LIGHT_TEXT,
    marginLeft: 0,
  },
}))

export const PublishedStatus = ({ changeFieldValue, formName }) => {
  const classes = useStyles()
  const reviewedState = useSelector((state) => formValueSelector(formName)(state, REVIEWED))
  const publishedState = useSelector((state) => formValueSelector(formName)(state, PUBLISHED))
  const handleCheckboxChange = (event, name) => {
    changeFieldValue(name, event.target.checked ? 1 : 0)
  }

  return (
    <div className="row flex-box">
      <FormControlLabel
        className={classes.labelCheckbox}
        control={
          <Checkbox
            name={REVIEWED}
            className={classes.checkbox}
            checked={reviewedState === 1}
            onChange={(event) => handleCheckboxChange(event, REVIEWED)}
          />
        }
        label="Reviewed"
      />
      <FormControlLabel
        className={classes.labelCheckbox}
        control={
          <Checkbox
            name={PUBLISHED}
            className={classes.checkbox}
            checked={publishedState === 1}
            onChange={(event) => handleCheckboxChange(event, PUBLISHED)}
          />
        }
        label="Published"
      />
    </div>
  )
}
