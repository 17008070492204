import * as actionTypes from '../actions/actionTypes'

const initialState = {
  loading: false,
  error: undefined,
  success: false,
  message: ''
}

const resetPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
        success: false,
        message: ''
      }
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        success: true,
        message: action.payload.message 
      }
    case actionTypes.RESET_PASSWORD_FAILURE:
      const errorMessage = action.payload && action.payload.message ? action.payload.message : 'Unknown error'
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
        message: errorMessage
      }
    default:
      return state
  }
}

export default resetPasswordReducer