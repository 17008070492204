//

import * as actionTypes from './actionTypes'
import * as http from '../services/http'
import { handleError, toInt } from '../utils/utils'
import { merge  } from 'rxjs'
import { switchMap, map, catchError } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { getFromCache } from '../utils/cache'

export const loadUsers = (page = '1') => ({
  type: actionTypes.USERS_LOAD,
  payload: toInt(page),
})

export const usersLoaded = (response) => ({
  type: actionTypes.USERS_LOADED,
  payload: { page: response.current_page, items: response.data, total: response.total },
})

export const loadUsersFailed = (error) => ({
  type: actionTypes.USERS_LOAD_FAILED,
  payload: error,
})

const getLoadUsers = (page, state) =>
  merge(
    getFromCache(state.users.pages, page).pipe(
      map((items) => ({
        current_page: page,
        data: items,
        total: state.users.total,
      }))
    ),
    http.loadUsers(page)
  )

export const loadUsersEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.AUTH_LOGGED_IN),
    switchMap(() => getLoadUsers(1, state$.value).pipe(map(usersLoaded), catchError(handleError(loadUsersFailed))))
  )

export const usersOrder = (orderBy, order) => ({
  type: actionTypes.USERS_ORDER,
  payload: { orderBy, order },
})
