import React from 'react'
import FormTop from '../../form/FormTop'
import { EditTop } from '../../general/EditTop'

const CatalogueControls = ({ remoteSubmit, remove, saveState, changeFieldValue, initialValues }) => (
  <div className="Catalogue-controls">
    <EditTop title="Edit Catalogue" remove={remove}/>

    <div className="controls">
      <FormTop
        title={initialValues.title_en}
        button="Save Catalogue"
        remote={remoteSubmit}
        remove={remove}
        saveState={saveState}
      />

      <div className="row flex-box">
      </div>
    </div>
  </div>
)

export default CatalogueControls
