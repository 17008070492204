import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps'
import * as React from 'react'
import { GOOGLE_MAPS_KEY } from '../../config/config'
import Geosuggest from 'react-geosuggest'
import './MapField.css'

const Map = props => (
  <div>
    <GoogleMap
      onRightClick={a => {
        props.changeFieldValue('latitude', a.latLng.lat())
        props.changeFieldValue('longitude', a.latLng.lng())
      }}
      defaultZoom={16}
      center={{
        lat: props.latitude.input.value ? props.latitude.input.value : 0,
        lng: props.longitude.input.value ? props.longitude.input.value : 0
      }}
    >
      <Marker
        position={{
          lat: props.latitude.input.value ? props.latitude.input.value : 0,
          lng: props.longitude.input.value ? props.longitude.input.value : 0
        }}
      />
    </GoogleMap>
  </div>
)

const WithMap = withGoogleMap(Map)

const Script = props => (
  <div>
    <label className="field-label label--basic">Location on map</label>

    <Geosuggest
      onSuggestSelect={select => {
        if (select !== undefined && select.location !== undefined) {
          props.changeFieldValue('latitude', select.location.lat)
          props.changeFieldValue('longitude', select.location.lng)
        }
      }}
      {...props}
    />
    <div className="coords">
      <span className="field-label label--basic">Current coordinates:</span> {props.latitude.input.value} {props.longitude.input.value}
    </div>
    <WithMap {...props} />
  </div>
)

const WithScript = withScriptjs(Script)

const defaultProps = {
  googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_KEY}&v=3.exp&libraries=places`,
  loadingElement: <div style={{ height: `100%` }} />,
  containerElement: <div style={{ height: `300px` }} />,
  mapElement: <div style={{ height: `100%` }} />
}

const MapField = props => <WithScript {...defaultProps} {...props} />

export default MapField
