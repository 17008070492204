import React from 'react'
import { useSelector } from 'react-redux'

export const Top = ({ title, children }) => {
  const colorAccent = useSelector((state) => state.auth.colorAccent)

  return (
    <div className="search shadow-bottom">
      <div className="left-right-block  flex-box-centered">
        <div className="title" style={{ color: colorAccent }}>
        <h3 className='title-heading'>{title}</h3>
        </div>
        {children}
      </div>
    </div>
  )
}
