//

export const loginRequest = ({ username, password }) => ({
  client_id: '2',
  client_secret: 'GaChkCmpoOKPGKYLcrE6JvU6vVK548tyONWdmiVz',
  grant_type: 'password',
  refresh_token: '',
  scope: '',
  username: username,
  password: password
})
