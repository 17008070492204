import React from 'react'
import CatalogueForm from '../form/CatalogueForm'
import { deleteCatalogue, updateCatalogue } from '../../../actions/cataloguesActions'
import { loadCatalogue } from '../../../actions/cataloguesActions'
import { CATALOGUE_FORM } from '../../../services/forms'
import { change, isDirty, submit } from 'redux-form'
import NavigationPrompt from '../../general/NavigationPrompt'
import { catalogueModel } from '../../../models/catalogueModel'
import CatalogueControls from './CatalogueControls'
import { loadProvenancePersons } from '../../../actions/provenancePersonsActions'
import { loadProvenanceInstitutions } from '../../../actions/provenanceInstitutionsActions'
import { loadArtworksAll } from '../../../actions/artworksActions'
import { useParams, useRouteMatch } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import DataProviderFunctional from '../../loader/DataProviderFunctional'

const CatalogueEditPresentation = () => {
  let { id } = useParams()
  let match = useRouteMatch()
  const catalogues = useSelector((state) => state.catalogues.items)
  const initialValues = catalogues[id] ? catalogues[id] : catalogueModel
  const lang = useSelector((state) => state.translations.language)
  const saveState = useSelector((state) => state.keywords.saveState)
  const dirty = useSelector((state) => isDirty(CATALOGUE_FORM)(state))
  const dispatch = useDispatch()
  const onSubmit = (data) => dispatch(updateCatalogue(data))
  const remove = () => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteCatalogue(id))
    }
  }
  const changeFieldValue = (field, value) => dispatch(change(CATALOGUE_FORM, field, value))
  const changeLanguage = (language) => () => dispatch(changeLanguage(language))
  const remoteSubmit = () => dispatch(submit(CATALOGUE_FORM))

  return (
    <div>
      <CatalogueControls
        remoteSubmit={remoteSubmit}
        remove={remove}
        saveState={saveState}
        changeFieldValue={changeFieldValue}
        initialValues={initialValues}
      />
      <div className="Catalogue-grid">
        <NavigationPrompt when={dirty} />
        <CatalogueForm
          lang={lang}
          onSubmit={onSubmit}
          changeFieldValue={changeFieldValue}
          initialValues={initialValues}
          match={match}
        />
      </div>

      <div />
    </div>
  )
}

const CatalogueEdit = () => {
  let { id } = useParams()
  const loading = useSelector((state) => state.catalogues.state)
  const error = useSelector((state) => state.catalogues.error)
  const dispatch = useDispatch()
  const load = () => {
    dispatch(loadProvenancePersons())
    dispatch(loadProvenanceInstitutions())
    dispatch(loadArtworksAll())
    dispatch(loadCatalogue(id))
  }

  return (
    <DataProviderFunctional
      error={error}
      load={load}
      loading={loading}
      component={CatalogueEditPresentation}
    />
  )
}

export default CatalogueEdit
