import { applyMiddleware, combineReducers, createStore } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { createEpicMiddleware } from 'redux-observable'
import * as Sentry from '@sentry/react'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { composeWithDevTools } from 'redux-devtools-extension'

// Import your appReducers and appEpic as needed
import appReducers from './reducers/appReducers'
import { appEpic } from './actions/appActions'

// Create a history instance
export const history = createBrowserHistory()

// Define your root reducer
const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    ...appReducers,
    form: formReducer,
  })

// Configure your Redux store
export const configureStore = (preloadedState) => {
  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options
  })

  const epicMiddleware = createEpicMiddleware()

  // Use composeWithDevTools to compose middlewares and enhancers
  const composeEnhancers = composeWithDevTools({
    // Specify options if needed
  })

  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        epicMiddleware
      ),
      sentryReduxEnhancer
    )
  )

  epicMiddleware.run(appEpic)

  return store
}

// export const createRootReducer = (history) =>
//   combineReducers({
//     router: connectRouter(history),
//     ...appReducers,
//     form: formReducer,
//   })

//   export const history = createBrowserHistory()

// export const configureStore = (preloadedState) => {
//   const sentryReduxEnhancer = Sentry.createReduxEnhancer({
//     // Optionally pass options
//   })
//   const epicMiddleware = createEpicMiddleware(appEpic)

//   const store = createStore(
//     createRootReducer(history), // root reducer with router state
//     preloadedState,
//     compose(
//       applyMiddleware(
//         routerMiddleware(history), // for dispatching history actions
//         epicMiddleware
//       ),
//       sentryReduxEnhancer
//     )
//   )

//   return store
// }

// export const configureStore = (history) => {
//   const sentryReduxEnhancer = Sentry.createReduxEnhancer({
//     // Optionally pass options
//   })

//   // noinspection JSUnresolvedVariable
//   const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

//   const historyMiddleware = routerMiddleware(history)
//   const epicMiddleware = createEpicMiddleware(appEpic)

//   let store = createStore(
//     combineReducers({
//       ...appReducers,
//       router: routerReducer,
//       form: formReducer,
//     }),
//     composeEnhancers(applyMiddleware(historyMiddleware, epicMiddleware), sentryReduxEnhancer)
//   )

//   return store
// }
