import * as React from 'react'
import { FieldArray } from 'redux-form'
import OutlinedButton from '../general/OutlinedButton'

export const RepeaterDefaultAdd = ({ fields, addFn, label = 'Add row' }) => (
  <OutlinedButton color="secondary" onClick={() => (addFn ? addFn(fields) : fields.push())} label={label} />
)

export const RepeaterRP = ({ name, addFn, children, label }) => (
  <FieldArray
    name={name}
    component={({ fields }) => (
      <div className="repeater">
        {fields.map(children)}
        <RepeaterDefaultAdd fields={fields} addFn={addFn} label={label} />
      </div>
    )}
  />
)

const Repeater = (Component, addFn, label) => (props) =>
  (
    <div className="repeater">
      {props.fields.map((member, index, fields) => Component({ member, index, fields, ...props }))}
      <RepeaterDefaultAdd fields={props.fields} addFn={addFn} label={label} />
    </div>
  )

export default Repeater
