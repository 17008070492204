//
import React from 'react'
import logo from '../../img/logo.jpg'

const AppBarTitle = ({ title, subtitle, colorAccent }) => {
  const logoSmallStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colorAccent,
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: 1,
    letterSpacing: '1px',
    width: '40px',
    height: '40px',
    border: `3px solid ${colorAccent}`
  }
  return (
    <div className="app-bar-title">
      <div className="app-bar-logo">
        <div className="logo-small" style={logoSmallStyles}><span>CR</span></div>
        {/* <img className="app-bar-logo" src={logo} alt="" /> */}
      </div>
      <div>
        <span className="subtitle" style={{ color: colorAccent }}>
          {subtitle}
        </span>
        <span className="title">{title}</span>
      </div>
    </div>
  )
}

export default AppBarTitle
