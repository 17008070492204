import React from 'react'
import { Field } from 'redux-form'
import { textareaStyle } from '../form/style'
import MarkupEditor from '../form/MarkupEditor'

export const TechnicalDescription = () => (
  <div>
    <div className="row">
      <div className="field-label">Norwegian Technical Description</div>
      <div className="rich-text-editor">
        <Field
          name={`translations.nb.tech_description`}
          {...textareaStyle}
          type="text"
          multiLine={true}
          minRows={3}
          component={MarkupEditor}
        />
      </div>
    </div>

    <div className="row">
      <div className="field-label">English Technical Description</div>
      <div className="rich-text-editor">
        <Field
          name={`translations.en.tech_description`}
          {...textareaStyle}
          type="text"
          multiLine={true}
          minRows={3}
          component={MarkupEditor}
        />
      </div>
    </div>
  </div>
)