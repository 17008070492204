import * as actionTypes from '../actions/actionTypes'
import { DEFAULT_LANGUAGE } from '../config/config'

const initialState = {
  language: DEFAULT_LANGUAGE
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TRANSLATIONS_CHANGE_LANGUAGE:
      return { ...state, language: action.payload }
    default:
      return state
  }
}
