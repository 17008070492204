import * as actionTypes from './actionTypes'

export const toggleFilter = (type, value) => ({
  type: actionTypes.FILTERS_TOGGLE,
  payload: { type, value }
})

export const setFilter = (type, value) => ({
  type: actionTypes.FILTERS_SET,
  payload: { type, value }
})

export const filterVisibilityToggle = () => ({
  type: actionTypes.FILTERS_VISIBILITY_TOGGLE
})
