import * as actionTypes from '../actions/actionTypes'
import { loadingState } from '../utils/loadingState'

const initialState = {
  user: {},
  state: loadingState.IDLE,
  error: undefined,
  saveState: loadingState.IDLE,
  saveError: undefined
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.USER_LOAD:
      return { ...state, state: loadingState.LOADING, error: undefined }
    case actionTypes.USER_LOADED:
      return { ...state, state: loadingState.LOADED, error: undefined, user: payload }
    case actionTypes.USER_LOAD_FAILED:
      return { ...state, state: loadingState.FAILED, error: payload }

    case actionTypes.USER_UPDATE:
    case actionTypes.USER_CREATE:
      return { ...state, saveState: loadingState.LOADING, saveError: undefined }
    case actionTypes.USER_UPDATED:
    case actionTypes.USER_CREATED:
      return { ...state, saveState: loadingState.LOADED, saveError: undefined }
    case actionTypes.USER_UPDATE_FAILED:
    case actionTypes.USER_CREATE_FAILED:
      return { ...state, saveState: loadingState.FAILED, saveError: payload }
    default:
      return state
  }
}
