import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { USER_FORM } from '../../../services/forms'
import Avatar from '../../general/Avatar'
import { useSelector } from 'react-redux'
import { CustomTextField } from '../../general/CustomTextField'

const UserFormPresentation = ({ handleSubmit, initialValues, upload, removePhoto }) => {
  const currentUser = useSelector((state) => state.auth.user)
  if (!initialValues) {
    return <div>User not found or has been deleted.</div>
  }
  const canEdit = currentUser.id === initialValues.id || currentUser.admin === 1 ? true : false

  return (
    <form className="form-container" onSubmit={handleSubmit}>
      <div className="field-wrapper">
        {canEdit && (
          <Field name="avatar" component={Avatar} upload={upload} removePhoto={removePhoto} disabled={!canEdit} />
        )}
        {!canEdit && (
          <div className="single-img">
            <div className="image-label label--basic">Profile Photo</div>
            {initialValues.avatar ? (
              <img className="table-img-holder" src={initialValues.avatar} alt="user avatar" />
            ) : (
              <div>No photo uploaded</div>
            )}
          </div>
        )}
      </div>
      <div className="field-wrapper">
        <Field id="name-input" name="name" component={CustomTextField} type="text" label="Name" disabled={!canEdit} />
      </div>
      <div className="field-wrapper">
        <Field
          id="email-input"
          name="email"
          component={CustomTextField}
          type="email"
          label="Email"
          disabled={!canEdit}
        />
      </div>
      <div className="field-wrapper">
        <Field
          id="pasword-input"
          name="password"
          component={CustomTextField}
          type="password"
          label="Password"
          disabled={!canEdit}
        />
      </div>
      {/* <TextButton onClick={handleSubmit} type="submit" label="Save" color="primary" /> */}
    </form>
  )
}

const formProps = {
  form: USER_FORM,
}

const UserForm = reduxForm(formProps)(UserFormPresentation)

export default UserForm
