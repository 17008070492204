import { of, from} from 'rxjs'
import * as http from '../services/http'
import * as actionTypes from './actionTypes'
import { handleError } from '../utils/utils'
import { initialize } from 'redux-form'
import * as router from '../services/router'
import { EXHIBITION_FORM } from '../services/forms'
import { push } from 'connected-react-router'
import { map, catchError, mergeMap, switchMap, concatMap  } from 'rxjs/operators'
import { ofType } from 'redux-observable'

////////    LOAD

export const loadExhibitions = (page) => ({
  type: actionTypes.EXHIBITIONS_LOAD,
  payload: page,
})

export const exhibitionsLoadedAll = (response) => {
  return {
    type: actionTypes.EXHIBITIONS_LOADED_ALL,
    payload: { items: response },
  }
}

export const loadExhibitionsFailed = (error) => ({
  type: actionTypes.EXHIBITIONS_LOAD_FAILED,
  payload: error,
})

const getLoadExhibitionsAll = (state) => {
  const orderBy = state.exhibitions.orderBy
  const order = state.exhibitions.order
  let items = state.exhibitions.items
  if (state.exhibitions.stateAllLoaded) {
    return of(items).pipe(
      map((data) => Object.values(data)),
      concatMap(() => http.loadExhibitionsAll(orderBy, order))
    )
  } else {
    return http.loadExhibitionsAll(orderBy, order)
  }
}

export const loadExhibitionsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(actionTypes.EXHIBITIONS_LOAD),
    switchMap((action) =>
      getLoadExhibitionsAll(state$.value).pipe(
        map(exhibitionsLoadedAll),
        catchError(handleError(loadExhibitionsFailed))
      )
    )
  )
}

/// BASIC EXHIBITIONS LIST:

export const loadExhibitionsBasic = (page) => ({
  type: actionTypes.EXHIBITIONS_BASIC_LOAD,
  payload: page,
})

export const exhibitionsBasicLoadedAll = (response) => {
  return {
    type: actionTypes.EXHIBITIONS_BASIC_LOADED_ALL,
    payload: { items: response },
  }
}

export const loadExhibitionsBasicFailed = (error) => ({
  type: actionTypes.EXHIBITIONS_BASIC_LOAD_FAILED,
  payload: error,
})

const getLoadExhibitionsBasicAll = (state) => {
  const orderBy = state.exhibitions.orderBy
  const order = state.exhibitions.order
  let items = state.exhibitions.items
  if (state.exhibitions.stateAllLoaded) {
    return of(items).pipe(
      map((data) => Object.values(data)),
      concatMap(() => http.loadExhibitionsBasic(orderBy, order))
    )
  } else {
    return http.loadExhibitionsBasic(orderBy, order)
  }
}

export const loadExhibitionsBasicEpic = (action$, state$) => {
  return action$.pipe(
    ofType(actionTypes.EXHIBITIONS_BASIC_LOAD),
    switchMap((action) =>
      getLoadExhibitionsBasicAll(state$.value).pipe(
        map(exhibitionsBasicLoadedAll),
        catchError(handleError(loadExhibitionsBasicFailed))
      )
    )
  )
}

/// SINGLE EXHIBITION:

export const loadExhibition = (id) => ({
  type: actionTypes.EXHIBITION_LOAD,
  payload: id,
})

export const exhibitionLoaded = (data) => ({
  type: actionTypes.EXHIBITION_LOADED,
  payload: data,
})

export const loadExhibitionFailed = (error) => ({
  type: actionTypes.EXHIBITION_LOAD_FAILED,
  payload: error,
})

const getLoadExhibition = (id, state) => {
  return http.loadExhibition(id)
}

export const loadExhibitionEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.EXHIBITION_LOAD),
    switchMap((action) =>
      getLoadExhibition(action.payload, state$.value).pipe(
        map(exhibitionLoaded),
        catchError(handleError(loadExhibitionFailed))
      )
    )
  )

////////    UPDATE

export const updateExhibition = (data) => {
  return {
    type: actionTypes.EXHIBITION_UPDATE,
    payload: data,
  }
}

export const exhibitionUpdated = (data) => ({
  type: actionTypes.EXHIBITION_UPDATED,
  payload: data,
})

export const updateExhibitionFailed = (error) => ({
  type: actionTypes.EXHIBITION_UPDATE_FAILED,
  payload: error,
})

export const updateExhibitionEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.EXHIBITION_UPDATE),
    switchMap((action) =>
      from(http.updateExhibition(action.payload.id, action.payload)).pipe(
        map(exhibitionUpdated),
        mergeMap((action) => of(action, initialize(EXHIBITION_FORM, action.payload))),
        catchError(handleError(updateExhibitionFailed))
      )
    )
  )

////////    CREATE

export const createExhibition = (data) => {
  return {
    type: actionTypes.EXHIBITION_CREATE,
    payload: data,
  }
}

export const exhibitionCreated = (data) => ({
  type: actionTypes.EXHIBITION_CREATED,
  payload: data,
})

export const createExhibitionFailed = (error) => ({
  type: actionTypes.EXHIBITION_CREATE_FAILED,
  payload: error,
})

export const createExhibitionEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.EXHIBITION_CREATE),
    switchMap((action) =>
      http.createExhibition(action.payload).pipe(
        map(exhibitionCreated),
        mergeMap((action) =>
          of(
            action,
            initialize(EXHIBITION_FORM, action.payload),
            push(router.ROUTE_EXHIBITION_EDIT + action.payload.id)
          )
        ),
        catchError(handleError(createExhibitionFailed))
      )
    )
  )

////////    DELETE

export const deleteExhibition = (data) => ({
  type: actionTypes.EXHIBITION_DELETE,
  payload: data,
})

export const exhibitionDeleted = (data) => {
  return {
    type: actionTypes.EXHIBITION_DELETED,
    payload: data,
  }
}

export const deleteExhibitionFailed = (error) => ({
  type: actionTypes.EXHIBITION_DELETE_FAILED,
  payload: error,
})

export const deleteExhibitionEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.EXHIBITION_DELETE),
    switchMap((action) =>
      from(http.deleteExhibition(action.payload)).pipe(
        map(exhibitionDeleted),
        mergeMap((action) =>
          of(
            action,
            //destroy(EXHIBITION_FORM),
            push(router.ROUTE_EXHIBITIONS)
          )
        ),
        catchError(handleError(deleteExhibitionFailed))
      )
    )
  )

////////    MISC

export const changeSortOrder = (sortOrder) => ({
  type: actionTypes.EXHIBITIONS_CHANGE_SORT_ORDER,
  payload: sortOrder,
})

export const exhibitionsOrder = (orderBy, order) => ({
  type: actionTypes.EXHIBITIONS_ORDER,
  payload: { orderBy, order },
})
