import * as actionTypes from './actionTypes'
import { of } from 'rxjs'
import { requestPasswordReset } from '../services/http'
import { ofType } from 'redux-observable'
import { map, catchError, switchMap } from 'rxjs/operators'

export const forgotPasswordRequest = (payload) => ({
  type: actionTypes.FORGOT_PASSWORD_REQUEST,
  payload: payload,
})

export const forgotPasswordSuccess = (message) => ({
  type: actionTypes.FORGOT_PASSWORD_SUCCESS,
  payload: message,
})

export const forgotPasswordFailure = (error) => ({
  type: actionTypes.FORGOT_PASSWORD_FAILURE,
  payload: error,
})

export const forgotPasswordEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.FORGOT_PASSWORD_REQUEST), // Filter for FORGOT_PASSWORD_REQUEST actions
    switchMap(({ payload }) =>
      requestPasswordReset(payload.username, payload.database).pipe(
        // Call the API to request password reset
        map((response) => {
          const message = response.message
          return forgotPasswordSuccess(message)
        }),
        catchError((error) => of(forgotPasswordFailure(error)))
      )
    )
  )
