import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { change, formValueSelector, isDirty, submit } from 'redux-form'
import { KEYWORD_FORM } from '../../../services/forms'
import { createKeyword } from '../../../actions/keywordsActions'
import KeywordForm from '../form/KeywordForm'
import FormTop from '../../form/FormTop'
import { uploadOwnerPhoto } from '../../../services/http'
import { keywordModel } from '../../../models/keywordModel'
import { EditTop } from '../../general/EditTop'

const KeywordCreate = () => {
  const lang = useSelector(state => state.translations.language)
  const initialValues = keywordModel
  //const dirty = useSelector(state => isDirty(KEYWORD_FORM)(state))
  const saveState = useSelector(state => state.keywords.saveState)
  const selector = formValueSelector(KEYWORD_FORM)
  const category = useSelector((state) => selector(state, 'category'))
  const dispatch = useDispatch()
  const onSubmit = data => dispatch(createKeyword({ ...data }))
  const remoteSubmit = () => dispatch(submit(KEYWORD_FORM))
  const changeFieldValue = (field, value) => dispatch(change(KEYWORD_FORM, field, value))
  const upload = uploadOwnerPhoto

  return (
    <div>
      <div className="Exhibition-controls">
        <EditTop title="Create Keyword" />

        <div className="controls">
          <FormTop button="Save Keyword" remote={remoteSubmit} saveState={saveState} />
        </div>
      </div>

      <div className="Exhibition-grid">
        {/* <NavigationPrompt when={dirty} /> */}
        <KeywordForm
          lang={lang}
          onSubmit={onSubmit}
          changeFieldValue={changeFieldValue}
          initialValues={initialValues}
          upload={upload}
          category={category}
        />
      </div>
      <div />
    </div>
  )
}

export default KeywordCreate
