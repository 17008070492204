import React from 'react'
import { FieldArray } from 'redux-form'
import { RepeaterDefaultAdd } from '../../form/Repeater'
import { AddInstitutionsField } from './AddInstitutionsField'
import { AddPersonsField } from './AddPersonsField'

const AuthorContainer = ({ fields, changeFieldValue }) => (
  <div className="repeater-field">
    <div className="field-label">Author/Researcher</div>
    {fields.map((member, index) => (
      <AddPersonsField
        key={index}
        member={member}
        index={index}
        fields={fields}
        changeFieldValue={changeFieldValue}
      />
    ))}
    <RepeaterDefaultAdd fields={fields} addFn={() => fields.push({})} label="Add author/researcher" />
  </div>
)

const AuthorInstContainer = ({ fields, changeFieldValue, title = '' }) => (
  <div className="repeater-field">
    <div className="field-label">{title}</div>
    {fields.map((member, index) => (
      <AddInstitutionsField
        key={index}
        member={member}
        index={index}
        fields={fields}
        changeFieldValue={changeFieldValue}
      />
    ))}
    <RepeaterDefaultAdd fields={fields} addFn={() => fields.push({})} label={`Add ${title}`} />
  </div>
)

export const CatalogueAuthors = (props) => (
  <FieldArray name="catalogues_authors" component={AuthorContainer} {...props} />
)

export const CatalogueAuthorsInst = (props) => (
  <FieldArray name="catalogues_authors_institutions" component={AuthorInstContainer} {...props} />
)
