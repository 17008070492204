import * as actionTypes from '../actions/actionTypes'

const initialState = {
  motifs: {},
  techniques: {},
  types: {},
  authenticity: {},
  whereabouts: {},
  materials: {},
  locations: {},
  q: '',
  from: '',
  to: '',
  currator: '',
  exhibition: '',
  visible: true
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.FILTERS_TOGGLE:
      return {
        ...state,
        [payload.type]: { ...state[payload.type], [payload.value]: !state[payload.type][payload.value] }
      }
    case actionTypes.FILTERS_SET:
      return { ...state, [payload.type]: payload.value }
    case actionTypes.FILTERS_VISIBILITY_TOGGLE:
      return { ...state, visible: !state.visible }
    default:
      return state
  }
}
