import { loadingState } from '../utils/loadingState'
import { updateItemsCache, updatePagesCache, removeFromCache } from '../utils/cache'
import { arrayToIdMap } from '../utils/cache'
import { exhibitionsOrder } from '../actions/exhibitionsActions'

export const ASC = 'asc'
export const DESC = 'desc'

export const flipOrder = order => (order === ASC ? DESC : ASC)

export const crudInitialState = {
  items: {},
  pages: {},
  referenceErrors:[],
  page: 0,
  state: loadingState.IDLE,
  error: undefined,
  saveState: loadingState.IDLE,
  saveError: undefined,
  searchItems: [],
  searchError: undefined,
  searchState: loadingState.IDLE,
  deleteState: loadingState.IDLE,
  deleteError: undefined,
  sortOrder: 'mostRecent',
  orderBy: 'created_at',
  order: 'desc'
}

export const itemsLoad = state => {
  return { ...state, state: loadingState.LOADING }
}
export const itemsLoaded = (state, payload) => ({
  ...state,
  state: loadingState.LOADED,
  items: updateItemsCache(payload.items, state.items),
  pages: updatePagesCache(1, payload.items, state.pages),
  total: payload.total,
  page: payload.page
})

export const itemsLoadedAll = (state, payload) => {
  return {
    ...state,
    state: loadingState.LOADED,
    stateAllLoaded: true,
    items: arrayToIdMap(payload.items)
  }
}

export const itemsLoadFailed = (state, payload) => ({ ...state, state: loadingState.FAILED, error: payload })

export const itemsSearch = state => ({
  ...state,
  searchState: loadingState.LOADING,
  searchError: undefined
})
export const referenceError = (state, payload) => {
  let errors = [...state.referenceErrors, payload]
  return {
    ...state,
        referenceErrors: errors,
    }
}
export const removeReferenceError = (state, payload) => {
    let errors = state.referenceErrors.filter(item => item !== payload)
    return {
        ...state,
        referenceErrors: errors,
    }
}
export const itemsSearchFailed = (state, payload) => ({
  ...state,
  searchState: loadingState.FAILED,
  searchError: payload
})
export const itemsSearched = (state, payload) => { 
  console.log("itemsSearched",payload )
  return ({
  ...state,
  searchState: loadingState.LOADED,
  searchItems: payload.items,
  searchError: undefined
})}

export const itemLoad = state => ({ ...state, state: loadingState.LOADING })
export const itemLoaded = (state, payload) => ({
  ...state,
  state: loadingState.LOADED,
  items: updateItemsCache([payload], state.items)
})
export const itemLoadFailed = (state, payload) => ({ ...state, state: loadingState.FAILED, error: payload })

export const itemSave = state => ({ ...state, saveState: loadingState.LOADING, saveError: undefined })
export const itemSaved = state => ({
  ...state,
  saveState: loadingState.LOADED,
  saveError: undefined
})
export const itemSaveFailed = (state, payload) => ({ ...state, saveState: loadingState.FAILED, saveError: payload })

export const itemSavedCache = (state, payload) => ({
  ...state,
  items: updateItemsCache([payload], state.items),
  saveState: loadingState.LOADED,
  saveError: undefined
})

export const itemDelete = state => ({ ...state, deleteState: loadingState.LOADING, deleteError: undefined })
export const itemDeleted = (state, payload) => ({
  ...state,
  items: removeFromCache(payload, state.items),
  state: loadingState.IDLE, //so redux form does not reload
  deleteState: loadingState.LOADED,
  deleteError: undefined
})
export const itemDeleteFailed = (state, payload) => ({
  ...state,
  deleteState: loadingState.FAILED,
  deleteError: payload
})

export const itemsChangeSortOrder = (state, payload) => ({ ...state, sortOrder: payload })

export const itemsOrder = (state, payload) => ({
  ...state,
  orderBy: payload.orderBy,
  order: state.orderBy === payload.orderBy ? flipOrder(state.order) : ASC,
})

export const makeOrderFn = dispatch => orderBy => () => dispatch(exhibitionsOrder(orderBy))

export const getOrder = (state, field) => ({
  order: state[field].order,
  orderBy: state[field].orderBy
})

export const itemsLoadedBasic = (state, payload) => {
  return {
    ...state,
    state: loadingState.LOADED,
    stateAllLoaded: true,
    items: arrayToIdMap(payload.items)
  }
}