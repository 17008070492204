import * as React from 'react'
import { Field } from 'redux-form'
import { ReferenceField } from './ReferenceField'
import { textareaStyle } from './style'
import { commentary } from '../../models/comentaryModel'
import { ARTWORK_FORM } from '../../services/forms'
import MarkupEditor from '../form/MarkupEditor'

const ArtworkCommentariesField = ({ index, fields, lang }) => (
  <div key={index} >
    <div className="">
      <div className="row">
        <div className="field-label">Norwegian Critical commentary</div>
        <div className="rich-text-editor">
          <Field
            name={`translations.nb.crit_commentary`}
            multiLine={true}
            {...textareaStyle}
            minRows={3}
            component={MarkupEditor}
          />
        </div>
      </div>
      <div className="row">
        <div className="field-label">English Critical commentary</div>
        <div className="rich-text-editor">
          <Field
            name={`translations.en.crit_commentary`}
            multiLine={true}
            {...textareaStyle}
            minRows={3}
            component={MarkupEditor}
          />
        </div>
      </div>
      <ReferenceField name={`references.commentaries_${index}`} formType={ARTWORK_FORM} />
    </div>
  </div>
)

const ArtworkCommentaries = ({ lang }) => (
  <div>
    <ArtworkCommentariesField fields={commentary} index={0} lang={lang} />
  </div>
)

export default ArtworkCommentaries
