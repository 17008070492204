export const documentModel = {
  internal_id: '',
  type: 'personal_communication',
  subtype: '',
  title: '',
  title_en: '',
  author: '',
  recipient: '',
  documents_authors: [],
  documents_authors_institutions: [],
  documents_recipients: [],
  documents_recipients_institutions: [],
  date: '',
  date_en: '',
  language: '',
  pages: 0,
  accession_number: '',
  label: '',
  name_of_database: '',
  url: '',
  transcriber: '',
  translator: '',
  //images: [],
  documents_pages: [],
  reviewed: false,
  published: false,
  created_at: undefined,
  updated_at: undefined,
}
