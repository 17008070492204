export const fieldsContent = {
  book: [
    ['author', 'Author'],
    ['year', 'Year'],
    ['title', 'Title'],
    ['place_published', 'Place Published'],
    ['editor', 'Editor'],
    ['publisher', 'Publisher'],
    ['number_of_pages', 'Number of Pages'],
    ['edition', 'Edition'],
    ['type_of_work', 'Type of Work'],
    ['translator', 'Translator'],
    ['url', 'URL'],
    ['language', 'Language']
  ],
  book_section: [
    ['author', 'Author'],
    ['year', 'Year'],
    ['title', 'Title'],
    ['place_published', 'Place Published'],
    ['editor', 'Editor'],
    ['series_editor', 'Series Editor'],
    ['book_title', 'Book Title'],
    ['publisher', 'Publisher'],
    ['pages', 'Pages'],
    ['translator', 'Translator'],
    ['url', 'URL'],
    ['language', 'Language']
  ],
  catalog: [
    ['author', 'Author'],
    ['year', 'Year'],
    ['title', 'Title'],
    ['place_published', 'Place Published'],
    ['institution', 'Institution'],
    ['publisher', 'Publisher'],
    ['edition', 'Edition'],
    ['type_of_work', 'Type of Work'],
    ['translator', 'Translator'],
    ['url', 'URL'],
    ['language', 'Language']
  ],
  edited_book: [
    //['author', 'Author'],
    ['editor', 'Editor'],
    ['year', 'Year'],
    ['title', 'Title'],
    ['place_published', 'Place Published'],
    ['series_editor', 'Series Editor'],
    ['publisher', 'Publisher'],
    ['number_of_pages', 'Number of Pages'],
    ['type_of_work', 'Type of Work'],
    ['translator', 'Translator'],
    ['url', 'URL'],
    ['language', 'Language']
  ],
  film_broadcast: [
    ['director', 'Director'],
    //['author', 'Author'],
    //['year_released', 'Year_released'],
    //['date_released', 'Date Released'],
    ['year', 'Year'],
    ['title', 'Title'],
    ['place_published', 'Place Published'],
    ['producer', 'Producer'],
    ['distributor', 'Distributor'],
    ['running_time', 'Running Time'],
    ['medium', 'Medium'],
    ['url', 'URL'],
    ['language', 'Language']
  ],
  journal_article: [
    ['author', 'Author'],
    ['year', 'Year'],
    ['title', 'Title'],
    ['journal', 'Journal'],
    ['volume', 'Volume'],
    ['issue', 'Issue'],
    ['pages', 'Pages'],
    ['url', 'URL'],
    ['language', 'Language']
  ],
  manuscript: [
    ['author', 'Author'],
    ['year', 'Year'],
    ['title', 'Title'],
    ['number_of_pages', 'Number of Pages'],
    ['type_of_work', 'Type of Work'],
    ['accession_number', 'Accession Number'],
    ['url', 'URL'],
    ['name_of_database', 'Name of Database'],
    ['database_provider', 'Database Provider'],
    ['language', 'Language']
  ],
  newspaper_article: [
    //['reporter', 'Reporter'],
    ['author', 'Author'],
    ['year', 'Year'],
    ['title', 'Title'],
    ['newspaper', 'Newspaper'],
    ['place_published', 'Place Published'],
    ['type_of_article', 'Type of Article'],
    ['pages', 'Pages'],
    ['name_of_database', 'Name of Database'],
    ['url', 'URL'],
    ['language', 'Language']
  ],
  pamphlet: [
    ['author', 'Author'],
    ['year', 'Year'],
    ['title', 'Title'],
    ['place_published', 'Place Published'],
    ['institution', 'Institution'],
    ['publisher', 'Publisher'],
    ['pages', 'Pages'],
    ['type_of_work', 'Type of Work'],
    ['translator', 'Translator'],
    ['url', 'URL'],
    ['language', 'Language']
  ],
  personal_communication: [
    ['author', 'Author'],
    // ['date', 'Date'],
    ['year', 'Year'],
    ['title', 'Title'],
    ['recipient', 'Recipient'],
    ['type', 'Type'],
    ['accession_number', 'Accession Number'],
    ['label', 'Label'],
    ['name_of_database', 'Name of Database'],
    ['url', 'URL'],
    ['language', 'Language']
  ],
  thesis: [
    ['author', 'Author'],
    ['year', 'Year'],
    ['title', 'Title'],
    ['place_published', 'Place Published'],
    ['university', 'University'],
    ['degree', 'Degree'],
    ['number_of_pages', 'Number of Pages'],
    ['advisor', 'Advisor'],
    ['thesis_type', 'Thesis Type'],
    ['url', 'URL'],
    ['language', 'Language']
  ],
  unpublished_work: [
    ['author', 'Author'],
    ['year', 'Year'],
    ['title', 'Title of Work'],
    ['type_of_work', 'Type of Work'],
    ['publisher', 'Publisher'],
    ['pages', 'Pages'],
    ['name_of_database', 'Name of Database'],
    ['url', 'URL'],
    ['language', 'Language']
  ]
}
