import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { COLOR_WHITE } from '../../config/colors'

const useStyles = makeStyles((theme) => ({
  customButton: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    //borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '2px',
    padding: 0,
    minWidth: 88,
  },
  labelStyle: {
    color: COLOR_WHITE,
    fontSize: '14px',
    fontWeight: '500',
    padding: '8px 16px',
    borderRadius: '2px',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255, 0.4)',
    },
  },
}))

const ContainedButton = ({ label, type, fullWidth, color, startIcon, endIcon, onClick, isRecent, onMouseDown, customClasses }) => {
  const classes = useStyles()

  return (
    <Button
      variant="contained"
      type={type}
      fullWidth={fullWidth}
      color={color}
      className={`${classes.customButton} ${customClasses}`}
      onClick={onClick}
      onMouseDown={onMouseDown}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      <Typography className={classes.labelStyle}>{label}</Typography>
    </Button>
  )
}

export default ContainedButton
