import React from 'react'
import { reduxForm } from 'redux-form'
import { REFERENCE_FORM } from '../../services/forms'
import { FormFields } from './FormFields'

const FormPresentation = ({ handleSubmit, initialValues }) => (
  <form className="artwork-form form-container" onSubmit={handleSubmit}>
    <FormFields initialType={initialValues.type} />
  </form>
)

const formProps = {
  form: REFERENCE_FORM,
  destroyOnUnmount: false,
  enableReinitialize: true,
}

const ReferenceForm = reduxForm(formProps)(FormPresentation)

export default ReferenceForm
