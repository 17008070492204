import * as actionTypes from '../actions/actionTypes'
import isDefined from 'crocks/predicates/isDefined'
import safe from 'crocks/Maybe/safe'
import { crudInitialState, itemsLoad, itemsLoadedAll, itemsLoadFailed } from './crud'

const initialState = crudInitialState

const actionHandlers = {
  [actionTypes.PROFESSIONS_LOAD]: itemsLoad,
  [actionTypes.PROFESSIONS_LOADED]: itemsLoadedAll,
  [actionTypes.PROFESSIONS_LOAD_FAILED]: itemsLoadFailed
}

export default (state = initialState, { type, payload }) =>
  safe(isDefined, actionHandlers[type])
    .map(handler => handler(state, payload))
    .option(state)
