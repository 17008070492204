//
import { merge } from 'rxjs'
import * as http from '../services/http'
import { map, catchError, switchMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { handleError } from '../utils/utils'
import * as actionsTypes from './actionTypes'
import { getFromCache } from '../utils/cache'

export const loadHistory = (artworkId) => ({
  type: actionsTypes.HISTORY_LOAD,
  payload: artworkId,
})

export const historyLoaded = (artworkId) => (items) => ({
  type: actionsTypes.HISTORY_LOADED,
  payload: { artworkId, items },
})

export const loadHistoryFailed = (error) => ({
  type: actionsTypes.HISTORY_LOAD_FAILED,
  payload: error,
})

const getLoadHistory = (artworkId, state) =>
  merge(getFromCache(state.history.items, artworkId), http.loadArtworkHistory(artworkId))

export const loadHistoryEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionsTypes.HISTORY_LOAD),
    switchMap((action) =>
      getLoadHistory(action.payload, state$.value).pipe(
        map(historyLoaded(action.payload)),
        catchError(handleError(loadHistoryFailed))
      )
    )
  )
export const loadUserHistory = (userId) => ({
  type: actionsTypes.HISTORY_USER_LOAD,
  payload: userId,
})

export const userHistoryLoaded = (userId) => (items) => ({
  type: actionsTypes.HISTORY_USER_LOADED,
  payload: { userId, items },
})

export const loadUserHistoryFailed = (error) => ({
  type: actionsTypes.HISTORY_USER_LOAD_FAILED,
  payload: error,
})

const getLoadUserHistory = (userId, state) =>
  merge(getFromCache(state.history.userItems, userId), http.loadUserHistory(userId))

export const loadUserHistoryEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionsTypes.HISTORY_USER_LOAD),
    switchMap((action) =>
      getLoadUserHistory(action.payload, state$.value).pipe(
        map(userHistoryLoaded(action.payload)),
        catchError(handleError(loadUserHistoryFailed))
      )
    )
  )
