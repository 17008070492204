import { merge, from, of } from 'rxjs'
import { map, catchError, mergeMap, switchMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import * as http from '../services/http'
import * as actionTypes from './actionTypes'
import { handleError } from '../utils/utils'
import { getFromCache } from '../utils/cache'
import * as router from '../services/router'
import { initialize } from 'redux-form'
import { ARCHIVE_FORM } from '../services/forms'
import { push } from 'connected-react-router'

export const loadArchives = (archiveId) => ({
  type: actionTypes.ARCHIVES_LOAD,
  payload: archiveId,
})

export const archivesLoaded = (response) => ({
  type: actionTypes.ARCHIVES_LOADED,
  payload: { page: response.current_page, items: response.data, total: response.last_page },
})

export const loadArchivesFailed = (error) => ({
  type: actionTypes.ARCHIVES_LOAD_FAILED,
  payload: error,
})

const getLoadArchives = (page, state) =>
  merge(
    getFromCache(state.archives.pages, page).pipe(
      map((items) => ({
        current_page: page,
        data: items,
        total: state.archives.total,
      }))
    ),
    http.loadArchives(page)
  )

export const loadArchivesEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.ARCHIVES_LOAD),
    switchMap((action) =>
      getLoadArchives(action.payload, state$.value).pipe(
        map(archivesLoaded),
        catchError(handleError(loadArchivesFailed))
      )
    )
  )

export const loadArchive = (stableId) => ({
  type: actionTypes.ARCHIVE_LOAD,
  payload: stableId,
})

export const archiveLoaded = (data) => ({
  type: actionTypes.ARCHIVE_LOADED,
  payload: data,
})

export const loadArchiveFailed = (error) => ({
  type: actionTypes.ARCHIVE_LOAD_FAILED,
  payload: error,
})

const getLoadArchive = (stableId, state) =>
  merge(getFromCache(state.archives.items, stableId), from(http.loadArchive(stableId)))

export const loadArchiveEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.ARCHIVE_LOAD),
    switchMap((action) =>
      getLoadArchive(action.payload, state$.value).pipe(map(archiveLoaded), catchError(handleError(loadArchiveFailed)))
    )
  )

export const updateArchive = (data) => ({
  type: actionTypes.ARCHIVE_UPDATE,
  payload: data,
})

export const archiveUpdated = (data) => ({
  type: actionTypes.ARCHIVE_UPDATED,
  payload: data,
})

export const updateArchiveFailed = (error) => ({
  type: actionTypes.ARCHIVE_UPDATE_FAILED,
  payload: error,
})

export const updateArchiveEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.ARCHIVE_UPDATE),
    switchMap((action) =>
      from(http.updateArchive(action.payload.id, action.payload)).pipe(
        map(archiveUpdated),
        mergeMap((action) => of(action, initialize(ARCHIVE_FORM, action.payload))),
        catchError(handleError(updateArchiveFailed))
      )
    )
  )

export const createArchive = (data) => ({
  type: actionTypes.ARCHIVE_CREATE,
  payload: data,
})

export const archiveCreated = (data) => ({
  type: actionTypes.ARCHIVE_CREATED,
  payload: data,
})

export const createArchiveFailed = (error) => ({
  type: actionTypes.ARCHIVE_CREATE_FAILED,
  payload: error,
})

export const createArchiveEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.ARCHIVE_CREATE),
    switchMap((action) =>
      from(http.createArchive(action.payload)).pipe(
        map(archiveCreated),
        mergeMap((action) =>
          of(action, initialize(ARCHIVE_FORM, action.payload), push(router.ROUTE_ARCHIVE_EDIT + action.payload.id))
        ),
        catchError(handleError(createArchiveFailed))
      )
    )
  )
