import * as actionTypes from '../actions/actionTypes'
import { loadingState } from '../utils/loadingState'
import { updatePagesCache } from '../utils/cache'

const initialState = {
  items: {},
  userItems: {},
  artworkId: 0,
  state: loadingState.IDLE,
  error: undefined
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.HISTORY_LOAD:
      return { ...state, state: loadingState.LOADING, artworkId: action.payload }
    case actionTypes.HISTORY_LOADED:
      return {
        ...state,
        state: loadingState.LOADED,
        items: updatePagesCache(action.payload.artworkId, action.payload.items, state.items)
      }
    case actionTypes.HISTORY_LOAD_FAILED:
      return { ...state, state: loadingState.FAILED, error: action.payload }

    case actionTypes.HISTORY_USER_LOAD:
      return { ...state, state: loadingState.LOADING, userId: action.payload }
    case actionTypes.HISTORY_USER_LOADED:
      return {
        ...state,
        state: loadingState.LOADED,
        userItems: updatePagesCache(action.payload.userId, action.payload.items, state.userItems)
      }
    case actionTypes.HISTORY_USER_LOAD_FAILED:
      return { ...state, state: loadingState.FAILED, error: action.payload }
    default:
      return state
  }
}
