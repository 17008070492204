import { handleError } from '../utils/utils'
import { merge, of } from 'rxjs'
import * as actionTypes from './actionTypes'
import * as http from '../services/http'
import { getFromCache } from '../utils/cache'
import { initialize } from 'redux-form'
import { ARTWORK_FORM } from '../services/forms'
import { push, goBack } from 'connected-react-router'
import * as router from '../services/router'
import { map, mergeMap, catchError, switchMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'

export const loadArtwork = (stableId) => ({
  type: actionTypes.ARTWORK_LOAD,
  payload: stableId,
})

export const artworkLoaded = (data) => ({
  type: actionTypes.ARTWORK_LOADED,
  payload: data,
})

export const loadArtworkFailed = (error) => ({
  type: actionTypes.ARTWORK_LOAD_FAILED,
  payload: error,
})

const getLoadArtwork = (stableId, state) =>
  merge(getFromCache(state.artworks.items, stableId), http.loadArtwork(stableId))

export const loadArtworkEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.ARTWORK_LOAD),
    switchMap((action) =>
      getLoadArtwork(action.payload, state$.value).pipe(
        map(artworkLoaded),
        catchError(handleError(loadArtworkFailed))
      )
    )
  )

export const updateArtwork = (data) => ({
  type: actionTypes.ARTWORK_UPDATE,
  payload: data,
})

export const artworkUpdated = (data) => ({
  type: actionTypes.ARTWORK_UPDATED,
  payload: data,
})

export const updateArtworkFailed = (error) => ({
  type: actionTypes.ARTWORK_UPDATE_FAILED,
  payload: error,
})

export const updateArtworkEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.ARTWORK_UPDATE),
    switchMap((action) =>
      http.updateArtwork(action.payload.stable_id, action.payload).pipe(
        map(artworkUpdated),
        mergeMap((action) => of(action, initialize(ARTWORK_FORM, action.payload))),
        catchError(handleError(updateArtworkFailed))
      )
    )
  )

export const createArtwork = (data) => ({
  type: actionTypes.ARTWORK_CREATE,
  payload: data,
})

export const artworkCreated = (data) => ({
  type: actionTypes.ARTWORK_CREATED,
  payload: data,
})

export const createArtworkFailed = (error) => ({
  type: actionTypes.ARTWORK_CREATE_FAILED,
  payload: error,
})

export const createArtworkEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.ARTWORK_CREATE),
    switchMap((action) =>
      http.createArtwork(action.payload).pipe(
        map(artworkCreated),
        mergeMap((action) =>
          of(action, initialize(ARTWORK_FORM, action.payload), push(router.ROUTE_ARTWORK_EDIT + action.payload.id))
        ),
        catchError(handleError(createArtworkFailed))
      )
    )
  )

export const deleteArtwork = (data) => ({
  type: actionTypes.ARTWORK_DELETE,
  payload: data,
})

export const artworkDeleted = (data) => ({
  type: actionTypes.ARTWORK_DELETED,
  payload: data,
})

export const deleteArtworkFailed = (error) => ({
  type: actionTypes.ARTWORK_DELETE_FAILED,
  payload: error,
})

export const deleteArtworkEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.ARTWORK_DELETE),
    switchMap((action) =>
      http.deleteArtwork(action.payload).pipe(
        map(artworkDeleted),
        mergeMap((action) => of(action, goBack())),
        catchError(handleError(deleteArtworkFailed))
      )
    )
  )
