import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { push } from 'connected-react-router'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import Pagination from '../pagination/Pagination'
import { Top } from '../header/Top'
import { Add } from '@material-ui/icons'
import { getOrder } from '../../reducers/crud'
import { or, sortBy } from '../../utils/utils'
import DataProviderFunctional from '../loader/DataProviderFunctional'
import TextButton from './TextButton'
import { useTheme } from '@material-ui/core'

export const makeList = (options) => {
  const ListPresentation = () => {
    const HeaderComponent = options.renderHeader
    const RowComponent = options.renderRow
    const dispatch = useDispatch()
    let { page } = useParams()
    const getPage = () => (page === undefined ? 1 : page)
    const items = useSelector((state) => state[options.model].pages[getPage()])
    const { order, orderBy } = useSelector((state) => getOrder(state, options.model))
    const orderedItems = or(items, [])
    sortBy(items, orderBy, order)
    const currentPage = useSelector((state) => state[options.model].page)
    const total = useSelector((state) => state[options.model].total)
    const route = options.paginationRoute
    const title = options.title
    const onCreateClick = () => dispatch(push(options.createRoute))
    const onItemClick = (item) => item !== undefined && dispatch(push(options.editRoute + item.id))
    const orderFn = (orderBy) => () => dispatch(options.orderFn(orderBy))
    const theme = useTheme()

    return (
      <div>
        <Top title={title}>
          <div className="item-right">
            <TextButton
            label="Add"
            color="primary"
            startIcon={<Add  style={{ color: theme.palette.primary.main }} />}
            onClick={onCreateClick}
          />
          </div>
        </Top>

        <Table className="non-fixed">
          <HeaderComponent order={order} orderBy={orderBy} orderFn={orderFn} />
          <TableBody>
            {orderedItems.map((item, i) => (
              <RowComponent key={i} item={item} onClick={() => onItemClick(item)} />
            ))}
          </TableBody>
        </Table>
        <Pagination currentPage={currentPage} total={total} route={route} />
      </div>
    )
  }

  const List = () => {
    const loading = useSelector((state) => state[options.model].state)
    const error = useSelector((state) => state[options.model].error)
    const watch = 'page'
    const dispatch = useDispatch()
    let { page } = useParams()
    const getPage = () => (page === undefined ? 1 : page)
    const load = (props) => {
      if (Array.isArray(options.loadFn)) {
        options.loadFn.forEach((fn) => dispatch(fn(getPage(props))))
      } else {
        dispatch(options.loadFn(getPage(props)))
      }
    }

    return (
      <DataProviderFunctional error={error} load={load} loading={loading} watch={watch} component={ListPresentation} />
    )
  }
  return List
}