import * as React from 'react'

class OpenClose extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: props.defaultValue === undefined ? false : props.defaultValue }
  }

  open = () => this.setState({ isOpen: true })
  close = () => this.setState({ isOpen: false })

  render() {
    return this.props.children({
      isOpen: this.state.isOpen,
      open: this.open,
      close: this.close
    })
  }
}

export default OpenClose
