import { of, from } from 'rxjs'
import * as http from '../services/http'
import * as actionTypes from './actionTypes'
import { handleError } from '../utils/utils'
import { initialize } from 'redux-form'
import * as router from '../services/router'
import { CATALOGUE_FORM } from '../services/forms'
import { push } from 'connected-react-router'
import { map, catchError, mergeMap, switchMap, concatMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'

////////    LOAD

export const loadCatalogues = (page) => ({
  type: actionTypes.CATALOGUES_LOAD,
  payload: page,
})

export const cataloguesLoaded = (response) => ({
  type: actionTypes.CATALOGUES_LOADED,
  payload: { page: response.current_page, items: response.data, total: response.last_page },
})

export const loadCataloguesAll = () => {
  return {
    type: actionTypes.CATALOGUES_LOAD_ALL,
  }
}

export const cataloguesLoadedAll = (response) => {
  return {
    type: actionTypes.CATALOGUES_LOADED_ALL,
    payload: response,
  }
}

export const loadCataloguesFailed = (error) => ({
  type: actionTypes.CATALOGUES_LOAD_FAILED,
  payload: error,
})

const getLoadCataloguesAll = (state) => {
  const orderBy = state.catalogues.orderBy
  const order = state.catalogues.order
  let items = state.catalogues.items
  if (state.catalogues.stateAllLoaded) {
    return of(items).pipe(
      map((data) => Object.values(data)),
      concatMap(() => http.loadCataloguesAll(orderBy, order))
    )
  } else {
    return http.loadCataloguesAll(orderBy, order)
  }
}

export const loadCataloguesEpic = (action$, state$) => {
  return action$.pipe(
    ofType(actionTypes.CATALOGUES_LOAD_ALL),
    switchMap((action) =>
      getLoadCataloguesAll(state$.value).pipe(map(cataloguesLoadedAll), catchError(handleError(loadCataloguesFailed)))
    )
  )
}

/// SINGLE CATALOGUE:

export const loadCatalogue = (id) => ({
  type: actionTypes.CATALOGUE_LOAD,
  payload: id,
})

export const catalogueLoaded = (data) => ({
  type: actionTypes.CATALOGUE_LOADED,
  payload: data,
})

export const loadCatalogueFailed = (error) => ({
  type: actionTypes.CATALOGUE_LOAD_FAILED,
  payload: error,
})

const getLoadCatalogue = (id, state) => {
  let item = state.catalogues.items[id]
  if (item) {
    return of(item).pipe(map((data) => data))
  } else {
    return http.loadCatalogue(id)
  }
}

export const loadCatalogueEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.CATALOGUE_LOAD),
    switchMap((action) =>
      getLoadCatalogue(action.payload, state$.value).pipe(
        map(catalogueLoaded),
        catchError(handleError(loadCatalogueFailed))
      )
    )
  )

////////    UPDATE

export const updateCatalogue = (data) => {
  return {
    type: actionTypes.CATALOGUE_UPDATE,
    payload: data,
  }
}

export const catalogueUpdated = (data) => ({
  type: actionTypes.CATALOGUE_UPDATED,
  payload: data,
})

export const updateCatalogueFailed = (error) => ({
  type: actionTypes.CATALOGUE_UPDATE_FAILED,
  payload: error,
})

export const updateCatalogueEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.CATALOGUE_UPDATE),
    switchMap((action) =>
      from(http.updateCatalogue(action.payload.id, action.payload)).pipe(
        map(catalogueUpdated),
        mergeMap((action) => of(action, initialize(CATALOGUE_FORM, action.payload))),
        catchError(handleError(updateCatalogueFailed))
      )
    )
  )

////////    CREATE

export const createCatalogue = (data) => {
  return {
    type: actionTypes.CATALOGUE_CREATE,
    payload: data,
  }
}

export const catalogueCreated = (data) => ({
  type: actionTypes.CATALOGUE_CREATED,
  payload: data,
})

export const createCatalogueFailed = (error) => ({
  type: actionTypes.CATALOGUE_CREATE_FAILED,
  payload: error,
})

export const createCatalogueEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.CATALOGUE_CREATE),
    switchMap((action) =>
      http.createCatalogue(action.payload).pipe(
        map(catalogueCreated),
        mergeMap((action) =>
          of(action, initialize(CATALOGUE_FORM, action.payload), push(router.ROUTE_CATALOGUE_EDIT + action.payload.id))
        ),
        catchError(handleError(createCatalogueFailed))
      )
    )
  )

////////    DELETE

export const deleteCatalogue = (data) => ({
  type: actionTypes.CATALOGUE_DELETE,
  payload: data,
})

export const catalogueDeleted = (data) => {
  return {
    type: actionTypes.CATALOGUE_DELETED,
    payload: data,
  }
}

export const deleteCatalogueFailed = (error) => ({
  type: actionTypes.CATALOGUE_DELETE_FAILED,
  payload: error,
})

export const deleteCatalogueEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.CATALOGUE_DELETE),
    switchMap((action) =>
      from(http.deleteCatalogue(action.payload)).pipe(
        map(catalogueDeleted),
        mergeMap((action) =>
          of(
            action,
            //destroy(CATALOGUE_FORM),
            push(router.ROUTE_CATALOGUES)
          )
        ),
        catchError(handleError(deleteCatalogueFailed))
      )
    )
  )

////////    MISC

export const changeSortOrder = (sortOrder) => ({
  type: actionTypes.CATALOGUES_CHANGE_SORT_ORDER,
  payload: sortOrder,
})

export const cataloguesOrder = (orderBy, order) => ({
  type: actionTypes.CATALOGUES_ORDER,
  payload: { orderBy, order },
})
