//
import { NEVER, of } from 'rxjs'

export const getFromCache = (cache, key) => (cache[key] === undefined ? NEVER : of(cache[key]))

export const arrayToIdMap = array =>
  array.reduce((xs, x) => {
    xs[x.id] = x
    return xs
  }, {})

export const updateMapCache = (data, cache) => ({
  ...cache,
  ...arrayToIdMap(data)
})

export const arrayToStableIdMap = array =>
  array.reduce((xs, x) => {
    xs[x.stable_id] = x
    return xs
  }, {})

export const updateArtworksItemsCache = (data, cache) => ({
  ...cache,
  ...arrayToStableIdMap(data)
})

export const updateItemsCache = (data, cache) => ({
  ...cache,
  ...arrayToIdMap(data)
})

export const updateItemsCacheAll = (data, cache) => ({
  ...cache,
  ...data
})

export const updatePagesCache = (page, items, cache) => ({
  ...cache,
  [page]: items
})

export const addToPagesCache = (page, items, cache) => ({
  ...cache,
  [page]: [...cache[page], ...items]
})

export const removeFromCache = (id, cache) => {
  delete cache[id]
  return { ...cache }
}

export const splitToPages = items => {
  let i,
    j,
    page = 1,
    array = [],
    chunk = 10
  for (i = 0, j = items.length; i < j; i += chunk, page++) {
    array[page] = items.slice(i, i + chunk)
  }
  return array
}
