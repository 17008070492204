import React from 'react'
import { loadHistory, loadUserHistory } from '../../../actions/historyActions'
import { isDefined, relativeTime } from '../../../utils/utils'
import './history.css'
import { useDispatch, useSelector } from 'react-redux'
import DataProviderFunctional from '../../loader/DataProviderFunctional'
import { artworkUpdated } from '../../../actions/artworkActions'

const Diffs = ({ updates }) => (
  <div style={{ flex: '1', marginLeft: '30px' }}>
    <span>updated: </span>
    {Object.keys(updates).map((key, i) => (
      <span key={i}>{key} </span>
    ))}
  </div>
)

const HistoryEntry = ({ history }) => {
  const dispatch = useDispatch()
  const setArtwork = (data) => () => {
    dispatch(artworkUpdated(JSON.parse(data)))
    //dispatch(initialize(ARTWORK_FORM, JSON.parse(data)))
  }
  
  return history.type === 'created' ? (
    <div onClick={setArtwork(history.meta)} className="left-right-block history-entry">
      <div>{history.user ? history.user.name : 'unknown'}</div>
      <div>Created</div>
      <div>{relativeTime(history.created_at)}</div>
    </div>
  ) : (
    <div onClick={setArtwork(history.meta)} className="left-right-block history-entry">
      <div>{history.user ? history.user.name : 'unknown'.name}</div>
      <Diffs updates={JSON.parse(history.updates)} />
      <div>{relativeTime(history.created_at)}</div>
    </div>
  )
}

const HistoryPresenter = ({ artworkId, userId }) => {
  const history = useSelector((state) =>
    isDefined(artworkId) ? state.history.items[artworkId] : state.history.userItems[userId]
  )
  return isDefined(history) && (
    <div className="dark-text">
      {history.length > 0 ? (
        history.map((history, i) => <HistoryEntry key={i} history={history} />)
      ) : (
        <div>No entries</div>
      )}
    </div>
  )
}

const HistoryContainer = ({ artworkId, userId }) => {
  const loading = useSelector((state) => state.history.state)
  const error = useSelector((state) => state.history.error)
  const dispatch = useDispatch()
  const load = () => (isDefined(artworkId) ? dispatch(loadHistory(artworkId)) : dispatch(loadUserHistory(userId)))

  return <DataProviderFunctional artworkId={artworkId} userId={userId} error={error} load={load} loading={loading} component={HistoryPresenter} />
}

// TODO watch for userId changes
export default HistoryContainer
