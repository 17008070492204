import { REFERENCE_FORM } from '../../services/forms'
import { deleteReference, loadReference, updateReference } from '../../actions/referencesActions'
import ReferenceForm from './ReferenceForm'
import { makeEdit } from '../general/Edit'

const options = {
  model: 'references',
  component: ReferenceForm,
  form: REFERENCE_FORM,
  initialValues: {},
  submitFn: updateReference,
  loadFn: loadReference,
  title: 'Edit Reference',
  removeFn: deleteReference,
}

const ReferenceEdit = makeEdit(options)

export default ReferenceEdit
