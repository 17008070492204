import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from '@material-ui/core'
import ResetPasswordForm from './ResetPasswordForm'
import { Logo } from '../auth/Logo'
import { resetPasswordRequest } from '../../actions/resetPasswordActions'
import { useLocation, Link as RouterLink } from 'react-router-dom'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { ROUTE_LOGIN } from '../../services/router'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const ResetPassword = () => {
  const { token } = useParams()
  const colorAccent = useSelector((state) => state.auth.colorAccent)
  const error = useSelector((state) => state.resetPassword.error)
  const success = useSelector((state) => state.resetPassword.success)
  const message = useSelector((state) => state.resetPassword.message)
  const loading = useSelector((state) => state.resetPassword.loading)
  const dispatch = useDispatch()
  const query = useQuery()
  const email = query.get('email') || 'testEmail'
  const database = query.get('database') || 'testDatabase'
  const onSubmit = (values) => {
    dispatch(
      resetPasswordRequest({
        token,
        email,
        newPassword: values.password,
        newPasswordConfirmation: values.confirm_password,
        database,
      })
    )
  }

  return (
    <div className="reset-page">
      <Logo color={colorAccent} />
      <ResetPasswordForm onSubmit={onSubmit} loading={loading} />
      <div>{error && <div className="login-error">{message}</div>}</div>
      <div>
        {success && (
          <div className="login-success">
            {message}
            <p>
            <Link component={RouterLink} to={ROUTE_LOGIN} className="">
              Login
            </Link>
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default ResetPassword
