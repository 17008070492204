import { getDatabase } from '../services/auth'
import { DATABASES } from './config'

//
export const COLOR_BLACK = '#000000'
export const COLOR_BLACK_LIGHT = '#222222'
export const COLOR_PRIMARY = '#2a2a2a'
export const COLOR_GRAY_DARK = '#2D2D2D'
export const COLOR_GRAY_BCG = '#323232'
export const COLOR_GRAY_MID = '#363636'
export const COLOR_GREY = '#494949'
export const COLOR_DARK_TEXT = '#858585'
export const COLOR_LIGHT_TEXT = '#e5eaee'
export const COLOR_WHITE = '#ffffff'
export const COLOR_ACCENT_DEFAULT = '#bcbcbc'
export const COLOR_ERROR = '#ff0000'
export const COLOR_EDITOR_SELECTED_BCG = '#c3e6df'
export let COLOR_ACCENT = COLOR_ACCENT_DEFAULT

getDatabase().then((currentDatabaseId) => {
  const selectedDatabase = DATABASES.find((database) => database.id === currentDatabaseId)
  COLOR_ACCENT = selectedDatabase ? selectedDatabase.accent_color : COLOR_ACCENT_DEFAULT
})
