import * as React from 'react'
import { Field } from 'redux-form'
import { AUTHENTICITY } from '../../config/config'
import { CustomRadioGroup } from '../general/CustomRadioGroup'
import { CustomRadio } from '../general/CustomRadio'

const ArtworkAuthenticity = () => {
 return  <div className="row">
    <div className="section-title">Authenticity</div>
    <div className="light-radio flex">
      <Field name="authenticity" component={CustomRadioGroup}>
        {AUTHENTICITY.map((item, i) => (
          <CustomRadio key={i} value={item.value} label={item.title}
          />
        ))}
      </Field>
    </div>
  </div>
}

export default ArtworkAuthenticity
