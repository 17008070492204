import { loadingState } from '../utils/loadingState'
import * as actionTypes from '../actions/actionTypes'
import { arrayToIdMap } from '../utils/cache'
import {
  crudInitialState,
  itemsOrder, 
} from './crud'

const initialState = crudInitialState

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.CATALOGUES_LOAD:
      return { ...state, state: loadingState.LOADING, page: payload }

    case actionTypes.CATALOGUES_LOADED:
      const { data, current_page } = payload;
      return {
        ...state,
        items: arrayToIdMap(data),
        page: current_page,
        stateAll: loadingState.LOADED,
        state: loadingState.LOADED,
      }

    case actionTypes.CATALOGUES_LOAD_ALL:
      return { ...state, state: loadingState.LOADING, stateAll: loadingState.LOADING }

    case actionTypes.CATALOGUES_LOADED_ALL:
      return { ...state, items: arrayToIdMap(payload.data), state: loadingState.LOADED, stateAll: loadingState.LOADED }

    case actionTypes.CATALOGUES_SEARCH:
      return { ...state, searchState: loadingState.LOADING, searchError: undefined }
    case actionTypes.CATALOGUES_SEARCH_FAILED:
      return { ...state, searchState: loadingState.FAILED, searchError: payload }
    case actionTypes.CATALOGUES_SEARCHED:
      return { ...state, searchState: loadingState.LOADED, searchItems: payload.data, searchError: undefined }

    case actionTypes.CATALOGUES_ORDER:
      return itemsOrder(state, payload)

    case actionTypes.CATALOGUES_LOAD_FAILED:
      return { ...state, state: loadingState.FAILED, error: payload }

    //single catalogue:
    case actionTypes.CATALOGUE_LOAD:
      return { ...state, state: loadingState.LOADING, id: undefined }

    case actionTypes.CATALOGUE_LOADED:
      return {
        ...state,
        state: loadingState.LOADED,
        id: payload.id,
        items: {
          ...state.items,
          [payload.id]: {
            ...payload,
          },
        },
      }

    case actionTypes.CATALOGUE_LOAD_FAILED:
      return { ...state, state: loadingState.FAILED, error: payload }
    case actionTypes.CATALOGUE_UPDATE:
    case actionTypes.CATALOGUE_CREATE:
      return { ...state, saveState: loadingState.LOADING, error: undefined }
    case actionTypes.CATALOGUE_UPDATED:
    case actionTypes.CATALOGUE_CREATED:
      return {
        ...state,
        saveState: loadingState.LOADED,
        items: {
          ...state.items,
          [payload.id]: {
            ...payload,
          },
        },
        error: undefined,
      }
    case actionTypes.CATALOGUE_UPDATE_FAILED:
    case actionTypes.CATALOGUE_CREATE_FAILED:
      return { ...state, saveState: loadingState.FAILED, error: payload }
    default:
      return state
  }
}
