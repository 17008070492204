import React from 'react'
import * as router from '../../../services/router'
import { useSelector, useDispatch } from 'react-redux'
import { Add, ViewComfy, ViewList } from '@material-ui/icons'
import { IconButton, useTheme } from '@material-ui/core'
import './ControlPanel.css'
import { COLOR_DARK_TEXT } from '../../../config/colors'
import { loadArtworks, toggleSortMode, toggleViewMode } from '../../../actions/artworksActions'
import { push } from 'connected-react-router'
import TextButton from '../../general/TextButton'

export const ViewMode = () => {
  const dispatch = useDispatch()
  const isGrid = useSelector((state) => state.artworks.isGrid)
  const colorAccent = useSelector((state) => state.auth.colorAccent)

  return (
    <div className="view-mode flex-box-centered">
      <span className="label view-mode__label">view mode:</span>
      <IconButton onClick={() => dispatch(toggleViewMode(true))}>
        <ViewComfy style={{color: isGrid ? colorAccent : COLOR_DARK_TEXT}} />
      </IconButton>
      <IconButton onClick={() => dispatch(toggleViewMode(false))}>
        <ViewList style={{color: !isGrid ? colorAccent : COLOR_DARK_TEXT}} />
      </IconButton>
    </div>
  )
}

const SortBy = () => {
  const dispatch = useDispatch()
  const isRecent = useSelector((state) => state.artworks.isRecent)
  const page = useSelector((state) => state.artworks.page)

  return (
    <div className="view-mode flex-box-centered">
      <span className="label">sort by:</span>
      <TextButton
        onClick={() => {
          dispatch(toggleSortMode(true))
          dispatch(loadArtworks(page))
        }}
        label="Most recent"
        isRecent={isRecent}
      />
      <TextButton
        onClick={() => {
          dispatch(toggleSortMode(false))
          dispatch(loadArtworks(page))
        }}
        label="Latest edit"
        isRecent={!isRecent}
      />
    </div>
  )
}

const ControlPanelPresentation = () => {
  const dispatch = useDispatch()
  const theme = useTheme()

  return (
    <div className="control-panel shadow">
      <div className="flex-box-centered justified">
        <ViewMode />
        <SortBy />
        <TextButton
          label="Add Artwork"
          color="primary"
          startIcon={<Add  style={{ color: theme.palette.primary.main }} />}
          onClick={() => dispatch(push(router.ROUTE_ARTWORK_CREATE))}
        />
      </div>
    </div>
  )
}

const SimpleControlPanelPresentation = ({ onCreateClick }) => (
  <div className="control-panel left-right-block flex-box-centered shadow">
    <div />
    <TextButton
      label="Add"
      color="primary"
      startIcon={<Add  style={{ color: theme.palette.primary.main }} />}
      onClick={onCreateClick}
    />
  </div>
)

export const SimpleControlPanel = SimpleControlPanelPresentation
export default ControlPanelPresentation
