import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isDirty, submit, change } from 'redux-form'
import { Form } from './Form'

export const makeCreate = (options) => {
  const CreatedForm = () => {
    const lang = useSelector((state) => state.translations.language)
    const dirty = useSelector((state) => isDirty(options.form)(state))
    const saveState = useSelector((state) => state[options.model].saveState)
    const dispatch = useDispatch()

    const onSubmit = (data) => {
      dispatch(options.submitFn(data))
    }

    const changeFieldValue = (form_name, field, value) => {
      dispatch(change(form_name, field, value))
    }

    const remoteSubmit = () => {
      dispatch(submit(options.form))
    }

    const load = () => {
      if (Array.isArray(options.loadFn)) {
        options.loadFn.forEach((fn) => dispatch(fn()))
      } else {
        dispatch(options.loadFn())
      }
    }

    return (
      <Form
        formComponent={options.component}
        lang={lang}
        initialValues={options.initialValues}
        dirty={dirty}
        saveState={saveState}
        title={options.title}
        onSubmit={onSubmit}
        changeFieldValue={changeFieldValue}
        remoteSubmit={remoteSubmit}
        load={load}
      />
    )
  }

  return CreatedForm
}