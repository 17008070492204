import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { INSTITUTION_FORM } from '../../../services/forms'
import { textareaStyle } from '../../form/style'
import PersonPhoto from '../../persons/form/PersonPhoto'
import { ArtworksProvenance } from '../../artworks/general/ArtworksProvenance'
import { ReferenceFieldList } from '../../form/ReferencesListField'
import { InstitutionAlternativeNames } from '../../persons/form/InstitutionAlternativeNames'
import MarkupEditor from '../../form/MarkupEditor'
import { CustomCheckbox } from '../../general/CustomCheckbox'
import { CustomTextField } from '../../general/CustomTextField'

const FormPresentation = ({ handleSubmit, upload, changeFieldValue, form }) => {
  return (
    <form className="artwork-form form-container" onSubmit={handleSubmit}>
      <div className="row"></div>
      <div className="row">
        <Field
          id="name-input"
          name="name"
          type="text"
          width="full"
          label="Name (Norwegian)"
          component={CustomTextField}
        />
        <Field
          id="name_en-input"
          name="name_en"
          type="text"
          width="full"
          label="Name (English)"
          component={CustomTextField}
        />
      </div>
      <div className="row accent-check">
        <div>
          <Field
            name="hide_name"
            label="Hide name"
            component={CustomCheckbox}
            parse={(value) => (value === true ? 1 : 0)}
          />
        </div>
      </div>
      <div className="row">
        <div className="field-label">Alternative names</div>
        <InstitutionAlternativeNames />
      </div>

      <div className="flex-box row">
        <div className="repeater-field">
          <Field
            id="founded-input"
            name="founded"
            label="Founding date"
            component={CustomTextField}
            type="number"
            form_name={form}
          />
        </div>

        <div className="repeater-field light-radio">
          <div className="field-label">Closed</div>
          <div className="reference-holder">
            <Field
              className="row-checkbox"
              name="is_closed"
              component={CustomCheckbox}
              parse={(value) => (value === true ? 1 : 0)}
            />
          </div>
        </div>

        <Field
          name="is_closed"
          component={({ input }) =>
            input.value ? (
              <div className="repeater-field">
                <Field
                  id="closed-input"
                  name="closed"
                  label="Closing date"
                  component={CustomTextField}
                  type="number"
                  form_name={form}
                />
              </div>
            ) : null
          }
        />
      </div>

      <div>
        <div className="row">
          <div className="field-label">Norwegian Biography</div>
          <div className="rich-text-editor">
            <Field name={`biography_nb`} multiLine={true} {...textareaStyle} minRows={3} component={MarkupEditor} />
          </div>
        </div>
        <div className="row">
          <div className="field-label">English Biography</div>
          <div className="rich-text-editor">
            <Field name={`biography`} multiLine={true} {...textareaStyle} minRows={3} component={MarkupEditor} />
          </div>
        </div>
        <ReferenceFieldList name={`references.biography`} formType={INSTITUTION_FORM} />
      </div>

      <div className="flex-box">
        <div className="repeater-field">
          <Field id="city-input" name="city" label="City" component={CustomTextField} />
        </div>
        <div className="repeater-field">
          <Field id="street-input" name="street" label="Street" component={CustomTextField} />
        </div>
      </div>

      <div className="row">
        <PersonPhoto upload={upload} />
      </div>

      <div className="row">
        <ArtworksProvenance />
      </div>
    </form>
  )
}

const formProps = {
  form: INSTITUTION_FORM,
  destroyOnUnmount: false,
  enableReinitialize: true,
}

export const InstitutionForm = reduxForm(formProps)(FormPresentation)
