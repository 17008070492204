export const catalogueModel = {
    title_nb: '',
    title_en: '',
    description_nb: '',
    description_en: '',
    artworks_id: '',
    catalogues_authors: [],
    catalogues_authors_institutions: [],
    artworks: [],
    references: [],
    created_at: undefined,
    updated_at: undefined,
  }
  