import React from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { change, formValueSelector, isDirty, submit } from 'redux-form'
import ArtworkForm from '../form/ArtworkForm'
import { deleteArtwork, loadArtwork, updateArtwork } from '../../../actions/artworkActions'
import { loadKeywords } from '../../../actions/keywordsActions'
import './ArtworkEdit.css'
import FormTop from '../../form/FormTop'
import { ARTWORK_FORM } from '../../../services/forms'
import { uploadFile } from '../../../services/http'
import Tabs from './Tabs'
import NavigationPrompt from '../../general/NavigationPrompt'
import { EditTop } from '../../general/EditTop'
import { getArtworkTitle, artworkToServer } from '../../../utils/utils'
import History from '../../history/list/History'
import { loadProvenancePersons } from '../../../actions/provenancePersonsActions'
import { loadProvenanceInstitutions } from '../../../actions/provenanceInstitutionsActions'
import DataProviderFunctional from '../../loader/DataProviderFunctional'
import { PublishedStatus } from '../../general/PublishedStatus'

const ArtworkEditPresentation = () => {
  const { id } = useParams()
  const saveState = useSelector((state) => state.artwork.saveState)
  const type = useSelector((state) => formValueSelector(ARTWORK_FORM)(state, 'type'))
  const lang = useSelector((state) => state.translations.language)
  const initialValues = useSelector((state) => state.artworks.items[id])
  const dirty = useSelector((state) => isDirty(ARTWORK_FORM)(state))
  const dispatch = useDispatch()

  const onSubmit = (data) => dispatch(updateArtwork(artworkToServer(data)))
  const remove = () => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteArtwork(id))
    }
  }
  const changeFieldValue = (field, value) => {
    dispatch(change(ARTWORK_FORM, field, value))}
  const upload = uploadFile
  const remoteSubmit = () => dispatch(submit(ARTWORK_FORM))

  return (
    <div id="artwork-edit">
      <div className="artwork-controls">
        <EditTop title="Edit artwork" remove={remove} />
        <div className="controls">
          <FormTop
            title={getArtworkTitle(initialValues)}
            button="Save artwork"
            remote={remoteSubmit}
            remove={remove}
            saveState={saveState}
          />
          <PublishedStatus
            changeFieldValue={changeFieldValue}
            formName={ARTWORK_FORM}
          />
          <Tabs />
        </div>
      </div>
      <div className="artwork-grid">
        <NavigationPrompt when={dirty} />
        <ArtworkForm
          lang={lang}
          type={type}
          onSubmit={onSubmit}
          remoteSubmit={remoteSubmit}
          changeFieldValue={changeFieldValue}
          initialValues={initialValues}
          upload={upload}
          dirty={dirty}
          saveState={saveState}
        />
      </div>
      <div className="section-inside">
        <h1>Revisions</h1>
        <div className="row">
          <History artworkId={id} />
        </div>
      </div>
      <div>
      </div>
    </div>
  )
}

const ArtworkEdit = () => {
  const loading = useSelector((state) => state.artwork.state)
  const error = useSelector((state) => state.artwork.error)
  const { id } = useParams()
  const dispatch = useDispatch()
  const load = () => {
    dispatch(loadArtwork(id))
    dispatch(loadProvenancePersons())
    dispatch(loadProvenanceInstitutions())
    dispatch(loadKeywords())
  }
  return <DataProviderFunctional error={error} load={load} loading={loading} component={ArtworkEditPresentation} />
}

export default ArtworkEdit
