import { merge, from, of } from 'rxjs'
import * as http from '../services/http'
import * as actionTypes from './actionTypes'
import { handleError } from '../utils/utils'
import { getFromCache } from '../utils/cache'
import { initialize } from 'redux-form'
import * as router from '../services/router'
import { LITERATURE_FORM } from '../services/forms'
import { push } from 'connected-react-router'
import { map, catchError, mergeMap, switchMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'

export const loadLiteratures = (artworkId) => ({
  type: actionTypes.LITERATURES_LOAD,
  payload: artworkId,
})

export const literaturesLoaded = (response) => ({
  type: actionTypes.LITERATURES_LOADED,
  payload: { page: response.current_page, items: response.data, total: response.last_page },
})

export const loadLiteraturesFailed = (error) => ({
  type: actionTypes.LITERATURES_LOAD_FAILED,
  payload: error,
})

const getLoadLiteratures = (page, state) =>
  merge(
    getFromCache(state.literatures.pages, page).pipe(
      map((items) => ({
        current_page: page,
        data: items,
        total: state.literatures.total,
      }))
    ),
    http.loadLiteratures(page)
  )

export const loadLiteraturesEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.LITERATURES_LOAD),
    switchMap((action) =>
      getLoadLiteratures(action.payload, state$.value).pipe(
        map(literaturesLoaded),
        catchError(handleError(loadLiteraturesFailed))
      )
    )
  )

export const loadLiterature = (stableId) => ({
  type: actionTypes.LITERATURE_LOAD,
  payload: stableId,
})

export const lLiteratureLoaded = (data) => ({
  type: actionTypes.LITERATURE_LOADED,
  payload: data,
})

export const loadLiteratureFailed = (error) => ({
  type: actionTypes.LITERATURE_LOAD_FAILED,
  payload: error,
})

const getLoadLiterature = (stableId, state) =>
  merge(getFromCache(state.literatures.items, stableId), from(http.loadLiterature(stableId)))

export const loadLiteratureEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.LITERATURE_LOAD),
    switchMap((action) =>
      getLoadLiterature(action.payload, state$.value).pipe(
        map(lLiteratureLoaded),
        catchError(handleError(loadLiteratureFailed))
      )
    )
  )

export const updateLiterature = (data) => ({
  type: actionTypes.LITERATURE_UPDATE,
  payload: data,
})

export const lLiteratureUpdated = (data) => ({
  type: actionTypes.LITERATURE_UPDATED,
  payload: data,
})

export const updateLiteratureFailed = (error) => ({
  type: actionTypes.LITERATURE_UPDATE_FAILED,
  payload: error,
})

export const updateLiteratureEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.LITERATURE_UPDATE),
    switchMap((action) =>
      from(http.updateLiterature(action.payload.id, action.payload)).pipe(
        map(lLiteratureUpdated),
        mergeMap((action) => of(action, initialize(LITERATURE_FORM, action.payload))),
        catchError(handleError(updateLiteratureFailed))
      )
    )
  )

export const createLiterature = (data) => ({
  type: actionTypes.LITERATURE_CREATE,
  payload: data,
})

export const lLiteratureCreated = (data) => ({
  type: actionTypes.LITERATURE_CREATED,
  payload: data,
})

export const createLiteratureFailed = (error) => ({
  type: actionTypes.LITERATURE_CREATE_FAILED,
  payload: error,
})

export const createLiteratureEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.LITERATURE_CREATE),
    switchMap((action) =>
      from(http.createLiterature(action.payload)).pipe(
        map(lLiteratureCreated),
        mergeMap((action) =>
          of(
            action,
            initialize(LITERATURE_FORM, action.payload),
            push(router.ROUTE_LITERATURE_EDIT + action.payload.id)
          )
        ),
        catchError(handleError(createLiteratureFailed))
      )
    )
  )
