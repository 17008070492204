import * as actionTypes from './actionTypes'
import * as http from '../services/http'
import { handleError, toInt } from '../utils/utils'
import { of, merge } from 'rxjs'
import { getFromCache } from '../utils/cache'
import { loadingState } from '../utils/loadingState'
import { ofType } from 'redux-observable'
import { map, catchError, switchMap, debounceTime } from 'rxjs/operators'

export const loadArtworks = (page = '1') => ({
  type: actionTypes.ARTWORKS_LOAD,
  payload: toInt(page),
})

export const loadArtworksAll = () => ({
  type: actionTypes.ARTWORKS_LOAD_ALL,
})

export const artworksLoaded = (response) => ({
  type: actionTypes.ARTWORKS_LOADED,
  payload: { page: response.current_page, items: response.data, total: response.total },
})

export const artworksLoadedAll = (response) => ({
  type: actionTypes.ARTWORKS_LOADED_ALL,
  payload: { items: response },
})

export const loadArtworksFailed = (error) => ({
  type: actionTypes.ARTWORKS_LOAD_FAILED,
  payload: error,
})

const makeFilter = (key, value) => {
  if (typeof value === 'string') {
    return value === '' ? '' : `&${key}=${value}`
  } else {
    const filterValues = Object.keys(value).filter((x) => value[x] === true)
    return filterValues.reduce((acc, x) => acc + `&${key}[]=${x}`, '')
  }
}

const makeFilters = (filters) => {
  return Object.keys(filters)
    .map((key) => makeFilter(key, filters[key]))
    .filter((filter) => filter !== '')
    .reduce((acc, filter) => acc + filter, '')
}

const getLoadArtworks = (page, state) => {
  const orderBy = state.artworks.orderBy
  const order = state.artworks.order
  const filters = makeFilters(state.filters)
  return merge(
    getFromCache(state.artworks.pages, page).pipe(
      map((items) => ({
        current_page: page,
        data: items,
        total: state.artworks.total,
      }))
    ),
    http.loadArtworks(page, orderBy, order, filters)
  )
}

const getLoadArtworksAll = (page, state) => {
  let items = state.artworks.items
  if (state.artworks.stateAll === loadingState.LOADED) {
    return of(items).pipe(map((data) => Object.values(data)))
  } else {
    return http.loadArtworksAll()
  }
}

export const loadArtworksEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.ARTWORKS_LOAD),
    switchMap((action) =>
      getLoadArtworks(action.payload, state$.value).pipe(
        map(artworksLoaded),
        catchError(handleError(loadArtworksFailed))
      )
    )
  )

export const loadArtworksAllEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.ARTWORKS_LOAD_ALL),
    switchMap((action) =>
      getLoadArtworksAll(action.payload, state$.value).pipe(
        map(artworksLoadedAll),
        catchError(handleError(loadArtworksFailed))
      )
    )
  )

export const searchArtworks = (query) => ({
  type: actionTypes.ARTWORKS_SEARCH,
  payload: query,
})

export const artworksSearched = (response) => ({
  type: actionTypes.ARTWORKS_SEARCHED,
  payload: { items: response.data },
})

export const searchArtworksFailed = (error) => ({
  type: actionTypes.ARTWORKS_SEARCH_FAILED,
  payload: error,
})

export const searchArtworkEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.ARTWORKS_SEARCH),
    debounceTime(500),
    switchMap((action) =>
      http.searchArtworks(action.payload).pipe(map(artworksSearched), catchError(handleError(searchArtworksFailed)))
    )
  )

export const toggleViewMode = (payload) => ({
  type: actionTypes.ARTWORKS_TOGGLE_VIEW,
  payload: payload,
})

export const toggleSortMode = (payload) => ({
  type: actionTypes.ARTWORKS_TOGGLE_SORT,
  payload: payload,
})
export const artworksOrder = (orderBy, order) => ({
  type: actionTypes.ARTWORKS_ORDER,
  payload: { orderBy, order },
})
