//

import * as actionTypes from '../actions/actionTypes'
import { loadingState } from '../utils/loadingState'
import { addToPagesCache, updatePagesCache } from '../utils/cache'

const initialState = {
  items: {},
  artworkId: 0,
  state: loadingState.IDLE,
  error: undefined,
  createState: loadingState.IDLE
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.COMMENTS_LOAD:
      return { ...state, state: loadingState.LOADING, artworkId: action.payload }
    case actionTypes.COMMENTS_LOADED:
      return {
        ...state,
        state: loadingState.LOADED,
        items: updatePagesCache(action.payload.artworkId, action.payload.items, state.items)
      }
    case actionTypes.COMMENTS_LOAD_FAILED:
      return { ...state, state: loadingState.FAILED, error: action.payload }

    case actionTypes.COMMENT_CREATE:
      return { ...state, createState: loadingState.LOADING }
    case actionTypes.COMMENT_CREATED:
      return {
        ...state,
        createState: loadingState.LOADED,
        items: addToPagesCache(action.payload.artworkId, [action.payload.item], state.items)
      }
    case actionTypes.COMMENT_CREATE_FAILED:
      return { ...state, createState: loadingState.FAILED, error: action.payload }
    default:
      return state
  }
}
