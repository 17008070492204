import { loadingState } from '../utils/loadingState'
import * as actionTypes from '../actions/actionTypes'
import { arrayToIdMap } from '../utils/cache'
import { EditorState, convertFromRaw } from 'draft-js'
import { itemsOrder } from './crud'

const initialState = {
  id: undefined,
  state: loadingState.IDLE,
  error: undefined,
  saveState: loadingState.IDLE,
  saveError: undefined,
  stateAll: loadingState.IDLE,
  searchItems: [],
  searchError: undefined,
  searchState: loadingState.IDLE,
  referenceErrors: [],
  deleteState: loadingState.IDLE,
  deleteError: undefined,
  items: {},
  pages: {},
  page: 0,
  total: 0,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.DOCUMENTS_LOAD:
      return { ...state, state: loadingState.LOADING, page: payload }

    case actionTypes.DOCUMENTS_LOAD_ALL:
      return { ...state, state: loadingState.LOADING, stateAll: loadingState.LOADING }

    // case actionTypes.DOCUMENTS_LOADED:
    //   return {
    //     ...state,
    //     state: loadingState.LOADED,
    //     page: payload.page,
    //     total: payload.total,
    //     items: updateArtworksItemsCache(payload.items, state.items),
    //     pages: updatePagesCache(payload.page, payload.items, state.pages),
    //   }

    case actionTypes.DOCUMENTS_LOADED_ALL:
      return {
        ...state,
        items: arrayToIdMap(payload.data),
        page: payload.current_page,
        stateAll: loadingState.LOADED,
        state: loadingState.LOADED,
      }

    case actionTypes.DOCUMENTS_SEARCH:
      return { ...state, searchState: loadingState.LOADING, searchError: undefined }
    case actionTypes.DOCUMENTS_SEARCH_FAILED:
      return { ...state, searchState: loadingState.FAILED, searchError: payload }
    case actionTypes.DOCUMENTS_SEARCHED:
      return { ...state, searchState: loadingState.LOADED, searchItems: payload.items }
    case actionTypes.DOCUMENTS_ERROR:
      return {
        ...state,
        referenceErrors: payload,
      }
    case actionTypes.DOCUMENTS_REMOVE_ERROR:
      return {
        ...state,
        referenceErrors: referenceErrors.filter((item) => item !== payload),
      }

    case actionTypes.DOCUMENTS_ORDER:
      return itemsOrder(state, payload)

    case actionTypes.DOCUMENTS_LOAD_FAILED:
      return { ...state, state: loadingState.FAILED, error: payload }

    //single document:
    case actionTypes.DOCUMENT_LOAD:
      return { ...state, state: loadingState.LOADING, id: undefined }
    case actionTypes.DOCUMENT_LOADED:
      return {
        ...state,
        state: loadingState.LOADED,
        id: payload.id,
        items: {
          ...state.items,
          [payload.id]: {
            ...payload,
            documents_pages: payload.documents_pages
              ? payload.documents_pages.map((p) => {
                  return {
                    ...p,
                    transcription: p.transcription_state
                      ? EditorState.createWithContent(convertFromRaw(JSON.parse(p.transcription_state)))
                      : null,
                    translation: p.translation_state
                      ? EditorState.createWithContent(convertFromRaw(JSON.parse(p.translation_state)))
                      : null,
                  }
                })
              : [],
          },
        },
      }

    case actionTypes.DOCUMENT_LOAD_FAILED:
      return { ...state, state: loadingState.FAILED, error: payload }
    case actionTypes.DOCUMENT_UPDATE:
    case actionTypes.DOCUMENT_CREATE:
      return { ...state, saveState: loadingState.LOADING, error: undefined }
    case actionTypes.DOCUMENT_UPDATED:
    case actionTypes.DOCUMENT_CREATED:
      return {
        ...state,
        saveState: loadingState.LOADED,
        items: {
          ...state.items,
          [payload.id]: {
            ...payload,
            documents_pages: payload.documents_pages
              ? payload.documents_pages.map((p) => {
                  return {
                    ...p,
                    transcription: p.transcription_state
                      ? EditorState.createWithContent(convertFromRaw(JSON.parse(p.transcription_state)))
                      : null,
                    translation: p.translation_state
                      ? EditorState.createWithContent(convertFromRaw(JSON.parse(p.translation_state)))
                      : null,
                  }
                })
              : [],
          },
        },
        error: undefined,
      }
    case actionTypes.DOCUMENT_UPDATE_FAILED:
    case actionTypes.DOCUMENT_CREATE_FAILED:
      return { ...state, saveState: loadingState.FAILED, error: payload }
    default:
      return state
  }
}
