import React from 'react'
import * as router from '../../services/router'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import './Header.css'
import { Menu, MenuItem, makeStyles } from '@material-ui/core'
import { COLOR_LIGHT_TEXT, COLOR_PRIMARY } from '../../config/colors'

const useStyles = makeStyles((theme) => ({
  menu: {
    '& .MuiMenu-paper': {
      backgroundColor: COLOR_LIGHT_TEXT,
      zIndex: 1500,
    },
  },
  menuItem: {
    color: COLOR_PRIMARY,
  },
}))

export const UserMenu = ({ setAnchorEl, anchorEl }) => {
  const user = useSelector((state) => state.users.items[state.auth.user.id])
  const dispatch = useDispatch()
  const logout = () => dispatch(push(router.ROUTE_LOGOUT))
  const profile = (id) => dispatch(push(router.ROUTE_USER_PROFILE + id))
  const classes = useStyles()
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleClickLogout = () => {
    logout()
    setAnchorEl(null)
  }
  const handleClickProfile = () => {
    profile(user.id)
    setAnchorEl(null)
  }

  return (
    <Menu
      id="user-menu"
      className={classes.menu}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem className={classes.menuItem} onClick={handleClickLogout}>Sign out</MenuItem>
      <MenuItem className={classes.menuItem} onClick={handleClickProfile}>Edit profile</MenuItem>
    </Menu>
  )
}
