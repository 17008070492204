
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { change, isDirty, submit } from 'redux-form'
import { useParams } from 'react-router'
import { Form } from './Form'
import DataProviderFunctional from '../loader/DataProviderFunctional'

export const makeEdit = (options) => {
  const EditPresentation = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const lang = useSelector((state) => state.translations.language)
    const dirty = useSelector((state) => isDirty(options.form)(state))
    const initialValues = useSelector((state) => state[options.model].items[id] || {})
    const saveState = useSelector((state) => state[options.model].saveState)
    const onSubmit = (data) => dispatch(options.submitFn({ ...data, id: id }))
    const remoteSubmit = () => dispatch(submit(options.form))
    const changeFieldValue = (form_name, field, value) => {
      dispatch(change(form_name, field, value))
    }
    const onRemove = () => {
      if (window.confirm('Do you really want to delete?')) {
        dispatch(options.removeFn(id))
      }
    }

    return (
      <Form
        formComponent={options.component}
        lang={lang}
        initialValues={initialValues}
        dirty={dirty}
        saveState={saveState}
        title={options.title}
        upload={options.upload}
        onSubmit={onSubmit}
        changeFieldValue={changeFieldValue}
        remoteSubmit={remoteSubmit}
        onRemove={onRemove}
      />
    )
  }

  const FormEdit = () => {
    let { id } = useParams()
    const loading = useSelector((state) => state[options.model].state)
    const error = useSelector((state) => state[options.model].error)
    const dispatch = useDispatch()
    const load = () => dispatch(options.loadFn(id))

    return <DataProviderFunctional error={error} load={load} loading={loading} component={EditPresentation} />
  }
  return FormEdit
}