import { AvatarUploader } from '../form/Uploader'
import * as React from 'react'
import { CustomRefreshIndicator } from './CustomRefreshIndicator'
import { Tooltip, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'

const Avatar = ({ upload, input, removePhoto }) => (
  <div className="avatar-loader">
    {!input.value ? (
      <div>
        <AvatarUploader
          upload={upload}
          onStart={() => console.log('on upload start')}
          onSuccess={(data) => input.onChange(data.url)}
          onError={console.log}
          loader={CustomRefreshIndicator}
        />
      </div>
    ) : (
      <div className="img-holder">
        <img src={input.value} alt="" />
        <div className="hover-background">
          <Tooltip title="Remove">
            <IconButton onClick={() => removePhoto()} >
              <Close />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    )}
  </div>
)

export default Avatar
