import React, { useState } from 'react'
import { EditorState, Modifier } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Modal from 'react-modal'
import Select from 'react-select'
import { modalStyle } from '../form/style'
import TextButton from '../general/TextButton'

export const InsertMention = (props) => {
  const { editorState, onChange, items, title, labelKey } = props
  const [selectedItem, setSelectedItem] = useState(false)
  const contentState = editorState.getCurrentContent()
  const selection = editorState.getSelection()

  const insertCustomMention = () => {
    const contentStateWithEntity = contentState.createEntity('MENTION', 'IMMUTABLE', selectedItem)
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    const textWithEntity = Modifier.applyEntity(contentState, selection, entityKey)
    let newEditorState = EditorState.push(editorState, textWithEntity, 'apply-entity')
    newEditorState = EditorState.moveFocusToEnd(newEditorState)
    newEditorState = EditorState.forceSelection(newEditorState, textWithEntity.getSelectionAfter())
    onChange(newEditorState)
  }

  return (
    <div className="editor__custom-options rdw-inline-wrapper">
      <InsertModal
        insertFunction={insertCustomMention}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        title={title}
        items={items}
        labelKey={labelKey}
      />
    </div>
  )
}

export const InsertModal = (props) => {
  const { insertFunction, selectedItem, setSelectedItem, title, items, labelKey } = props
  const [open, setOpen] = useState(false)
  return (
    <div>
      <div className="editor__btn--insert-person rdw-option-wrapper" onClick={() => setOpen(true)}>
        {title}
      </div>
      <Modal isOpen={open} onRequestClose={() => setOpen(false)} {...modalStyle}>
        <div className="">
          <div className="id-line">
            <div>
              <div className="field-label">{`Select ${title} for the selected text`}</div>
              {items[0] && items[0].type === 'artwork' ? (
                <Select
                  //wrapperStyle={wrapperStyle}
                  name="select"
                  options={items}
                  optionComponent={ImageOption}
                  valueComponent={ImageValue}
                  labelKey={labelKey}
                  valueKey="id"
                  value={selectedItem}
                  onChange={(value) => setSelectedItem(value)}
                  clearable={false}
                  searchable={true}
                />
              ) : (
                <Select
                  //wrapperStyle={wrapperStyle}
                  name="select"
                  options={items}
                  labelKey={labelKey}
                  valueKey="id"
                  value={selectedItem}
                  onChange={(value) => setSelectedItem(value)}
                  clearable={false}
                  searchable={true}
                />
              )}
            </div>
          </div>
          <div className="right-controls">
            <TextButton color="primary" label="Done" onClick={() => setOpen(false)} onMouseDown={insertFunction} type="button"/>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const ImageOption = (props) => {
  const handleMouseDown = (event) => {
    event.preventDefault()
    event.stopPropagation()
    props.onSelect(props.option, event)
  }
  const handleMouseEnter = (event) => {
    props.onFocus(props.option, event)
  }
  const handleMouseMove = (event) => {
    if (props.isFocused) return
    props.onFocus(props.option, event)
  }
  return (
    <div
      className={props.className}
      title={props.option.title}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
    >
      <img src={props.option.image} className="select_value_image" />
      {props.children}
    </div>
  )
}

const ImageValue = (props) => {
  return (
    <div className="Select-value" title={props.value.title}>
      <div className="Select-value-label">{props.children}</div>
    </div>
  )
}
