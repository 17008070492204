import { of } from 'rxjs'
import * as http from '../services/http'
import * as actionTypes from './actionTypes'
import { handleError } from '../utils/utils'
import { ofType } from 'redux-observable'
import { map, catchError, switchMap } from 'rxjs/operators'

export const loadCountries = () => ({
  type: actionTypes.COUNTRIES_LOAD,
})

export const countriesLoaded = (response) => ({
  type: actionTypes.COUNTRIES_LOADED,
  payload: { items: response },
})

export const loadCountriesFailed = (error) => ({
  type: actionTypes.COUNTRIES_LOAD_FAILED,
  payload: error,
})

const getLoadCountries = (state) => {
  let items = state.countries.items
  if (Object.keys(items).length !== 0) {
    return of(items).pipe(map((data) => Object.values(data)))
  } else {
    return http.loadCountries()
  }
}

export const loadCountriesEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.COUNTRIES_LOAD),
    switchMap((action) =>
      getLoadCountries(state$.value).pipe(map(countriesLoaded), catchError(handleError(loadCountriesFailed)))
    )
  )
