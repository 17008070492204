const translationOf = lang => ({
  text: '',
  language: lang
})

export const commentary = {
  translations: {
    nb: translationOf('nb'),
    en: translationOf('en')
  }
}
