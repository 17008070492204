import { makeCreate } from '../general/Create'
import ReferenceForm from './ReferenceForm'
import { REFERENCE_FORM } from '../../services/forms'
import { createReference } from '../../actions/referencesActions'

const options = {
  model: 'references',
  component: ReferenceForm,
  form: REFERENCE_FORM,
  initialValues: {},
  submitFn: createReference,
  title: 'Create Reference'
}

const ReferenceCreate = makeCreate(options)

export default ReferenceCreate
