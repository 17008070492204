import React from 'react'
import { useSelector } from 'react-redux'
import AppBarTitle from './AppBarTitle'
import { AppBar, Toolbar, makeStyles } from '@material-ui/core'
import { DATABASES } from '../../config/config'
import { Logged } from './Logged'
import './Header.css'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    '& .MuiAppBar-colorPrimary': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    },
    '& .MuiPaper-elevation4': {
        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 4px, rgba(0, 0, 0, 0.12) 0px 1px 2px'
    }
  },
  toolbar: {
    justifyContent: 'space-between',
    paddingLeft: 16, 
    paddingRight: 16,
  },
}))

const Header = () => {
  const classes = useStyles()
  const database = useSelector((state) => state.auth.database)
  const subtitle = database ? DATABASES.find((db) => db.id === database).title : ''
  const colorAccent = useSelector((state) => state.auth.colorAccent)
  return (
    <div className={`header ${classes.root}`}>
      <AppBar className="app-bar">
        <Toolbar className={classes.toolbar}>
          <AppBarTitle title="Catalogue raisonne" subtitle={subtitle} colorAccent={colorAccent} />
          <Logged />
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default Header
