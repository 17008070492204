import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { PERSON_FORM } from '../../../services/forms'
import PersonDates from './PersonDates'
import PersonAlternativeNames from './PersonAlternativeNames'
import PersonPhoto from './PersonPhoto'
import { ReferenceFieldList } from '../../form/ReferencesListField'
import { ArtworksProvenance } from '../../artworks/general/ArtworksProvenance'
import { CustomCheckbox } from '../../general/CustomCheckbox'
import { CustomTextField } from '../../general/CustomTextField'
import { CustomTextArea } from '../../general/CustomTextArea'

const validate = (values) => {
  const errors = {}
  const requiredFields = ['firstname', 'lastname']
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })

  return errors
}

const PersonFormPresentation = ({
  handleSubmit,
  upload,
  //lang,
  //changeFieldValue,
  //professions,
  //countries,
  //exhibitions
}) => {
  //if (!professions) professions = {}
  //if (!countries) countries = {}
  //if (!exhibitions) exhibitions = {}

  return (
    <form className="form-container" onSubmit={handleSubmit}>
      <div className="row flex-box">
        <div className="repeater-field">
          <Field id="firstname-input" name="firstname" label="Firstname" component={CustomTextField} type="text" />
        </div>
        <div className="repeater-field">
          <Field id="lastname-input" name="lastname" label="Lastname" component={CustomTextField} type="text" />
        </div>
      </div>
      <div className="row accent-check">
        <div>
          <Field
            name="hide_name"
            component={CustomCheckbox}
            label="Hide name"
            parse={(value) => (value === true ? 1 : 0)}
          />
        </div>
      </div>
      <div className="row">
        <div className="field-label">Alternative names</div>
        <PersonAlternativeNames />
      </div>
      <div className="row">
        <PersonDates />
      </div>

      <div className="row">
        <PersonPhoto upload={upload} />
        <div className="repeater-field">
          <Field
            id="photo_credit-input"
            name="photo_credit"
            label="photo credit"
            component={CustomTextField}
            type="text"
          />
        </div>
      </div>
      <div className="row">
        <Field
          id="biography-input"
          name="biography"
          component={CustomTextArea}
          label="Biography"
          minRows={4}
          type="text"
        />
        <ReferenceFieldList name={`references.biography`} formType={PERSON_FORM} />
      </div>
      <div className="row">
        <Field
          id="biography_en-input"
          name="biography_en"
          component={CustomTextArea}
          label="Biography (English)"
          minRows={4}
          type="text"
        />
        <ReferenceFieldList name={`references.biography_en`} formType={PERSON_FORM} />
      </div>
      <div className="row">
        <Field
          id="notes-input"
          name="notes"
          component={CustomTextArea}
          label="Notes (not for publication)"
          minRows={4}
          type="text"
        />
      </div>

      <div className="row">
        <ArtworksProvenance />
      </div>
    </form>
  )
}

const formProps = {
  form: PERSON_FORM,
  destroyOnUnmount: false,
  enableReinitialize: true,
  validate,
}

const PersonForm = reduxForm(formProps)(PersonFormPresentation)

export default PersonForm
