import { of } from 'rxjs'
import * as http from '../services/http'
import * as actionTypes from './actionTypes'
import { handleError } from '../utils/utils'
import { map, filter, catchError, mergeMap, switchMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'

export const loadProfessions = () => ({
  type: actionTypes.PROFESSIONS_LOAD,
})

export const professionsLoaded = (response) => {
  return {
    type: actionTypes.PROFESSIONS_LOADED,
    payload: { items: response },
  }
}

export const loadProfessionsFailed = (error) => ({
  type: actionTypes.PROFESSIONS_LOAD_FAILED,
  payload: error,
})

const getLoadProfessions = (state) => {
  let items = state.professions.items
  if (Object.keys(items).length !== 0) {
    return of(items).pipe(map((data) => Object.values(data)))
  } else {
    return http.loadProfessions()
  }
}

export const loadProfessionsEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.PROFESSIONS_LOAD),
    switchMap((action) =>
      getLoadProfessions(state$.value).pipe(map(professionsLoaded), catchError(handleError(loadProfessionsFailed)))
    )
  )
