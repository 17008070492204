import React from 'react'
import { EditorState, Modifier } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

export const DoubleUnderline = (props) => {
  const { editorState, onChange } = props
  const selection = editorState.getSelection()
  const currentStyle = editorState.getCurrentInlineStyle()
  const content = editorState.getCurrentContent()
  const applyDoubleUnderline = () => {
    let newContent
    if (currentStyle.has('DOUBLE_UNDERLINE')) {
      newContent = Modifier.removeInlineStyle(content, selection, 'DOUBLE_UNDERLINE')
    } else {
      newContent = Modifier.applyInlineStyle(content, selection, 'DOUBLE_UNDERLINE')
    }
    onChange(EditorState.push(editorState, newContent, 'change-inline-style'))
  }
  return (
    <div className="editor__custom-options rdw-inline-wrapper">
      <div className="editor__btn--double-underline rdw-option-wrapper" onMouseDown={applyDoubleUnderline}>
        U
      </div>
    </div>
  )
}
