import * as React from 'react'
import { Field } from 'redux-form'
import { useSelector } from 'react-redux'
import { modalStyle } from './style'
import OpenClose from '../general/OpenClose'
import Modal from 'react-modal'
import { ARTWORK_IDS } from '../../config/config'
import { DEFAULT, NOT_AVAILABLE, UNDEFINED } from '../../utils/constants'
import OutlinedButton from '../general/OutlinedButton'
import TextButton from '../general/TextButton'
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core'
import { COLOR_GREY, COLOR_LIGHT_TEXT } from '../../config/colors'
import { CustomTextField } from '../general/CustomTextField'

const useStyles = makeStyles((theme) => ({
  chip: {
    backgroundColor: COLOR_GREY,
    '& .MuiChip-label': {
      color: COLOR_LIGHT_TEXT,
    },
  },
  checkbox: {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
    padding: 2,
    marginRight: 16,
  },
  labelCheckbox: {
    color: COLOR_LIGHT_TEXT,
    marginLeft: 0,
    width: '100%',
  },
}))

const IdField = ({ input, hintText }) =>
  input.value === UNDEFINED ? null : (
    <div className="repeater-field">
      <div className="flex-box">
      </div>
      {input.value === NOT_AVAILABLE ? (
        <Field
          id={`${input.name}-input`}
          disabled={true}
          name={input.name}
          label={hintText}
          component={CustomTextField}
          type="text"
        />
      ) : (
        <Field id={`${input.name}-input`} name={input.name} label={hintText} component={CustomTextField} type="text" />
      )}
    </div>
  )

const IdFieldWrapper = ({ name, hintText }) => <Field name={name} hintText={hintText} component={IdField} />

const IdToggle = ({ input, hintText }) => {
  const classes = useStyles()
  return (
    <FormControlLabel
      className={classes.labelCheckbox}
      label={hintText}
      control={
        <Checkbox
          className={classes.checkbox}
          checked={input.value !== UNDEFINED}
          onChange={(_, checked) => (checked ? input.onChange('') : input.onChange(UNDEFINED))}
        />
      }
    />
  )
}

const IdToggleWrapper = ({ name, hintText }) => <Field name={name} hintText={hintText} component={IdToggle} />

const IdList = () => {
  const database = useSelector((state) => state.auth.database)
  const artworkIds = ARTWORK_IDS[database] ?? ARTWORK_IDS[DEFAULT]
  return (
    <OpenClose>
      {({ isOpen, open, close }) => (
        <div>
          <div className="row">
            <div className="flex-box artwork-row">
              {artworkIds && artworkIds.map((i) => <IdFieldWrapper key={i.value} name={i.value} hintText={i.title} />)}
              <OutlinedButton color="secondary" onClick={open} label="Add ID" customClasses={'id-list-btn'} />
            </div>
          </div>

          <Modal isOpen={isOpen} onRequestClose={close} {...modalStyle}>
            {artworkIds && artworkIds.map((i) => <IdToggleWrapper key={i.value} name={i.value} hintText={i.title} />)}
            <div className="right-controls">
              <TextButton color="primary" onClick={close} type="button" label="Done" />
            </div>
          </Modal>
        </div>
      )}
    </OpenClose>
  )
}

export default IdList
