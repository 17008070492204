import * as React from 'react'
import { Field } from 'redux-form'
import { CustomCheckbox } from '../general/CustomCheckbox'

const ArtworkWhereabouts = () => (
  <div className="row">
    <div className="section-title">Whereabouts</div>
    <Field
      name="whereabouts_unknown"
      parse={(value) => (value === true ? 1 : 0)}
      component={CustomCheckbox}
      label="Unknown"
    />
  </div>
)

export default ArtworkWhereabouts
