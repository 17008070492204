import { InstitutionForm } from '../form/InstitutionForm'
import { INSTITUTION_FORM } from '../../../services/forms'
import { deleteInstitution, loadInstitution, updateInstitution } from '../../../actions/institutionsActions'
import { makeEdit } from '../../general/Edit'
import { uploadOwnerPhoto } from '../../../services/http'

const options = {
  model: 'institutions',
  component: InstitutionForm,
  form: INSTITUTION_FORM,
  initialValues: {},
  upload: uploadOwnerPhoto,
  submitFn: updateInstitution,
  loadFn: loadInstitution,
  removeFn: deleteInstitution,
  title: 'Edit Institution'
}

export const InstitutionEdit = makeEdit(options)
