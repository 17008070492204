import * as actionTypes from './actionTypes'
import { ofType } from 'redux-observable'
import { map, catchError, mergeMap, switchMap } from 'rxjs/operators'
import { of } from 'rxjs'
import { resetPassword } from '../services/http'
import { ROUTE_LOGIN } from '../services/router'
import { push } from 'connected-react-router'

export const resetPasswordRequest = (payload) => ({
  type: actionTypes.RESET_PASSWORD_REQUEST,
  payload: payload,
})

export const resetPasswordSuccess = (message) => ({
  type: actionTypes.RESET_PASSWORD_SUCCESS,
  payload: message,
})

export const resetPasswordFailure = (error) => ({
  type: actionTypes.RESET_PASSWORD_FAILURE,
  payload: error,
})

export const resetPasswordEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.RESET_PASSWORD_REQUEST),
    switchMap(({ payload }) =>
      resetPassword(
        payload.token,
        payload.email,
        payload.newPassword,
        payload.newPasswordConfirmation,
        payload.database
      ).pipe(
        map((response) => {
          const message = response.message || 'Password reset successful'
          return resetPasswordSuccess({ message })
        }),
        catchError((error) => {
          const errorMsg = error.response?.body?.message || 'Password reset failed'
          return of(resetPasswordFailure({ message: errorMsg }))
        }),
        mergeMap((action) => {
          if (action.type === actionTypes.RESET_PASSWORD_FAILURE) {
            // Redirect to login only if reset failed
            return of(action, push(ROUTE_LOGIN))
          }
          return of(action)
        })
      )
    )
  )
