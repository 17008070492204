import * as React from 'react'
import ProfileForm from './ProfileForm'
import { uploadAvatar } from '../../services/http'
import { loadUser, updateUser } from '../../actions/userActions'
import { change } from 'redux-form'
import { PROFILE_FORM } from '../../services/forms'
import DataProviderFunctional from '../loader/DataProviderFunctional'
import { useDispatch, useSelector } from 'react-redux'

const ProfilePresentation = () => {
  const initialValues = useSelector((state) => state.users.items[state.auth.user.id])
  const upload = uploadAvatar
  const dispatch = useDispatch()
  const onSubmit = (data) => dispatch(updateUser(data))
  const removePhoto = () => dispatch(change(PROFILE_FORM, 'avatar', ''))

  return (
    <div>
      <ProfileForm onSubmit={onSubmit} initialValues={initialValues} upload={upload} removePhoto={removePhoto} />
    </div>
  )
}

const Profile = () => {
  const loading = useSelector((state) => state.user.state)
  const error = useSelector((state) => state.user.error)
  const dispatch = useDispatch()
  const load = () => dispatch(loadUser(1))

  return <DataProviderFunctional error={error} load={load} loading={loading} component={ProfilePresentation} />
}

export default Profile
