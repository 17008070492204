import * as React from 'react'
import { Field, FieldArray } from 'redux-form'
import Repeater from './Repeater'
import { Close } from '@material-ui/icons'
import { SIGNATURE_LOCATIONS, SIGNATURE_TYPES } from '../../config/config'
import { Tooltip, IconButton, MenuItem } from '@material-ui/core'
import { ReferenceField } from './ReferenceField'
import { ARTWORK_FORM } from '../../services/forms'
import { COLOR_LIGHT_TEXT } from '../../config/colors'
import { CustomSelect } from '../general/CustomSelect'
import { CustomTextField } from '../general/CustomTextField'

const ArtworkSignaturesField = ({ member, index, fields, lang }) => (
  <div key={index} className="flex title-row">
    <div className="repeater-field">
      {index === 0 ? <div className="field-label">Type</div> : null}
      <Field name={`${member}.type`} component={CustomSelect}>
        {SIGNATURE_TYPES.map((item, i) => (
          <MenuItem key={i} value={item.value}>
            {item.title}
          </MenuItem>
        ))}
      </Field>
    </div>
    <div className="repeater-field">
      {index === 0 ? <div className="field-label">Location</div> : null}
      <Field name={`${member}.location`} component={CustomSelect}>
        {SIGNATURE_LOCATIONS.map((item, i) => (
          <MenuItem key={i} value={item.value}>
            {item.title}
          </MenuItem>
        ))}
      </Field>
    </div>
    <div className="repeater-field">
      {index === 0 ? <div className="field-label">Signature</div> : null}
      <Field
        id={`${member}.value-${index}-input`}
        name={`${member}.value`}
        label="Signature"
        hideLabel={true}
        component={CustomTextField}
        type="text"
      />
      <ReferenceField name={`references.signatures_${index}`} formType={ARTWORK_FORM} />
    </div>
    <Tooltip title="Remove">
      <IconButton className="repeater-remove" onClick={() => fields.remove(index)}>
        <Close style={{ color: COLOR_LIGHT_TEXT }} />
      </IconButton>
    </Tooltip>
  </div>
)

const ArtworkTitlesFields = Repeater(ArtworkSignaturesField, undefined, 'Add signature')

const ArtworkSignatures = ({ lang }) => (
  <div>
    <div className="section-title">Signatures</div>
    <FieldArray name="signatures" component={ArtworkTitlesFields} lang={lang} />
  </div>
)

export default ArtworkSignatures
