import React from 'react'
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core'
import { COLOR_GREY, COLOR_LIGHT_TEXT } from '../../config/colors'

const useStyles = makeStyles((theme) => ({
  chip: {
    backgroundColor: COLOR_GREY,
    '& .MuiChip-label': {
      color: COLOR_LIGHT_TEXT,
    },
  },
  checkbox: {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
    padding: 2,
    marginRight: 16,
  },
  labelCheckbox: {
    color: COLOR_LIGHT_TEXT,
    marginLeft: 0,
  },
}))

export const CustomCheckbox = ({ input, label }) => {
  const classes = useStyles()
  return (
    <FormControlLabel
      className={classes.labelCheckbox}
      label={label}
      control={
        <Checkbox
          className={classes.checkbox}
        //   name={input.name}
          checked={input.value ? true : false}
          onChange={input.onChange}
        />
      }
    />
  )
}
