import React from 'react'
import { Field, change, reduxForm } from 'redux-form'
import Select from 'react-select'
import TextButton from '../general/TextButton'
import { CustomTextField } from '../general/CustomTextField'
import { FORGOT_PASSWORD_FORM } from '../../services/forms'
import { DATABASES } from '../../config/config'
import { useDispatch } from 'react-redux'
import propOr from 'crocks/helpers/propOr'

const validate = (values) => {
  const errors = {}
  if (!values.username) {
    errors.username = 'Please enter your email'
  }
  if (!values.database) {
    errors.database = 'Please select a database'
  }
  return errors
}

const wrapperStyle = {
  width: '',
}

const ForgotPasswordFormPresentation = ({ handleSubmit, loading, handleColorChange }) => {
  const dispatch = useDispatch()
  const changeFieldValue = (field, value) => {
    dispatch(change(FORGOT_PASSWORD_FORM, field, value))
  }
  return (
    <div className="reset-page">
      <div className="login-form">
        <form onSubmit={handleSubmit} className="shadow">
          <div className="field-spacing">
          <Field id="username-input" name="username" type="email" component={CustomTextField} label="Email" />
          </div>
          <div className="field-spacing">
            <label className="field-label auth-label" htmlFor="database-input">
              Database
            </label>
            <Field
              id="database-input"
              className="text-input"
              name="database"
              component={({ input, meta }) => (
                <>
                  <Select
                    wrapperStyle={wrapperStyle}
                    name={input.name}
                    options={DATABASES}
                    labelKey="title"
                    valueKey="id"
                    value={input.value}
                    onChange={(value) => {
                      handleColorChange(value)
                      changeFieldValue(input.name, propOr(undefined, 'id', value))
                    }}
                    clearable={false}
                    searchable={true}
                  />
                  {meta.touched && meta.error && <span className="field-error">{meta.error}</span>}
                </>
              )}
            />
          </div>
          <div className="centered login-button">
            <TextButton
              type="submit"
              label={loading ? 'Sending Email...' : 'Get password reset link'}
              fullWidth={true}
              color="primary"
            />
          </div>
        </form>
      </div>
    </div>
  )
}

const formProps = {
  form: FORGOT_PASSWORD_FORM,
  validate,
}
const ForgotPasswordForm = reduxForm(formProps)(ForgotPasswordFormPresentation)

export default ForgotPasswordForm
