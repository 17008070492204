import request from 'superagent'
import { map } from 'rxjs/operators'
import * as auth from './auth'
import * as config from '../config/config'
import { ITEMS_PER_PAGE } from '../config/config'
import { loginRequest } from '../models/loginRequest'
import { isDefined } from '../utils/utils'
import { from } from 'rxjs'

const selectBody = (result) => result.body
const selectData = (response) => response.data

const getHeaders = () =>
  auth
    .getLocalToken()
    .then((token) => auth.getDatabase().then((database) => [token, database]))
    .then(([token, database]) => {
      const headers = {
        auth: isDefined(token) ? { key: 'Authorization', value: 'Bearer ' + token } : { key: 'X-unused', value: '' },
        database: isDefined(database) ? { key: 'X-Database', value: database } : { key: 'X-Database', value: 'mysql' },
      }
      return headers
    })

const post = (url, data) =>
  getHeaders().then((header) =>
    request
      .post(config.BASE_API_URL + url)
      .set(header.auth.key, header.auth.value)
      .set(header.database.key, header.database.value)
      .set('Accept', 'application/json')
      .send(data)
      .then(selectBody)
  )

const postFile = (url, file) =>
  getHeaders().then((header) =>
    request
      .post(config.BASE_API_URL + url)
      .set(header.auth.key, header.auth.value)
      .set(header.database.key, header.database.value)
      .set('Accept', 'application/json')
      .attach('file', file)
      .then(selectBody)
  )

const patch = (url, data) =>
  getHeaders().then((header) =>
    request
      .patch(config.BASE_API_URL + url)
      .set(header.auth.key, header.auth.value)
      .set(header.database.key, header.database.value)
      .set('Accept', 'application/json')
      .send(data)
      .then(selectBody)
  )

const get = (url) =>
  getHeaders().then((header) =>
    request
      .get(config.BASE_API_URL + url)
      .set(header.auth.key, header.auth.value)
      .set(header.database.key, header.database.value)
      .set('Accept', 'application/json')
      .then(selectBody)
  )

const del = (url) =>
  getHeaders().then((header) =>
    request
      .del(config.BASE_API_URL + url)
      .set(header.auth.key, header.auth.value)
      .set(header.database.key, header.database.value)
      .set('Accept', 'application/json')
      .then(selectBody)
  )

export const login = (credentials) => from(post('/oauth/token', loginRequest(credentials)))

export const getProfile = (token, database) =>
  from(
    request
      .get(config.BASE_API_URL + '/api/users/profile')
      .set('Authorization', 'Bearer ' + token)
      .set('X-Database', database ?? 'mysql')
      .set('Accept', 'application/json')
      .then(selectBody)
  )

// ARTWORKS
export const loadArtworks = (page, orderBy, order, filters) =>
  from(
    get(
      `/api/artworks?per_page=${ITEMS_PER_PAGE}&page=${page}&published=all&order_by=${orderBy}&order=${order}${filters}`
    )
  )

export const loadArtworksAll = () => from(get(`/api/artworks/getAll`))

export const searchArtworks = (query) => from(get(`/api/artworks?per_page=${ITEMS_PER_PAGE}&q=${query}&published=all`))

export const loadArtwork = (id) => from(get(`/api/artworks/${id}`))

export const updateArtwork = (id, data) => from(patch(`/api/artworks/${id}`, data))

export const createArtwork = (data) => from(post(`/api/artworks`, data))

export const deleteArtwork = (stableId) => from(del(`/api/artworks/${stableId}`))

// USERS
export const loadUsers = (page) => from(get(`/api/users?per_page=${ITEMS_PER_PAGE}&page=${page}`))

export const loadUser = (id) => from(get(`/api/users/${id}`))

export const updateUser = (id, data) => from(patch(`/api/users/${id}`, data))

export const createUser = (data) => from(post(`/api/users`, data))

// HISTORY
export const loadArtworkHistory = (artworkId) =>
  from(get(`/api/history?per_page=${ITEMS_PER_PAGE}&artwork_id=${artworkId}`)).pipe(map(selectData))

export const loadUserHistory = (userId) =>
  from(get(`/api/history?per_page=${ITEMS_PER_PAGE}&user_id=${userId}`)).pipe(map(selectData))

// COMMENTS
export const loadArtworkComments = (artworkId) =>
  from(get(`/api/comments?per_page=${ITEMS_PER_PAGE}&artwork_id=${artworkId}`)).pipe(map(selectData))

export const createComment = (data) => from(post(`/api/comments`, data))

export const uploadFile = (file) => from(postFile(`/api/files/upload`, file))

export const uploadAvatar = (file) => from(postFile(`/api/files/uploadAvatar`, file))

export const uploadOwnerPhoto = (file) => from(postFile(`/api/files/uploadOwnerPhoto`, file))

// REFERENCES
export const loadReferences = (page) => from(get(`/api/references?per_page=9999&page=${page}`))

export const loadReference = (id) => from(get(`/api/references/${id}`))

export const updateReference = (id, data) => from(patch(`/api/references/${id}`, data))

export const createReference = (data) => from(post(`/api/references`, data))

export const searchReferences = (query) => from(get(`/api/references?per_page=${ITEMS_PER_PAGE}&q=${query}`))

export const deleteReferences = (id) => from(del(`/api/references/${id}`))

// PERSONS
export const loadPersonsAll = () => from(get(`/api/provenancePersons`))

export const loadPersons = (page) => from(get(`/api/persons?page=${page}`))

export const searchPersons = (query) => from(get(`/api/persons?per_page=${ITEMS_PER_PAGE}&q=${query}`))

export const loadPerson = (id) => from(get(`/api/persons/${id}`))

export const updatePerson = (id, data) => from(patch(`/api/persons/${id}`, data))

export const createPerson = (data) => from(post(`/api/persons`, data))

export const deletePerson = (id) => from(del(`/api/persons/${id}`))

export const deleteUser = (id) => from(del(`/api/users/${id}`))

//FOR PROVENANCE:
export const loadProvenancePersonsAll = () => from(get(`/api/provenancePersons`))
export const loadProvenanceInstitutionsAll = () => from(get(`/api/provenanceInstitutions`))

// PERSONS
export const loadInstitutionsAll = () => from(get(`/api/institutions`))

export const loadInstitutions = (page) => from(get(`/api/institutions?page=${page}`))

export const searchInstitutions = (query) => from(get(`/api/institutions?per_page=${ITEMS_PER_PAGE}&q=${query}`))

export const loadInstitution = (id) => from(get(`/api/institutions/${id}`))

export const updateInstitution = (id, data) => from(patch(`/api/institutions/${id}`, data))

export const createInstitution = (data) => from(post(`/api/institutions`, data))

export const deleteInstitution = (id) => from(del(`/api/institutions/${id}`))

// ARCHIVES
export const loadArchives = (page) => from(get(`/api/archives?page=${page}`))

export const loadArchive = (id) => from(get(`/api/archives/${id}`))

export const updateArchive = (id, data) => from(patch(`/api/archives/${id}`, data))

export const createArchive = (data) => from(post(`/api/archives`, data))

// LITERATURES
export const loadLiteratures = (page) => from(get(`/api/literatures?page=${page}`))

export const loadLiterature = (id) => from(get(`/api/literatures/${id}`))

export const updateLiterature = (id, data) => from(patch(`/api/literatures/${id}`, data))

export const createLiterature = (data) => from(post(`/api/literatures`, data))

// EXHIBITIONS
export const loadExhibitionsAll = () => from(get(`/api/exhibitions`))

export const loadExhibitionsBasic = () => from(get(`/api/exhibitionsBasic`))

export const loadExhibitions = (page) => from(get(`/api/exhibitions?page=${page}`))

export const loadExhibition = (id) => from(get(`/api/exhibitions/${id}`))

export const updateExhibition = (id, data) => from(patch(`/api/exhibitions/${id}`, data))

export const createExhibition = (data) => from(post(`/api/exhibitions`, data))

export const deleteExhibition = (id) => from(del(`/api/exhibitions/${id}`))

// professions
export const loadProfessions = () => from(get(`/api/professions`))

// countries
export const loadCountries = () => from(get(`/api/countries`))

// KEYWORDS
export const loadKeywordsAll = () => from(get(`/api/getKeywords`))

export const loadKeywords = (page) => from(get(`/api/getKeywords?page=${page}`))

export const searchKeywords = (query) => from(get(`/api/keywords?per_page=${ITEMS_PER_PAGE}&q=${query}`))

export const loadKeyword = (id) => from(get(`/api/keywords/${id}`))

export const updateKeyword = (id, data) => from(patch(`/api/keywords/${id}`, data))

export const createKeyword = (data) => from(post(`/api/keywords`, data))

export const deleteKeyword = (id) => from(del(`/api/keywords/${id}`))

//DOCUMENTS:

export const loadDocuments = (page, orderBy, order, filters) =>
  from(
    get(
      `/api/documents?per_page=${ITEMS_PER_PAGE}&page=${page}&published=all&order_by=${orderBy}&order=${order}${filters}`
    )
  )

export const loadDocumentsAll = () => from(get(`/api/documents`))

export const searchDocuments = (query) =>
  from(get(`/api/documents?per_page=${ITEMS_PER_PAGE}&q=${query}&published=all`))

export const loadDocument = (id) => from(get(`/api/documents/${id}`))

export const updateDocument = (id, data) => from(patch(`/api/documents/${id}`, data))

export const createDocument = (data) => from(post(`/api/documents`, data))

export const deleteDocument = (id) => from(del(`/api/documents/${id}`))

export const deleteDocumentPage = (id) => from(del(`/api/documentPages/${id}`))

// CATALOGUES
export const loadCataloguesAll = () => from(get(`/api/catalogues`))

export const loadCatalogues = (page) => from(get(`/api/catalogues?page=${page}`))

export const loadCatalogue = (id) => from(get(`/api/catalogues/${id}`))

export const updateCatalogue = (id, data) => from(patch(`/api/catalogues/${id}`, data))

export const createCatalogue = (data) => from(post(`/api/catalogues`, data))

export const deleteCatalogue = (id) => from(del(`/api/catalogues/${id}`))

//FORGOT PASSWORD:

export const requestPasswordReset = (email, database) =>
  from(
    getHeaders().then((header) =>
      request
        .post(config.BASE_API_URL + '/api/password/email')
        .set(header.auth.key, header.auth.value)
        .set('X-Database', database ?? 'mysql')
        .set('Accept', 'application/json')
        .send({ email })
        .then(selectBody)
    )
  )

// export const requestPasswordReset = (email, database) =>
//   from(
//     getHeaders().then((header) => {
//       console.log('Logging request instead of making HTTP call:')
//       console.log('URL:', config.BASE_API_URL + '/password/email')
//       console.log('Headers:', {
//         [header.auth.key]: header.auth.value,
//         'X-Database': database ?? 'mysql',
//         'Accept': 'application/json',
//       })
//       console.log('Body:', { email, database })

//       // Simulate a response
//       return Promise.resolve(selectBody({ status: 'success', message: 'Password reset logged' }))
//     })
//   )

export const resetPassword = (token, email, newPassword, newPasswordConfirmation, database) => {
  return from(
    request
      .post(`${config.BASE_API_URL}/api/password/reset`)
      .set('X-Database', database ?? 'mysql')
      .set('Accept', 'application/json')
      .send({
        token,
        email,
        password: newPassword,
        password_confirmation: newPasswordConfirmation,
      })
      .then((response) => response.body)
  )
}

// Modified reset password function to log request instead of making HTTP call
// export const resetPassword = (token, email, newPassword, newPasswordConfirmation, database) =>
//   from(
//     getHeaders().then((header) => {
//       console.log('Logging request instead of making HTTP call:')
//       console.log('URL:', config.BASE_API_URL + '/password/reset')
//       console.log('Headers:', {
//         [header.auth.key]: header.auth.value,
//         'X-Database': database ?? 'mysql',
//         'Accept': 'application/json',
//       })
//       console.log('Body:', {
//         token,
//         email,
//         newPassword,
//         newPasswordConfirmation,
//         database,
//       })

//       // Simulate a response
//       return Promise.resolve(selectBody({ status: 'success', message: 'Password reset logged' }))
//     })
//   )
