//
import * as React from 'react'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
      info: null
    }
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error: error, info: info })
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    return this.state.hasError === true ? (
      <div>
        <h1>Something went wrong. Please refresh your browser.</h1>
        <h3>{this.state.error}</h3>
        <h3>{this.state.info}</h3>
      </div>
    ) : (
      this.props.children
    )
  }
}

export default ErrorBoundary
