import * as http from '../services/http'
import { merge, of } from 'rxjs'
import * as actionsTypes from './actionTypes'
import { getFromCache } from '../utils/cache'
import { handleError } from '../utils/utils'
import { goBack } from 'connected-react-router'
import { ofType } from 'redux-observable'
import { map, catchError, mergeMap, switchMap } from 'rxjs/operators'

export const loadUser = (id) => ({
  type: actionsTypes.USER_LOAD,
  payload: id,
})

export const userLoaded = (item) => ({
  type: actionsTypes.USER_LOADED,
  payload: item,
})

export const loadUserFailed = (error) => ({
  type: actionsTypes.USER_LOAD_FAILED,
  payload: error,
})

const getLoadUser = (id, state) => merge(getFromCache(state.users.items, id), http.loadUser(id))

export const loadUserEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionsTypes.USER_LOAD),
    switchMap((action) =>
      getLoadUser(action.payload, state$.value).pipe(map(userLoaded), catchError(handleError(loadUserFailed)))
    )
  )

export const updateUser = (data) => ({
  type: actionsTypes.USER_UPDATE,
  payload: data,
})

export const userUpdated = (data) => ({
  type: actionsTypes.USER_UPDATED,
  payload: data,
})

export const loadUpdateFailed = (error) => ({
  type: actionsTypes.USER_UPDATE_FAILED,
  payload: error,
})

export const updateUserEpic = (action$) =>
  action$.pipe(
    ofType(actionsTypes.USER_UPDATE),
    mergeMap((action) =>
      http
        .updateUser(action.payload.id, action.payload)
        .pipe(map(userUpdated), catchError(handleError(loadUpdateFailed)))
    )
  )

export const userCreate = (data) => ({
  type: actionsTypes.USER_CREATE,
  payload: data,
})

export const userCreated = (data) => ({
  type: actionsTypes.USER_CREATED,
  payload: data,
})

export const loadCreateFailed = (error) => ({
  type: actionsTypes.USER_CREATE_FAILED,
  payload: error,
})
export const deleteUser = (data) => {
  return {
    type: actionsTypes.USER_DELETE,
    payload: data,
  }
}
export const userDeleted = (data) => {
  return {
    type: actionsTypes.USER_DELETED,
    payload: data,
  }
}
export const deleteUserFailed = (error) => ({
  type: actionsTypes.USER_DELETE_FAILED,
  payload: error,
})
export const deleteUserEpic = (action$) =>
  action$.pipe(
    ofType(actionsTypes.USER_DELETE),
    switchMap((action) =>
      http.deleteUser(action.payload).pipe(
        map(userDeleted),
        mergeMap((action) => of(action, goBack())),
        catchError(handleError(deleteUserFailed))
      )
    )
  )
export const createUserEpic = (action$) =>
  action$.pipe(
    ofType(actionsTypes.USER_CREATE),
    mergeMap((action) =>
      http.createUser(action.payload).pipe(map(userCreated), catchError(handleError(loadCreateFailed)))
    )
  )
