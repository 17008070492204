import React from 'react'
import { COLOR_ACCENT_DEFAULT } from '../../config/colors'

export const Logo = ({ color = COLOR_ACCENT_DEFAULT }) => {
  const logoStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: color,
    fontSize: '26px',
    fontWeight: '500',
    lineHeight: 1,
    letterSpacing: '2px',
    width: '66px',
    height: '66px',
    border: `4px solid ${color}`,
    margin: '0 auto',
  }

  return (
    <div className="logo centered">
      <div className="logo-large" style={logoStyles}>
        <span>CR</span>
      </div>
    </div>
  )
}