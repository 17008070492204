import React, { useEffect } from 'react'
import { loadingState } from '../../utils/loadingState'
import Loader from './Loader'
import Error from '../error/Error'

const DataProviderFunctional = (props) => {
  useEffect(() => {
    if (props.load) {
      props.load(props)
    }
  }, [props.watch, props.page])

  // loading twice if try to replicate componentWillReceiveProps(nextProps) in the old DataProvider:
  // useEffect(() => {
  //   if (props.watch !== undefined) {
  //     console.log('2. props.watch !== undefined', props.watch)
  //     props.load(props)
  //   }
  // }, [props.watch])

  // componentWillReceiveProps(nextProps) { //in the old DataProvider:
  //   if (this.props.watch !== undefined) {
  //     if (nextProps.match.params[this.props.watch] !== this.props.match.params[this.props.watch]) {
  //       this.props.load(nextProps)
  //     }
  //   }
  // }

  const Component = props.component

  switch (props.loading) {
    case loadingState.LOADED:
      return <Component {...props} />
    case loadingState.LOADING:
      return <Loader />
    case loadingState.FAILED:
      return props.error !== undefined && <Error message={props.error} />
    default:
      return null
  }
}

export default DataProviderFunctional