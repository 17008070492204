import React from 'react'
import { useSelector } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { textareaStyle } from '../../form/style'
import { CATALOGUE_FORM } from '../../../services/forms'
import './CatalogueForm.css'
import { CatalogueArtworks } from './CatalogueArtworks'
import { CatalogueAuthors, CatalogueAuthorsInst } from './CatalogueAuthors'
import MarkupEditor from '../../form/MarkupEditor'
import { ARTWORK_IDS_FOR_CATALOGUES } from '../../../config/config'
import RadioList from '../../form/RadioList'
import { ReferenceFieldList } from '../../form/ReferencesListField'
import { DEFAULT } from '../../../utils/constants'
import { CustomTextField } from '../../general/CustomTextField'

const CatalogueFormPresentation = ({ lang, changeFieldValue, persons, artworks, match }) => {
  if (!persons) persons = {}
  const database = useSelector((state) => state.auth.database)
  const artworkIdsForCatalogues = ARTWORK_IDS_FOR_CATALOGUES[database] ?? ARTWORK_IDS_FOR_CATALOGUES[DEFAULT]
  return (
    <form className="form-container">
      <div className="row">
        <div className="flex-box">
          <div className="repeater-field">
            <div>
              <div className="reference-holder label--basic">Title NB</div>
            </div>
            <div>
              <div className="reference-holder label--basic">Title EN</div>
            </div>
          </div>
          <div className="repeater-field">
            <div>
              <Field
                id="title_nb-input"
                name="title_nb"
                component={CustomTextField}
                label="Title NB"
                hideLabel={true}
              />
            </div>
            <div>
              <Field
                id="title_en-input"
                name="title_en"
                component={CustomTextField}
                label="Title EN"
                hideLabel={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="flex-box">
          <CatalogueAuthors />
          <CatalogueAuthorsInst title="Author/Researcher (Institution)" />
        </div>
      </div>

      <div className="row">
        <div className="field-label">Description NB</div>
        <div className="rich-text-editor">
          <Field
            name={`description_nb`}
            {...textareaStyle}
            type="text"
            multiLine={true}
            minRows={3}
            component={MarkupEditor}
          />
        </div>
      </div>

      <div className="row">
        <div className="field-label">Description EN</div>
        <div className="rich-text-editor">
          <Field
            name={`description_en`}
            {...textareaStyle}
            type="text"
            multiLine={true}
            minRows={3}
            component={MarkupEditor}
          />
        </div>
      </div>

      <div className="row">
        <div className="field-label">References</div>
        <div className="references">
          <ReferenceFieldList name={`references.references`} formType={CATALOGUE_FORM} />
        </div>
      </div>

      <div className="row">
        <div className="field-label">ID used in CR</div>
        <div>
          <RadioList name="artworks_id" items={artworkIdsForCatalogues} formName={CATALOGUE_FORM} />
        </div>
      </div>

      <div className="row">
        <div className="section-title">Artworks</div>
        <CatalogueArtworks />
      </div>
    </form>
  )
}

const formProps = {
  form: CATALOGUE_FORM,
  destroyOnUnmount: false,
  enableReinitialize: true,
  //validate
}

const CatalogueForm = reduxForm(formProps)(CatalogueFormPresentation)

export default CatalogueForm
