import { of } from 'rxjs'
import { map, catchError, switchMap, concatMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import * as http from '../services/http'
import * as actionTypes from './actionTypes'
import { handleError } from '../utils/utils'

export const loadProvenancePersons = () => ({
  type: actionTypes.PROVENANCE_PERSONS_LOAD,
})

export const provenancePersonsLoaded = (response) => ({
  type: actionTypes.PROVENANCE_PERSONS_LOADED,
  payload: { page: response.current_page, items: response.data, total: response.last_page },
})

export const provenancePersonsLoadedAll = (response) => {
  return {
    type: actionTypes.PROVENANCE_PERSONS_LOADED_ALL,
    payload: { items: response },
  }
}

export const loadProvenancePersonsFailed = (error) => ({
  type: actionTypes.PROVENANCE_PERSONS_LOAD_FAILED,
  payload: error,
})

const getLoadProvenancePersonsAll = (state) => {
  let items = state.provenancePersons.items
  if (state.provenancePersons.stateAllLoaded) {
    return of(items).pipe(
      map((data) => Object.values(data)),
      concatMap(() => http.loadProvenancePersonsAll())
    )
  } else {
    return http.loadProvenancePersonsAll()
  }
}

export const loadProvenancePersonsEpic = (action$, state$) => {
  // console.log("loadProvenancePersonsEpic")
  return action$.pipe(
    ofType(actionTypes.PROVENANCE_PERSONS_LOAD),
    switchMap((action) =>
      getLoadProvenancePersonsAll(state$.value).pipe(
        map(provenancePersonsLoadedAll),
        catchError(handleError(loadProvenancePersonsFailed))
      )
    )
  )
}
