import * as React from 'react'
import { Field, FieldArray } from 'redux-form'
import { getArtworkDate, getArtworkTitle, getArtworkTitleFromTranslationsArr, getImageThumb } from '../../../utils/utils'

const ExhibitionArtworkBox = ({ input: { value } }) => {
  const imgSrc = getImageThumb(value.images)
  const title = getArtworkTitleFromTranslationsArr(value) || getArtworkTitle(value)
  const date = getArtworkDate(value)
  // if (value.titles) title = getArtworkTitle(value)

  return (
    <div className="artworkBox">
      <div className="artworkBoxImage" style={{ justifyContent: 'center' }}>
        <img src={imgSrc} alt={`artwork ${value.id}`} />
      </div>
      <figcaption className="artworkTitle figcaption">{title}</figcaption>
      <figcaption className="artworkCR figcaption">{date}</figcaption>
      <figcaption className="artworkCR figcaption">{value.cr_number ? `CR: ${value.cr_number}` : ''}</figcaption>
    </div>
  )
}

const ExhibitionArtworkComponent = ({ index, fields }) => (
  <div>
    <div key={index} className="artwork-row">
      {fields.map((member, index) => (
        <div key={index}>
          <Field name={member} component={ExhibitionArtworkBox} />
        </div>
      ))}
    </div>
  </div>
)

// TODO land is not used
export const ExhibitionArtworks = ({ lang }) => (
  <div>
    <FieldArray name="artworks" component={ExhibitionArtworkComponent} lang={lang} />
  </div>
)
