import React, { useEffect, useState, useCallback } from 'react'
import { RichUtils, EditorState } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import 'draft-js/dist/Draft.css'
import useOnclickOutside from 'react-cool-onclickoutside'
import {
  createEntity,
  findEntityInSelection,
  getEditorData,
  removeEntity,
} from './helpers'
import TextButton from '../general/TextButton'
//https://codesandbox.io/s/ykm1kjq6wz?file=/decorators/index.js
export const InsertComment = (props) => {
  const { editorState, onChange, title, showCommentInput, setShowCommentInput } = props
  const [entity, setEntity] = useState(findEntityInSelection(editorState, 'COMMENT'))

  const ref = useOnclickOutside(() => {
    setShowCommentInput(false)
  })

  const applyValue = useCallback(
    (e, enteredText) => {
      e.stopPropagation()
      if (e.keyCode === 13) {
        e.preventDefault()
        if (!editorState || !(editorState instanceof EditorState)) {
          console.error('Invalid editor state:', editorState)
          return
        }

        let newEditorState, entityKey

        if (entity === null) {
          newEditorState = createEntity(editorState, 'COMMENT', { value: enteredText })
        } else {
          entityKey = entity?.entityKey
          if (entityKey !== null && entityKey !== undefined) {
            const { contentState } = getEditorData(editorState)
            const contentStateWithEntity = contentState.mergeEntityData(entityKey, {
              value: enteredText,
            })
            newEditorState = EditorState.push(editorState, contentStateWithEntity, 'apply-entity-data')
          } else {
            console.error('Invalid entity key:', entityKey)
            return
          }
        }
        if (!newEditorState || !(newEditorState instanceof EditorState)) {
          console.error('Invalid new editor state:', newEditorState)
          return
        }
        onChange(newEditorState)
        setShowCommentInput(false)
      }
    },
    [editorState, entity, createEntity, getEditorData, onChange, setShowCommentInput]
  )
  const onRemoveClick = () => {
    if (editorState) {
      const newEditorState = removeEntity(editorState, 'COMMENT')
      onChange(newEditorState)
      setShowCommentInput(false)
    } else {
      console.error('Invalid editorState:', editorState)
    }
  }
  useEffect(() => {
    const foundEntity = findEntityInSelection(editorState, 'COMMENT')
    setEntity(foundEntity)
  }, [editorState])
  
  return (
    <div className="editor__custom-options rdw-inline-wrapper editor__comment-container">
      <div
        className="editor__btn--insert-person rdw-option-wrapper"
        onClick={(e) => {
          e.preventDefault()
          setShowCommentInput(true)
        }}
      >
        {title}
      </div>
      {showCommentInput && (
        <Input
          clickOutsideRef={ref}
          entityType="COMMENT"
          placeholder="Write down some comment..."
          editorState={editorState}
          onKeyDown={applyValue}
          onRemoveClick={onRemoveClick}
          enableRemove={entity !== null}
          commentValue={entity?.entity?.data?.value || ''}
        />
      )}
    </div>
  )
}
export const Input = (props) => {
  const { placeholder, onKeyDown, onRemoveClick, enableRemove, clickOutsideRef, commentValue } = props
  const [inputValue, setInputValue] = useState(commentValue)

  const handleChange = (e) => {
    setInputValue(e.target.value)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      onKeyDown(e, inputValue)
      setInputValue('')
    }
  }
  return (
    <div className="editor__comment rdw-link-modal" ref={clickOutsideRef}>
      <input
        name="comment"
        value={inputValue}
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        autoFocus
      />
      {enableRemove && <TextButton onClick={onRemoveClick} label="Remove" />}
    </div>
  )
}