import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ForgotPasswordForm from './ForgotPasswordForm'
import { Logo } from '../auth/Logo'
import { DATABASES } from '../../config/config'
import { forgotPasswordRequest } from '../../actions/forgotPasswordActions'

const ForgotPassword = () => {
  const colorAccent = useSelector((state) => state.auth.colorAccent)
  const error = useSelector((state) => state.forgotPassword.error)
  const success = useSelector((state) => state.forgotPassword.success)
  const message = useSelector((state) => state.forgotPassword.message)
  const loading = useSelector((state) => state.forgotPassword.loading)
  const dispatch = useDispatch()
  const onSubmit = (values) => {
    dispatch(forgotPasswordRequest(values))
  }
  const [selectedColor, setSelectedColor] = useState(colorAccent)
  const handleColorChange = (value) => {
    if (value) {
      const selectedDatabase = DATABASES.find((db) => db.id === value.id)
      if (selectedDatabase) {
        setSelectedColor(selectedDatabase.accent_color)
      } else {
        setSelectedColor(colorAccent)
      }
    } else {
      setSelectedColor(colorAccent)
    }
  }

  return (
    <div>
      <Logo color={selectedColor} />
      <ForgotPasswordForm onSubmit={onSubmit} loading={loading} handleColorChange={handleColorChange} />
      <div>{error && <div className="login-error">{message}</div>}</div>
      <div>{success && <div className="login-success">{message}</div>}</div>
    </div>
  )
}

export default ForgotPassword
