import * as actionTypes from '../actions/actionTypes'
import { loadingState } from '../utils/loadingState'
import { updateArtworksItemsCache, updatePagesCache } from '../utils/cache'
import { arrayToIdMap } from '../utils/cache'
import { itemsOrder } from './crud'

const initialState = {
  items: {},
  pages: {},
  page: 0,
  total: 0,
  state: loadingState.IDLE,
  stateAll: loadingState.IDLE,
  error: undefined,
  isGrid: false,
  isRecent: false,
  searchItems: [],
  searchError: undefined,
  searchState: loadingState.IDLE,
    sortOrder:'mostRecent',
  orderBy: 'id',
  order: 'desc'
}

export default (state = initialState, { type, payload }) => {  
  switch (type) {
    case actionTypes.ARTWORKS_LOAD:
      return { ...state, state: loadingState.LOADING, page: payload }

    case actionTypes.ARTWORKS_LOAD_ALL:
      return { ...state, stateAll: loadingState.LOADING }

    case actionTypes.ARTWORKS_LOADED:
      return {
        ...state,
        state: loadingState.LOADED,
        page: payload.page,
        total: payload.total,
        items: updateArtworksItemsCache(payload.items, state.items),
        pages: updatePagesCache(payload.page, payload.items, state.pages)
      }

    case actionTypes.ARTWORKS_LOADED_ALL:
      return {
        ...state,
        items: arrayToIdMap(payload.items),
        stateAll: loadingState.LOADED
      }

    case actionTypes.ARTWORKS_SEARCH:
      return { ...state, searchState: loadingState.LOADING, searchError: undefined }
    case actionTypes.ARTWORKS_SEARCH_FAILED:
      return { ...state, searchState: loadingState.FAILED, searchError: payload }
    case actionTypes.ARTWORKS_SEARCHED:
      return { ...state, searchState: loadingState.LOADED, searchItems: payload.items }

    case actionTypes.ARTWORK_LOADED:
    case actionTypes.ARTWORK_UPDATED:
    case actionTypes.ARTWORK_CREATED:
      return {
        ...state,
        items: updateArtworksItemsCache([payload], state.items)
      }
    case actionTypes.ARTWORKS_TOGGLE_VIEW:
      return { ...state, isGrid: payload }
    case actionTypes.ARTWORKS_TOGGLE_SORT:
      return { ...state, isRecent: payload, pages: {} }
    case actionTypes.ARTWORKS_ORDER:
      return itemsOrder(state, payload)
    case actionTypes.ARTWORKS_LOAD_FAILED:
      return { ...state, state: loadingState.FAILED, error: payload }
    default:
      return state
  }
}
