import * as actionTypes from '../actions/actionTypes'
import isDefined from 'crocks/predicates/isDefined'
import safe from 'crocks/Maybe/safe'
import {
    crudInitialState,
    itemLoad,
    itemLoaded,
    itemsLoadedAll,
    itemLoadFailed,
    itemSave,
    itemSavedCache,
    itemSaveFailed,
    itemsLoad,
    itemsLoaded,
    itemsLoadFailed,
    itemsChangeSortOrder,
    itemDelete,
    itemDeleted,
    itemDeleteFailed, itemsOrder
} from './crud'

const initialState = crudInitialState

const actionHandlers = {
  [actionTypes.KEYWORDS_LOAD]: itemsLoad,
  [actionTypes.KEYWORDS_LOADED]: itemsLoaded,
  [actionTypes.KEYWORDS_LOADED_ALL]: itemsLoadedAll,
  [actionTypes.KEYWORDS_LOAD_FAILED]: itemsLoadFailed,
  [actionTypes.KEYWORD_LOAD]: itemLoad,
  [actionTypes.KEYWORD_LOADED]: itemLoaded,
  [actionTypes.KEYWORD_LOAD_FAILED]: itemLoadFailed,
  [actionTypes.KEYWORD_UPDATE]: itemSave,
  [actionTypes.KEYWORD_CREATE]: itemSave,
  [actionTypes.KEYWORD_UPDATED]: itemSavedCache,
  [actionTypes.KEYWORD_CREATED]: itemSavedCache,
  [actionTypes.KEYWORD_UPDATE_FAILED]: itemSaveFailed,
  [actionTypes.KEYWORD_CREATE_FAILED]: itemSaveFailed,
  [actionTypes.KEYWORDS_CHANGE_SORT_ORDER]: itemsChangeSortOrder,
  [actionTypes.KEYWORDS_ORDER]: itemsOrder,
  [actionTypes.KEYWORD_DELETE]: itemDelete,
  [actionTypes.KEYWORD_DELETED]: itemDeleted,
  [actionTypes.KEYWORD_DELETE_FAILED]: itemDeleteFailed
}

export default (state = initialState, { type, payload }) =>
  safe(isDefined, actionHandlers[type])
    .map(handler => handler(state, payload))
    .option(state)
