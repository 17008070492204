import React from 'react'
import { push } from 'connected-react-router'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import * as router from '../../../services/router'
import { institutionsOrder, loadInstitutions } from '../../../actions/institutionsActions'
import { TopSearch } from '../../header/TopSearch'
import { formatDate, sortBy } from '../../../utils/utils'
import Filters from './Filters'
import { CustomTableCell, makeSortableTableHeader } from '../../general/Table'
import { getOrder } from '../../../reducers/crud'
import { Add } from '@material-ui/icons'
import DataProviderFunctional from '../../loader/DataProviderFunctional'
import { useDispatch, useSelector } from 'react-redux'
import TextButton from '../../general/TextButton'
import { useTheme } from '@material-ui/core'

const headers = [
  { field: 'name', title: 'Name' },
  { field: 'founded', title: 'Founded' },
  { field: 'closed', title: 'Closing Date' },
  { field: 'updated_at', title: 'Last Edited' },
]

const ListTableHead = makeSortableTableHeader(headers)

const TableRows = ({ items, goToUrl, orderBy, order }) => {
  const itemsWithData = items
  sortBy(itemsWithData, orderBy, order)
  return itemsWithData.map((item) => (
    <TableRow key={item.id} onClick={() => goToUrl(router.ROUTE_INSTITUTION_EDIT + item.id)}>
      <CustomTableCell>{`${item.name}`}</CustomTableCell>
      <CustomTableCell>{formatDate(item.founded)}</CustomTableCell>
      <CustomTableCell>{formatDate(item.closed)}</CustomTableCell>
      <CustomTableCell>{formatDate(item.updated_at)}</CustomTableCell>
    </TableRow>
  ))
}

const InstitutionsPresenter = () => {
  const theme = useTheme()
  const institutions = useSelector((state) => getInstitutions(state))
  const { order, orderBy } = useSelector((state) => getOrder(state, 'institutions'))
  const dispatch = useDispatch()
  const goToUrl = (url) => dispatch(push(url))
  const orderFn = (orderBy) => () => dispatch(institutionsOrder(orderBy))

  return (
    <div className="exhibitions">
      <TopSearch title="Institutions" FiltersComponent={Filters}>
        <div className="item-right">
          <TextButton
            label="Add institution"
            color="primary"
            startIcon={<Add  style={{ color: theme.palette.primary.main }} />}
            onClick={() => goToUrl(router.ROUTE_INSTITUTION_CREATE)}
          />
        </div>
      </TopSearch>

      <Table className="non-fixed">
        <ListTableHead orderBy={orderBy} order={order} orderFn={orderFn} />
        <TableBody>
          <TableRows orderBy={orderBy} order={order} items={institutions} goToUrl={goToUrl} />
        </TableBody>
      </Table>
    </div>
  )
}

const getInstitutions = (state) => {
  const institutions = Object.values(state.institutions.items)
  if (state.filters.visible) {
    //opposite fix
    return institutions
  } else {
    return institutions.filter((p) => {
      const searchable = p.name.toLowerCase()
      return searchable.includes(state.filters.q.toLowerCase())
    })
  }
}

const Institutions = () => {
  const loading = useSelector((state) => state.institutions.state)
  const error = useSelector((state) => state.institutions.error)
  const watch = 'page'
  const dispatch = useDispatch()
  const load = () => dispatch(loadInstitutions())

  return (
    <DataProviderFunctional
      error={error}
      load={load}
      loading={loading}
      watch={watch}
      component={InstitutionsPresenter}
    />
  )
}

export default Institutions
