import * as http from './http'
import { mergeMap, map } from 'rxjs/operators'
import * as localForage from 'localforage'
import { from } from 'rxjs'
import { isDefined } from '../utils/utils'

const setData = (data) => from(localForage.setItem('cr_data', data))

const getToken = (data) => {
  if (isDefined(data) && isDefined(data.token)) {
    return data.token.access_token
  }
  return undefined
}

export const login = (credentials) => {
  return from(localForage.setItem('cr_database', credentials.database)).pipe(
    mergeMap(() => http.login(credentials)),
    mergeMap((token) =>
      http
        .getProfile(token.access_token, credentials.database)
        .pipe(
          map((user) => ({ token, user, database: credentials.database })))
    ),
    mergeMap((data) => setData(data))
  )
}

export const logout = () => setData(undefined)

export const getLocalToken = () => localForage.getItem('cr_data').then(getToken)

export const getDatabase = () => localForage.getItem('cr_database')

export const getAuthData = () => from(localForage.getItem('cr_data'))
