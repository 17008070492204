import React from 'react'
import { Field } from 'redux-form'
import { CustomTextField } from '../general/CustomTextField'

export const MainMeasurmentFields = () => {
  return (
    <div className="flex-box">
      <div className="repeater-field">
        <div className="field-label">
          <span className="gap-maker">Measurements</span>
        </div>
        <div>
          <div className="reference-holder label--basic">Main</div>
        </div>
      </div>
      <div className="repeater-field">
        <Field id="height-input" name="height" component={CustomTextField} type="text" label="Height" />
      </div>
      <div className="repeater-field">
        <Field id="width-input" name="width" component={CustomTextField} type="text" label="Width" />
      </div>
    </div>
  )
}
