import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  customButton: {
    '&:hover': {
      backgroundColor: 'rgba(153, 153, 153, 0.2)',
    },
    borderRadius: '2px',
    padding: '6px 16px',
    minWidth: 88,
  },
  labelStyle: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: '500',
  },
  labelStyleAccent: {
    color: theme.palette.secondary.main,
    fontSize: '14px',
    fontWeight: '500',
  },
  labelStyleRemove: {
    color: theme.palette.error.main,
  },
}))

const TextButton = ({ label, type, fullWidth, color, startIcon, endIcon, onClick, isRecent, onMouseDown, remove = false }) => {
  const classes = useStyles()

  return (
    <Button
      type={type}
      fullWidth={fullWidth}
      color={color}
      className={classes.customButton}
      onClick={onClick}
      onMouseDown={onMouseDown}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      <Typography
        className={`${isRecent === false ? classes.labelStyleAccent : classes.labelStyle} ${
          remove && classes.labelStyleRemove
        }`}
      >
        {label}
      </Typography>
    </Button>
  )
}

export default TextButton
