import React, { useEffect, useState } from 'react'
import {
  compareByProperty,
  getArtworkDate,
  getArtworkTitle,
  getArtworkTitleFromTranslationsArr,
} from '../../../utils/utils'
import { useSelector } from 'react-redux'
import { ARTWORK_IDS_FOR_CATALOGUES } from '../../../config/config'
import { DEFAULT, UNDEFINED } from '../../../utils/constants'

const CatalogueArtworkBox = ({ item, selectedID, database }) => {
  let imgSrc = item.images.length > 0 ? item.images[0].thumb_url : ''
  const title = getArtworkTitleFromTranslationsArr(item) || getArtworkTitle(item)
  const date = getArtworkDate(item)
  const artworkIdTitle = ARTWORK_IDS_FOR_CATALOGUES[database]
    ? ARTWORK_IDS_FOR_CATALOGUES[database].find((i) => selectedID === i.value).title
    : ARTWORK_IDS_FOR_CATALOGUES[DEFAULT].find((i) => selectedID === i.value).title
  return (
    <div className="artworkBox">
      <div className="artworkBoxImage" style={{ justifyContent: 'center' }}>
        <img src={imgSrc} alt={`artwork ${item.id}`} />
      </div>
      <figcaption className="artworkTitle figcaption">{title}</figcaption>
      <figcaption className="artworkCR figcaption">{date}</figcaption>
      <figcaption className="artworkCR figcaption">{`${artworkIdTitle}: ${item[selectedID]}`}</figcaption>
      <figcaption className="artworkCR figcaption">{item.cr_number ? `CR: ${item.cr_number}` : ''}</figcaption>
    </div>
  )
}

export const CatalogueArtworks = () => {
  const artworks = useSelector((state) => state.artworks.items)
  const database = useSelector((state) => state.auth.database)
  const selectedID = useSelector((state) => state.form.catalogue.values.artworks_id)
  const [includedArtworks, setincludedArtworks] = useState([])

  useEffect(() => {
    const filteredArtworks = Object.values(artworks)
      .filter((item) => item[selectedID] !== null && item[selectedID] !== UNDEFINED)
      .sort(compareByProperty(selectedID))
    setincludedArtworks(filteredArtworks)
  }, [selectedID, artworks])

  return selectedID ? (
    <div className="artwork-row">
      {includedArtworks.length > 0 ? (
        includedArtworks.map((i, index) => (
          <div key={`${selectedID}-${i.id}-${index}`}>
            <CatalogueArtworkBox item={i} selectedID={selectedID} database={database} />
          </div>
        ))
      ) : (
        <div>No artworks are assigned a selected ID in Artworks workspace</div>
      )}
    </div>
  ) : (
    <div>Nothing selected in "ID used in CR" section yet</div>
  )
}
