import { of, from } from 'rxjs'
import { map, catchError, mergeMap, switchMap, concatMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import * as http from '../services/http'
import * as actionTypes from './actionTypes'
import { handleError } from '../utils/utils'
import * as router from '../services/router'
import { push, goBack } from 'connected-react-router'

export const loadKeywords = () => ({
  type: actionTypes.KEYWORDS_LOAD,
})

export const keywordsLoaded = (response) => ({
  type: actionTypes.KEYWORDS_LOADED,
  payload: { page: response.current_page, items: response.data, total: response.last_page },
})

export const keywordsLoadedAll = (response) => {
  return {
    type: actionTypes.KEYWORDS_LOADED_ALL,
    payload: { items: response },
  }
}

export const loadKeywordsFailed = (error) => ({
  type: actionTypes.KEYWORDS_LOAD_FAILED,
  payload: error,
})

const getLoadKeywordsAll = (state) => {
  let items = state.keywords.items
  if (state.keywords.stateAllLoaded) {
    return of(items).pipe(
      map((data) => Object.values(data)),
      concatMap(() => http.loadKeywordsAll())
    )
  } else {
    return http.loadKeywordsAll()
  }
}

export const loadKeywordsEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.KEYWORDS_LOAD),
    switchMap((action) =>
      getLoadKeywordsAll(state$.value).pipe(map(keywordsLoadedAll), catchError(handleError(loadKeywordsFailed)))
    )
  )

export const loadKeyword = (stableId) => ({
  type: actionTypes.KEYWORD_LOAD,
  payload: stableId,
})

export const keywordLoaded = (data) => ({
  type: actionTypes.KEYWORD_LOADED,
  payload: data,
})

export const loadKeywordFailed = (error) => ({
  type: actionTypes.KEYWORD_LOAD_FAILED,
  payload: error,
})

const getLoadKeyword = (stableId, state) => {
  let item = state.keywords.items[stableId]
  if (item) {
    return of(item).pipe(map((data) => data))
  } else {
    return http.loadKeyword(stableId)
  }
}

export const loadKeywordEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.KEYWORD_LOAD),
    switchMap((action) =>
      getLoadKeyword(action.payload, state$.value).pipe(map(keywordLoaded), catchError(handleError(loadKeywordFailed)))
    )
  )

export const updateKeyword = (data) => ({
  type: actionTypes.KEYWORD_UPDATE,
  payload: data,
})

export const keywordUpdated = (data) => ({
  type: actionTypes.KEYWORD_UPDATED,
  payload: data,
})

export const updateKeywordFailed = (error) => ({
  type: actionTypes.KEYWORD_UPDATE_FAILED,
  payload: error,
})

export const updateKeywordEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.KEYWORD_UPDATE),
    switchMap((action) =>
      from(http.updateKeyword(action.payload.id, action.payload)).pipe(
        map(keywordUpdated),
        catchError(handleError(updateKeywordFailed))
      )
    )
  )

export const createKeyword = (data) => ({
  type: actionTypes.KEYWORD_CREATE,
  payload: data,
})

export const keywordCreated = (data) => ({
  type: actionTypes.KEYWORD_CREATED,
  payload: data,
})

export const createKeywordFailed = (error) => ({
  type: actionTypes.KEYWORD_CREATE_FAILED,
  payload: error,
})

export const createKeywordEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.KEYWORD_CREATE),
    switchMap((action) =>
      from(http.createKeyword(action.payload)).pipe(
        map(keywordCreated),
        mergeMap((action) => of(action, push(router.ROUTE_KEYWORD_EDIT + action.payload.id))),
        catchError(handleError(createKeywordFailed))
      )
    )
  )

export const deleteKeyword = (data) => {
  return {
    type: actionTypes.KEYWORD_DELETE,
    payload: data,
  }
}

export const keywordDeleted = (data) => {
  return {
    type: actionTypes.KEYWORD_DELETED,
    payload: data,
  }
}

export const deleteKeywordFailed = (error) => ({
  type: actionTypes.KEYWORD_DELETE_FAILED,
  payload: error,
})

export const deleteKeywordEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.KEYWORD_DELETE),
    switchMap((action) =>
      http.deleteKeyword(action.payload).pipe(
        map(keywordDeleted),
        mergeMap((action) => of(action, goBack())),
        catchError(handleError(deleteKeywordFailed))
      )
    )
  )

export const changeSortOrder = (sortOrder) => ({
  type: actionTypes.KEYWORDS_CHANGE_SORT_ORDER,
  payload: sortOrder,
})

export const keywordsOrder = (orderBy, order) => ({
  type: actionTypes.KEYWORDS_ORDER,
  payload: { orderBy, order },
})
