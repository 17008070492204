import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { change, Field, FieldArray } from 'redux-form'
import { fieldStyle } from './style'
import propOr from 'crocks/helpers/propOr'
import { Tooltip, IconButton } from '@material-ui/core'
import { ARTWORK_FORM } from '../../services/forms'
import { Close } from '@material-ui/icons'
import { RepeaterDefaultAdd } from './Repeater'
import Select from 'react-select'
import { COLOR_LIGHT_TEXT } from '../../config/colors'

const wrapperStyle = {
  width: '256px',
}

const AutoComplete = ({ input, items, changeFieldValue }) => {
  return (
    <Select
      wrapperStyle={wrapperStyle}
      name="keyword"
      options={items}
      labelKey="name"
      valueKey="id"
      value={input.value}
      onChange={(value) => changeFieldValue(input.name, propOr(undefined, 'id', value))}
      clearable={false}
      searchable={true}
    />
  )
}

const KeywordField = ({ member, index, fields, items, changeFieldValue }) => (
  <div key={index} className="flex-box select-reference artwork-row repeater-field">
    <div className="repeater-field keyword-select">
      {/* {index === 0 ? <div className="field-label">Keywords</div> : null} */}
      <Field
        name={`${member}.id`}
        items={items}
        changeFieldValue={changeFieldValue}
        {...fieldStyle}
        component={AutoComplete}
      />
    </div>
    <Tooltip title="Remove">
      <IconButton className="repeater-remove" onClick={() => fields.remove(index)}>
        <Close style={{ color: COLOR_LIGHT_TEXT}} />
      </IconButton>
    </Tooltip>
  </div>
)

const KeywordContainer = ({ fields }) => {
  const keywords = useSelector((state) => state.keywords.items)
  const items = Object.values(keywords).map((item) => {
    return { ...item, name: item.keyword }
  })
  const dispatch = useDispatch()
  const changeFieldValue = (field, value) => dispatch(change(ARTWORK_FORM, field, value))

  return (
    <div className="repeater">
      {fields.map((member, index) => (
        <KeywordField
          key={index}
          member={member}
          index={index}
          fields={fields}
          items={items}
          changeFieldValue={changeFieldValue}
        />
      ))}
      <RepeaterDefaultAdd fields={fields} addFn={() => fields.push({ ...items[0] })} label="Add keyword" />
    </div>
  )
}

export const ArtworkKeywordField = (props) => (
  <FieldArray name="artwork_keywords" component={KeywordContainer} {...props} />
)
