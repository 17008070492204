import React from 'react'
import FormTop from '../../form/FormTop'
import { EditTop } from '../../general/EditTop'
import { EXHIBITION_FORM } from '../../../services/forms'
import { PublishedStatus } from '../../general/PublishedStatus'

const ExhibitionControls = ({ remoteSubmit, remove, saveState, changeFieldValue, initialValues }) => {
  return (
    <div className="Exhibition-controls">
      <EditTop title="Edit Exhibition" remove={remove} />
      <div className="controls">
        <FormTop
          title={initialValues.title}
          button="Save Exhibition"
          remote={remoteSubmit}
          remove={remove}
          saveState={saveState}
        />
        <PublishedStatus changeFieldValue={changeFieldValue} formName={EXHIBITION_FORM} />
      </div>
    </div>
  )
}

export default ExhibitionControls
