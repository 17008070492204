import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { useTheme } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import { Add } from '@material-ui/icons'
import * as router from '../../../services/router'
import { loadPersons, personsOrder } from '../../../actions/personsActions'
import { TopSearch } from '../../header/TopSearch'
import { GENDERS, TITLES } from '../../../config/config'
import { dateByAccuracy, formatDate, sortBy } from '../../../utils/utils'
import Filters from './Filters'
import { CustomTableCell, makeSortableTableHeader } from '../../general/Table'
import { getOrder } from '../../../reducers/crud'
import DataProviderFunctional from '../../loader/DataProviderFunctional'
import TextButton from '../../general/TextButton'

const headers = [
  { field: 'lastname', title: 'Last Name' },
  { field: 'firstname', title: 'First Name' },
  { field: 'date_birth', title: 'Born' },
  { field: 'date_death', title: 'Death' },
  { field: 'updated_at', title: 'Last Edited' },
]

const ListTableHead = makeSortableTableHeader(headers)

const TableRows = ({ items, professions, countries, sortOrder, goToUrl, orderBy, order }) => {
  const itemsWithData = items.map((item) => {
    const title = item.title ? TITLES.find((t) => t.value === item.title).title : ''
    const gender = item.gender ? GENDERS.find((t) => t.value === item.gender).title : ''
    //const professionTitle = professions[item.profession_id] ? professions[item.profession_id].title : ''
    //const nationalityTitle = countries[item.nationality_id] ? countries[item.nationality_id].name : ''
    return {
      ...item,
      title,
      gender,
      //profession_title: professionTitle,
      //nationality_title: nationalityTitle,
    }
  })

  sortBy(itemsWithData, orderBy, order)

  return itemsWithData.map((item) => (
    <TableRow key={item.id} onClick={() => goToUrl(router.ROUTE_PERSON_EDIT + item.id)}>
      <CustomTableCell>{`${item.lastname}`}</CustomTableCell>
      <CustomTableCell>{`${item.firstname}`}</CustomTableCell>
      <CustomTableCell>
        {dateByAccuracy(item.accuracy_birth, item.birth_year, item.birth_month, item.birth_day)}
      </CustomTableCell>
      <CustomTableCell>
        {dateByAccuracy(item.accuracy_death, item.death_year, item.death_month, item.death_day)}
      </CustomTableCell>
      <CustomTableCell>{formatDate(item.updated_at)}</CustomTableCell>
    </TableRow>
  ))
}

const PersonsPresenter = () => {
  const persons = useSelector((state) => getPersons(state))
  const theme = useTheme()
  // const professions = useSelector((state) => state.professions.items)
  // const countries = useSelector((state) => state.countries.items)
  const { order, orderBy } = useSelector((state) => getOrder(state, 'persons'))
  const dispatch = useDispatch()
  const goToUrl = (url) => dispatch(push(url))
  const orderFn = (orderBy) => () => dispatch(personsOrder(orderBy))

  return (
    <div className="exhibitions">
      <TopSearch title="People" FiltersComponent={Filters}>
        <div className="item-right">
          <TextButton
            label="Add person"
            onClick={() => goToUrl(router.ROUTE_PERSON_CREATE)}
            color="primary"
            startIcon={<Add  style={{ color: theme.palette.primary.main }} />}
          />
        </div>
      </TopSearch>

      <Table className="non-fixed">
        <ListTableHead orderBy={orderBy} order={order} orderFn={orderFn} />
        <TableBody>
          <TableRows
            orderBy={orderBy}
            order={order}
            items={persons}
            // professions={professions}
            // countries={countries}
            goToUrl={goToUrl}
          />
        </TableBody>
      </Table>
    </div>
  )
}

const getPersons = (state) => {
  const persons = Object.values(state.persons.items)
  if (state.filters.visible) {
    //opposite fix
    return persons
  } else {
    return persons.filter((p) => {
      let searchable = `${p.firstname.toLowerCase()} ${p.lastname.toLowerCase()}`
      p.alternative_names.map((item) => (searchable += ' ' + item.name.toLowerCase()))
      return searchable.includes(state.filters.q.toLowerCase())
    })
  }
}

const Persons = () => {
  const error = useSelector((state) => state.persons.error)
  const loading = useSelector((state) => state.persons.state)
  const watch = 'page'
  const dispatch = useDispatch()
  const load = () => {
    dispatch(loadPersons())
    // dispatch(loadProfessions())
    // dispatch(loadCountries())
  }
  return (
    <DataProviderFunctional error={error} load={load} loading={loading} watch={watch} component={PersonsPresenter} />
  )
}

export default Persons
