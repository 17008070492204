import React from 'react'
import { PERSON_FORM } from '../../../services/forms'
import { createPerson } from '../../../actions/personsActions'
import PersonForm from '../form/PersonForm'
import { change, isDirty, submit } from 'redux-form'
import FormTop from '../../form/FormTop'
import { uploadOwnerPhoto } from '../../../services/http'
import { useDispatch, useSelector } from 'react-redux'
import { personModel } from '../../../models/personModel'
import { EditTop } from '../../general/EditTop'

const PersonCreate = () => {
  const initialValues = personModel
  const saveState = useSelector((state) => state.persons.saveState)
  const dispatch = useDispatch()
  const onSubmit = (data) => dispatch(createPerson({ ...data }))
  const remoteSubmit = () => dispatch(submit(PERSON_FORM))
  const changeFieldValue = (field, value) => dispatch(change(PERSON_FORM, field, value))
  const upload = uploadOwnerPhoto

  return (
    <div>
      <div className="Exhibition-controls">
        <EditTop title="Create Person" />

        <div className="controls">
          <FormTop button="Save Person" remote={remoteSubmit} saveState={saveState} />
        </div>
      </div>

      <div className="Exhibition-grid">
        {/* <NavigationPrompt when={dirty} /> */}
        <PersonForm
          onSubmit={onSubmit}
          changeFieldValue={changeFieldValue}
          initialValues={initialValues}
          upload={upload}
        />
      </div>
      <div />
    </div>
  )
}

export default PersonCreate
