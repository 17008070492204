import React from 'react'
import propOr from 'crocks/helpers/propOr'
import Select from 'react-select'

const wrapperStyle = {
  width: '256px',
}

export const AutoComplete = ({ input, changeFieldValue, items }) => {
  return (
    <Select
      wrapperStyle={wrapperStyle}
      name={input.name}
      options={items}
      labelKey="name"
      valueKey="id"
      value={input.value}
      onChange={(value) => changeFieldValue(input.name, propOr(undefined, 'id', value))}
      clearable={false}
      searchable={true}
    />
  )
}
