import React, { useState } from 'react'
import { REFERENCE_TYPES } from '../../config/config'
import { MenuItem } from '@material-ui/core'
import { Field } from 'redux-form'
import { fieldsContent } from './fieldsContent'
import { CustomSelect } from '../general/CustomSelect'
import { CustomTextField } from '../general/CustomTextField'

export const FormFields = ({ initialType }) => {
  const [currentType, setCurrentType] = useState(initialType)

  return (
    <div className="row">
      {currentType
        ? fieldsContent[currentType].map((field, index) => (
            <div key={index}>
              <Field
                id={`${field[0]}-input`}
                name={field[0]}
                type="text"
                width="full"
                label={field[1]}
                component={CustomTextField}
              />
            </div>
          ))
        : null}
      <div className="field-label">Type</div>
      <Field
        name="type"
        component={CustomSelect}
        onChange={(e, value) => {
          setCurrentType(value)
        }}
      >
        {REFERENCE_TYPES.map((item, i) => (
          <MenuItem key={i} value={item.value}>
            {item.title}
          </MenuItem>
        ))}
      </Field>
    </div>
  )
}
