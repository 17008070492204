import { of } from 'rxjs'
import moment from 'moment'
import { logout } from '../actions/authActions'
import crocks from 'crocks'
import {
  DATABASES,
  DEFAULT_LANGUAGE,
  OWNER_TYPE_INSTITUTION,
  SECOND_LANGUAGE,
  TITLE_STATUS_ESTABLISHED,
} from '../config/config'
import { ASC } from '../reducers/crud'
import { COLOR_ACCENT_DEFAULT } from '../config/colors'

const { propPathOr } = crocks

export const isDefined = (object) => object !== undefined && object !== null

export const range = (start, end) => {
  const result = []
  for (let i = start; i < end; i++) {
    result.push(i)
  }
  return result
}

export const toInt = (x) => Number.parseInt(x, 10)

export const handleError = (errorFunction) => (error) =>
  typeof error === 'object'
    ? error.status === 401
      ? of(logout())
      : of(errorFunction(error.message))
    : of(errorFunction(error))

export const getDate = (date) => {
  return moment.utc(date).format('YYYY-MM-DD')
}

export const getTranslatableText = (translations, field) => {
  let text = 'MISSING_TEXT'
  if (translations[DEFAULT_LANGUAGE] && translations[DEFAULT_LANGUAGE][field]) {
    text = translations[DEFAULT_LANGUAGE][field]
  } else if (translations[SECOND_LANGUAGE] && translations[SECOND_LANGUAGE][field]) {
    text = translations[SECOND_LANGUAGE][field]
  }
  return text
}

export const getArtworkTitle = (artwork) => {
  const established = or(artwork.titles, []).filter((title) => title.status === TITLE_STATUS_ESTABLISHED)
  return propPathOr('', [0, 'translations', 'nb', 'title'])(established)
}

export const getArtworkTitleFromTranslationsArr = (artwork) => {
  const established = or(artwork.titles, []).filter((title) => title.status === TITLE_STATUS_ESTABLISHED)
  return propPathOr('', [0, 'translations', 0, 'title'])(established)
}

export const getImageThumb = (images) =>
  images.find((i) => i.type === 'photo_1')
    ? images.find((i) => i.type === 'photo_1').thumb_url
    : images[0]
    ? images[0].thumb_url
    : ''

export const getArtworkImage = propPathOr('', ['images', 0, 'thumb_url'])

export const getOwnerName = (owner) =>
  owner.type === OWNER_TYPE_INSTITUTION ? owner.name : `${owner.firstname} ${owner.lastname}`

export const relativeTime = (time) => moment.utc(time).fromNow()

export const debug = (arg) => {
  console.log(arg)
  debugger
  return false
}

const compare = (a, b) => {
  if (typeof a === 'string') {
    return a.localeCompare(b)
  } else {
    return a < b ? -1 : a > b ? 1 : 0
  }
}

export const sortBy = (items, field, order) => {
  const x = order === ASC ? 1 : -1
  return items ? items.sort((a, b) => compare(a[field], b[field]) * x) : items
}

export const artworkSortBy = (items, field, order) => {
  const x = order === ASC ? 1 : -1
  if (field === 'titles') {
    items.sort((a, b) => compare(getArtworkTitle(a), getArtworkTitle(b)) * x)
  } else {
    items.sort((a, b) => compare(a[field], b[field]) * x)
  }
}

export const sortByDate = (items, dateField) => {
  items.sort((a, b) => {
    const dateA = new Date(a[dateField])
    const dateB = new Date(b[dateField])
    return dateA > dateB ? -1 : dateA < dateB ? 1 : 0
  })
}

export const getTitleByValue = (value, array) => {
  let elem = array.find((item) => item.value === value)
  return elem && elem.title ? elem.title : 'NO TITLE'
}

export const getTitleByValueInObject = (value, object) => {
  for (const key in object) {
    const subtypeArray = object[key];
    const elem = subtypeArray.find(item => item.value === value);
    if (elem && elem.title) {
      return elem.title;
    }
  }
  return 'NO TITLE';
};

export const formatDate = (date, date_accuracy) => {
  if (!date) {
    return 'No date'
  }
  switch (date_accuracy) {
    case 8: // year only
      return moment(date.toString()).format('YYYY')
    case 9: // year and month
      return moment(date.toString()).format('YYYY-MM')
    default:
      return moment(date.toString()).format('DD.MM.YYYY')
  }
}
// export const DATE_TYPES_PERSON = [
//     { title: '', value: 0, titleNb: '' },
//     { title: 'Circa year', value: 2, titleNb: 'Cirka' },
//     { title: 'Uncertain', value: 6, titleNb: 'Usikker' },
//     { title: 'Year only', value: 8, titleNb: 'Kun år' },
//     { title: 'Year and month', value: 9, titleNb: 'År og måned' },
//     { title: 'Year, month and day', value: 10, titleNb: 'År og måned' }
// ]
export const dateByAccuracy = (accuracy, year, month, day) => {
  switch (accuracy) {
    case 2: //Circa year
      return `Circa ${year}`
    case 6: // Uncertain
      return 'Uncertain'
    case 8: //Year only
      return year
    case 9: // Year and month'
      const date = moment()
      date.year(year).month(month - 1)
      return date.format('MM.YYYY')
    case 10:
      const d = moment()
      d.year(year)
      d.month(month - 1)
      d.date(day)
      return d.format('DD.MM.YYYY')
    default:
      return ''
  }
}

export const getDatePickedMinDate = () => moment().subtract(300, 'year').toDate()

export const containsById = (items, id) => items.some((x) => x.id === id)

export const containsByReferenceId = (items, id) => items.some((x) => x.reference_id === id)

export const or = (value, defaultValue) => (value ? value : defaultValue)

export const removeEmpty = (arr) => {
  if (arr !== undefined && arr.length !== 0) {
    return arr.filter((el) => {
      return el !== undefined && el.reference_id !== undefined ? el : null
    })
  }
}

const removePrefix = (item) => {
  const copy = { ...item, id: item.id.toString() }
  const index = copy.id.indexOf('_') + 1
  copy.id = copy.id.substring(index)
  return copy
}

export const artworkToServer = (artwork) => {
  artwork.provenance = or(artwork.provenance, [])
  artwork.owners = []
  artwork.institutions = []
  artwork.provenance = artwork.provenance.filter((item) => item.id)
  artwork.provenance.forEach((x) =>
    x.id.toString().startsWith('owner_')
      ? artwork.owners.push(removePrefix(x))
      : artwork.institutions.push(removePrefix(x))
  )
  return artwork
}

export const getHotspotCoords = (e, hotspotRef) => {
  return {
    top: parseInt(
      ((e.clientY - hotspotRef.current.getBoundingClientRect().top) /
        hotspotRef.current.getBoundingClientRect().height) *
        100,
      10
    ),
    left: parseInt(
      ((e.clientX - hotspotRef.current.getBoundingClientRect().left) /
        hotspotRef.current.getBoundingClientRect().width) *
        100,
      10
    ),
  }
}

export const getArtworkType = (types, type) => {
  const typeTitle = types.filter((t) => t.value === type)[0] ? types.filter((t) => t.value === type)[0].title : ''
  return typeTitle
}

export const stripLettersAndConvertToNumber = (str) => {
  return Number(str.replace(/[^0-9]/g, ''))
}

export const compareByProperty = (property) => (a, b) => {
  const propA = stripLettersAndConvertToNumber(String(a[property]))
  const propB = stripLettersAndConvertToNumber(String(b[property]))

  if (propA < propB) {
    return -1
  }
  if (propA > propB) {
    return 1
  }
  return 0
}

export const getArtworkDate = (artwork, undated = 'undated') => {
  return artwork.year_from & artwork.year_to
    ? artwork.year_from + ' - ' + artwork.year_to
    : artwork.date_year && artwork.year_to
    ? artwork.date_year + ' - ' + artwork.year_to
    : artwork.date_year && artwork.year_to === null
    ? artwork.date_year
    : artwork.year_from
    ? artwork.year_from
    : artwork.year_date
    ? artwork.year_date
    : undated
}

export const getAccentColor = (database) => {
  const databaseInfo = DATABASES.find((db) => db.id === database)

  if (databaseInfo) {
    return databaseInfo.accent_color
  } else {
    return COLOR_ACCENT_DEFAULT
  }
}

export const documentAuthorsString = (persons = [], institutions = []) => {
  const personAuthors = persons.map(i => `${i.lastname}, ${i.firstname}`)
  const institutionAuthors = institutions.map(i => `${i.name}${i.name_nb ? ' (' + i.name_nb + ')' : ''}`)
  const mergedAuthors = personAuthors.concat(institutionAuthors)
  return mergedAuthors.join(', ')
}