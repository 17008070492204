import React from 'react'
import UserForm from '../form/UserForm'
import { useDispatch } from 'react-redux'
import { userCreate } from '../../../actions/userActions'
import { submit } from 'redux-form'
import { USER_FORM } from '../../../services/forms'
import TextButton from '../../general/TextButton'

const UserCreate = () => {
  const dispatch = useDispatch()
  const onSubmit = (data) => dispatch(userCreate(data))
  const remoteSubmit = () => dispatch(submit(USER_FORM))

  return (
    <div>
      <div className="controls">
        <div className="form-top left-right-block flex-box-centered shadow">
          <div className="left-title">Create user</div>
          <div className=" flex-box-centered">
            <TextButton onClick={remoteSubmit} type="submit" label="Save" color="primary" />
          </div>
        </div>
      </div>
      <UserForm onSubmit={onSubmit} initialValues={{}} />
    </div>
  )
}

export default UserCreate
