import * as React from 'react'
import { DATE_TYPES_PROVENANCE, ACQUISITION_METHOD, DATE_TYPES_PROVENANCE_CURRENT } from '../../config/config'
import { change, Field, FieldArray } from 'redux-form'
import { fieldStyle } from './style'
import propOr from 'crocks/helpers/propOr'
import { ReferenceField } from './ReferenceField'
import { ARTWORK_FORM } from '../../services/forms'
import { Close } from '@material-ui/icons'
import { RepeaterDefaultAdd } from './Repeater'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip, IconButton, MenuItem } from '@material-ui/core'
import { COLOR_LIGHT_TEXT } from '../../config/colors'
import { CustomSelect } from '../general/CustomSelect'
import { CustomRadioGroup } from '../general/CustomRadioGroup'
import { CustomRadio } from '../general/CustomRadio'
import { CustomTextField } from '../general/CustomTextField'

const wrapperStyle = {
  width: '256px',
}

const AutoComplete = ({ input, items, changeFieldValue }) => {
  return (
    <Select
      wrapperStyle={wrapperStyle}
      //id="persons"
      name="persons"
      options={items}
      labelKey="name"
      valueKey="id"
      value={input.value}
      onChange={(value) => changeFieldValue(input.name, propOr(undefined, 'id', value))}
      clearable={false}
      searchable={true}
    />
  )
}

const ProvenanceField = ({ member, index, fields, items, changeFieldValue }) => {
  const colorAccent = useSelector((state) => state.auth.colorAccent)
  const checkboxStyles = {
    checkbox: {
      fill: colorAccent,
      color: colorAccent,
    },
  }
  return (
    <div key={index} className="flex-box select-reference artwork-row repeater-field">
      <div className="repeater-field">
        {index === 0 ? <div className="field-label">Provenance</div> : null}
        <Field
          name={`${member}.id`}
          items={items}
          changeFieldValue={changeFieldValue}
          {...fieldStyle}
          component={AutoComplete}
        />
      </div>

      <div>
        {index === 0 ? <div className="field-label">Ownership</div> : null}
        <div className="flex-box-centered">
          <Field name={`${member}.ownership`} component={CustomSelect} styles={{ width: 180, marginRight: 16 }}>
            {ACQUISITION_METHOD.map((item, i) => (
              <MenuItem key={i} value={item.value}>
                {item.title}
              </MenuItem>
            ))}
          </Field>
        </div>
      </div>

      <div>
        {index === 0 ? <div className="field-label">Accuracy</div> : null}
        <div className="flex-box-centered">
          <Field name={`${member}.from_accuracy`} component={CustomSelect} styles={{ width: 180, marginRight: 16 }}>
            {DATE_TYPES_PROVENANCE.map((item, i) => (
              <MenuItem key={i} value={item.value}>
                {item.title}
              </MenuItem>
            ))}
          </Field>
        </div>
      </div>

      <div className="repeater-field">
        {index === 0 ? <div className="field-label">From</div> : null}
        <Field
          id={`${member}.from_year-${index}-input`}
          name={`${member}.from_year`}
          component={CustomTextField}
          width="short"
          label="From"
          hideLabel={true}
        />
      </div>

      <div className="light-radio repeater-field present-field">
        {index === 0 ? <div className="field-label">Current</div> : null}
        <Field
          className="single-radio"
          name={`${member}.is_present`}
          format={(value) => value.toString()}
          onChange={() => {
            fields.map((field, index) => changeFieldValue(`${field}.is_present`, '0'))
          }}
          component={CustomRadioGroup}
        >
          <CustomRadio value={'1'} />
        </Field>
      </div>

      {
        <React.Fragment>
          <div>
            {index === 0 ? <div className="field-label">Accuracy</div> : null}
            <div
              className="flex-box-centered"
              //style={fields.get(index).is_present === 1 ? { visibility: 'hidden', opacity: 0 } : {}}
            >
              <Field name={`${member}.to_accuracy`} component={CustomSelect} styles={{ width: 180, marginRight: 16 }}>
                {DATE_TYPES_PROVENANCE_CURRENT.map((item, i) => (
                  <MenuItem key={i} value={item.value}>
                    {item.title}
                  </MenuItem>
                ))}
              </Field>
            </div>
          </div>

          <div>
            {index === 0 ? <div className="field-label">To</div> : null}
            <div style={fields.get(index).is_present === 1 ? { visibility: 'hidden', opacity: 0 } : {}}>
              <Field
                id={`${member}.to_year-${index}-input`}
                name={`${member}.to_year`}
                component={CustomTextField}
                width="short"
                label="To"
                hideLabel={true}
              />
            </div>
          </div>
        </React.Fragment>
      }

      <ReferenceField name={`references.provenance_${index}`} formType={ARTWORK_FORM} />
      <Tooltip title="Remove">
        <IconButton
          className="repeater-remove"
          onClick={() => {
            fields.remove(index)
            changeFieldValue(`references.provenance_${index}`, [])
          }}
        >
          <Close style={{ color: COLOR_LIGHT_TEXT }} />
        </IconButton>
      </Tooltip>
    </div>
  )
}

const OwnersContainer = ({ fields, items, changeFieldValue }) => (
  <div>
    <div className="section-title">Provenance</div>

    <div className="repeater">
      {fields.map((member, index) => (
        <ProvenanceField
          key={index}
          member={member}
          index={index}
          fields={fields}
          items={items}
          changeFieldValue={changeFieldValue}
        />
      ))}
      <RepeaterDefaultAdd
        fields={fields}
        addFn={() => fields.push({ ...items[0], is_present: 0 })}
        label="Add provenance"
      />
    </div>
  </div>
)

export const ArtworkProvenance = (props) => <FieldArray name="provenance" component={OwnersContainer} {...props} />

export const ArtworkProvenanceField = () => {
  const persons = useSelector((state) => state.provenancePersons.items)
  const institutions = useSelector((state) => state.provenanceInstitutions.items)
  const items = Object.values(persons)
    .map((i) => ({ id: 'owner_' + i.id, name: `${i.firstname} ${i.lastname}` }))
    .concat(Object.values(institutions).map((i) => ({ id: 'institution_' + i.id, name: i.name })))
  const dispatch = useDispatch()
  const changeFieldValue = (field, value) => dispatch(change(ARTWORK_FORM, field, value))
  return <ArtworkProvenance items={items} changeFieldValue={changeFieldValue} />
}
