import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import App from './components/app/App'
import { unregister } from './registerServiceWorker'
import Modal from 'react-modal'
import { Provider } from 'react-redux'
import { configureStore, history } from './configureStore'
import { ConnectedRouter } from 'connected-react-router'
import './index.css'

Sentry.init({
  dsn: 'https://619161ddb4624f0fa611a69af81b1f9b@o557285.ingest.sentry.io/5690293',
  integrations: [new Sentry.BrowserTracing()],
  environment: 'production',
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
  normalizeDepth: 2,
  max_breadcrumbs: 50,
  beforeSend(event) {
    if (event.user) {
      delete event.user.email;
    }
    return event;
  },
})

const root = document.getElementById('root')
if (root === null) {
  throw new Error('Root node not found')
}

Modal.setAppElement(root)

//const history = createBrowserHistory()
//const store = configureStore(history)

const store = configureStore()

const render = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>,

    root
  )
}

//registerServiceWorker()

unregister()

render(App)

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./components/app/App', () => {
    render(App)
  })
}
