import React from 'react'
import { ARTWORK_TYPES } from '../../config/config'
import { PrintPlateMeasurmentFields } from './PrintPlateMeasurmentFields'
import { PaintingMeasurmentFields } from './PaintingMeasurementFields'
import { PhotographyMeasurmentFields } from './PhotographyMeasurementFields'
import { PrintMeasurmentFields } from './PrintMeasurmentFields'
import { MainMeasurmentFields } from './MainMeasurmentFields'

export const MeasurmentsFieldsByType = {
  [ARTWORK_TYPES.PAINTING]: <PaintingMeasurmentFields />,
  [ARTWORK_TYPES.PRINT_PLATE]: <PrintPlateMeasurmentFields />,
  [ARTWORK_TYPES.PHOTOGRAPH]: <PhotographyMeasurmentFields />,
  [ARTWORK_TYPES.PRINT]: <PrintMeasurmentFields />,
  [ARTWORK_TYPES.BANNER]: <MainMeasurmentFields />,
  [ARTWORK_TYPES.DRAWING]: <MainMeasurmentFields />,
  [ARTWORK_TYPES.DESIGN]: <MainMeasurmentFields />,
  [ARTWORK_TYPES.BOOK]: <MainMeasurmentFields />,
}

export const MeasurmentFields = ({ type }) => {
  if (!type) {
    return <MainMeasurmentFields />
  }
  return (
    <div className="row">
    {MeasurmentsFieldsByType[type]}
    </div>
  )
}
