//
import { merge } from 'rxjs'
import * as http from '../services/http'
import { handleError } from '../utils/utils'
import { ofType } from 'redux-observable'
import { map, catchError, switchMap } from 'rxjs/operators'

import * as actionsTypes from './actionTypes'
import { getFromCache } from '../utils/cache'

export const loadComments = (artworkId) => ({
  type: actionsTypes.COMMENTS_LOAD,
  payload: artworkId,
})

export const commentsLoaded = (artworkId) => (items) => ({
  type: actionsTypes.COMMENTS_LOADED,
  payload: { artworkId, items },
})

export const loadCommentsFailed = (error) => ({
  type: actionsTypes.COMMENTS_LOAD_FAILED,
  payload: error,
})

const getLoadComments = (artworkId, state) =>
  merge(getFromCache(state.comments.items, artworkId), http.loadArtworkComments(artworkId))

export const loadCommentsEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionsTypes.COMMENTS_LOAD),
    switchMap((action) =>
      getLoadComments(action.payload, state$.value).pipe(
        map(commentsLoaded(action.payload)),
        catchError(handleError(loadCommentsFailed))
      )
    )
  )

export const createComment = (artworkId, userId, comment) => ({
  type: actionsTypes.COMMENT_CREATE,
  payload: { artwork_id: artworkId, user_id: userId, comment: comment },
})

export const commentCreated = (item) => ({
  type: actionsTypes.COMMENT_CREATED,
  payload: { artworkId: item.artwork_id, item },
})

export const createCommentFailed = (error) => ({
  type: actionsTypes.COMMENT_CREATE_FAILED,
  payload: error,
})

export const createCommentsEpic = (action$) =>
  action$.pipe(
    ofType(actionsTypes.COMMENT_CREATE),
    switchMap((action) =>
      http.createComment(action.payload).pipe(
        map(commentCreated), 
        catchError(handleError(createCommentFailed))
      )
    )
  )
