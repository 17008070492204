import React from 'react'
import { Field, reduxForm } from 'redux-form'
import TextButton from '../general/TextButton'
import { CustomTextField } from '../general/CustomTextField'
import { RESET_PASSWORD_FORM } from '../../services/forms'

const validate = (values) => {
  const errors = {}
  if (!values.password) {
    errors.password = 'Please enter a new password'
  } else if (values.password.length < 8) {
    errors.password = 'Password must be at least 8 characters long'
  }
  if (!values.confirm_password) {
    errors.confirm_password = 'Please confirm your new password'
  } else if (values.confirm_password !== values.password) {
    errors.confirm_password = 'Passwords do not match'
  }
  return errors
}

const ResetPasswordFormPresentation = ({ handleSubmit, loading }) => {
  return (
    <div className="login-form">
      <form onSubmit={handleSubmit} className="shadow">
        <div className="field-spacing">
          <Field
            id="new-password-input"
            name="password"
            type="password"
            component={CustomTextField}
            label="New Password"
          />
        </div>
        <div className="field-spacing">
          <Field
            id="confirm-password-input"
            name="confirm_password"
            type="password"
            component={CustomTextField}
            label="Confirm Password"
          />
        </div>
        <div className="centered login-button">
          <TextButton
            type="submit"
            label={loading ? 'Resetting Password...' : 'Reset Password'}
            fullWidth={true}
            color="primary"
          />
        </div>
      </form>
    </div>
  )
}

const ResetPasswordForm = reduxForm({
  form: RESET_PASSWORD_FORM,
  validate,
})(ResetPasswordFormPresentation)

export default ResetPasswordForm
