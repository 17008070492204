import React from 'react'
import { PERSON_FORM } from '../../../services/forms'
import { deletePerson, loadPerson, updatePerson } from '../../../actions/personsActions'
import PersonForm from '../form/PersonForm'
import { change, isDirty, submit } from 'redux-form'
import FormTop from '../../form/FormTop'
import { uploadOwnerPhoto } from '../../../services/http'
import { personModel } from '../../../models/personModel'
import { EditTop } from '../../general/EditTop'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import DataProviderFunctional from '../../loader/DataProviderFunctional'
import { PublishedStatus } from '../../general/PublishedStatus'

const PersonEditPresentation = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const lang = useSelector((state) => state.translations.language)
  const initialValues = useSelector((state) => (state.persons.items[id] ? state.persons.items[id] : personModel))
  const dirty = useSelector((state) => isDirty(PERSON_FORM)(state))
  const saveState = useSelector((state) => state.persons.saveState)
  const onSubmit = (data) => dispatch(updatePerson({ ...data, id: id }))
  const remoteSubmit = () => dispatch(submit(PERSON_FORM))
  const remove = () => {
    if (window.confirm('Are you sure?')) {
      dispatch(deletePerson(id))
    }
  }
  const changeFieldValue = (field, value) => dispatch(change(PERSON_FORM, field, value))
  const changeLanguage = (language) => () => dispatch(changeLanguage(language))
  const upload = uploadOwnerPhoto

  return (
    <div>
      <div className="Exhibition-controls">
        <EditTop title="Edit Person" remove={remove} />
        <div className="controls">
          <FormTop
            title={`${initialValues.lastname}, ${initialValues.firstname}`}
            button="Save Person"
            remote={remoteSubmit}
            remove={remove}
            saveState={saveState}
          />
          <PublishedStatus changeFieldValue={changeFieldValue} formName={PERSON_FORM} />
        </div>
      </div>

      <div className="Exhibition-grid">
        {/* <NavigationPrompt when={dirty} /> */}

        <PersonForm
          lang={lang}
          onSubmit={onSubmit}
          changeFieldValue={changeFieldValue}
          initialValues={initialValues}
          upload={upload}
        />
      </div>

      <div />
    </div>
  )
}

const PersonEdit = () => {
  let { id } = useParams()
  const error = useSelector((state) => state.persons.error)
  const loading = useSelector((state) => state.persons.state)
  const dispatch = useDispatch()
  const load = () => {
    dispatch(loadPerson(id))
  }
  return <DataProviderFunctional error={error} load={load} loading={loading} component={PersonEditPresentation} />
}

export default PersonEdit
