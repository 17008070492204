import * as React from 'react'
import { DATE_TYPES_PERSON } from '../../../config/config'
import { AccurateDate } from '../../form/AccurateDate'

const PersonDates = () => (
  <div className="flex-box">
    <AccurateDate
      accuracyTitle="Birth accuracy"
      accuracyField="accuracy_birth"
      dateField="birth"
      accuracies={DATE_TYPES_PERSON}
    />
    <AccurateDate
      accuracyTitle="Death accuracy"
      accuracyField="accuracy_death"
      dateField="death"
      accuracies={DATE_TYPES_PERSON}
    />
  </div>
)

export default PersonDates
