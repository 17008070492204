import React from 'react'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel/TableSortLabel'
import TableCell from '@material-ui/core/TableCell/TableCell'
import { withStyles } from '@material-ui/core'

export const CustomTableCell = withStyles(theme => ({
  head: {
    color: 'rgb(158, 158, 158)'
  },
  body: {
    color: theme.palette.common.white
  }
}))(TableCell)

export const makeSortableTableHeader = headers => ({ orderBy, order, orderFn }) => (
  <TableHead>
    <TableRow>
      {headers.map((header, i) => (
        <CustomTableCell key={i}>
          <TableSortLabel key={i} active={orderBy === header.field} direction={order} onClick={orderFn(header.field)}>
            {header.title}
          </TableSortLabel>
        </CustomTableCell>
      ))}
    </TableRow>
  </TableHead>
)
