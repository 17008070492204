import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Field, FieldArray } from 'redux-form'
import { TITLE_STATUS } from '../../config/config'
import { IconButton, Tooltip, MenuItem } from '@material-ui/core'
import { ReferenceField } from './ReferenceField'
import { emptyTitle, title } from '../../models/titleModel'
import { Close } from '@material-ui/icons'
import { ARTWORK_FORM } from '../../services/forms'
import { DEFAULT } from '../../utils/constants'
import OutlinedButton from '../general/OutlinedButton'
import { COLOR_LIGHT_TEXT } from '../../config/colors'
import { CustomSelect } from '../general/CustomSelect'
import { CustomTextField } from '../general/CustomTextField'

const TitleComponent = ({ index, fields, member, lang }) => {
  const database = useSelector((state) => state.auth.database)
  const titleStatus = TITLE_STATUS[database] ?? TITLE_STATUS[DEFAULT]

  return (
    <div key={index} className="artwork-row title-row">
      <div className="flex-box">
        <div className="repeater-field">
          {index === 0 ? <div className="field-label">Titles (Norwegian)</div> : null}
          <Field
            id={`${member}.translations.nb.title-${index}-input`}
            name={`${member}.translations.nb.title`}
            label="Title"
            hideLabel={true}
            component={CustomTextField}
            type="text"
          />
        </div>
        <div className="repeater-field">
          {index === 0 ? <div className="field-label">Titles (English)</div> : null}
          <Field
            id={`${member}.translations.en.title-${index}-input`}
            name={`${member}.translations.en.title`}
            label="Title"
            hideLabel={true}
            component={CustomTextField}
            type="text"
          />
        </div>
        <div className="repeater-field">
          {index === 0 ? <div className="field-label">Status</div> : null}
          <div className="flex-box-centered">
            <Field name={`${member}.status`} component={CustomSelect}>
              {titleStatus &&
                titleStatus.map((item, i) => (
                  <MenuItem key={i} value={item.value}>
                    {item.title}
                  </MenuItem>
                ))}
            </Field>
            <Tooltip title="Remove">
              <IconButton className="repeater-remove" onClick={() => fields.remove(index)}>
                <Close style={{ color: COLOR_LIGHT_TEXT }} />
              </IconButton>
            </Tooltip>
            <ReferenceField name={`references.titles_${index}`} formType={ARTWORK_FORM} />
          </div>
        </div>
      </div>
    </div>
  )
}

const ArtworkTitlesField = ({ fields, lang }) => {
  // eslint-disable-next-line
  //fields.length === 0 ? fields.push(title) : null
  const [addInitialTitle, setAddInitialTitle] = useState(true)

  useEffect(() => {
    if (addInitialTitle && fields.length === 0) {
      fields.push(title)
      setAddInitialTitle(false)
    }
  }, [addInitialTitle, fields])

  return (
    <div>
      {fields.map((member, index, fields) => (
        <TitleComponent key={index} member={member} index={index} fields={fields} lang={lang} />
      ))}
      <OutlinedButton color="secondary" onClick={() => fields.push(emptyTitle)} label="Add new title" />
    </div>
  )
}

const ArtworkTitles = ({ lang }) => (
  <div>
    <FieldArray name="titles" component={ArtworkTitlesField} lang={lang} />
  </div>
)

export default ArtworkTitles
