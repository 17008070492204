import React from 'react'
import { Field } from 'redux-form'
import { CustomTextField } from '../general/CustomTextField'

export const PrintPlateMeasurmentFields = () => {
  return (
    <div className="flex-box">
      <div className="repeater-field">
        <div className="field-label">
          <span className="gap-maker">Measurements</span>
        </div>
        <div>
          <div className="reference-holder  label--basic">Motif</div>
        </div>
        <div>
          <div className="reference-holder  label--basic">Plate</div>
        </div>
      </div>
      <div className="repeater-field">
        <div className="field-label">Min Height</div>
        <div>
          <Field
            id="motif_min_height-input"
            name="motif_min_height"
            component={CustomTextField}
            type="text"
            label="Min Height"
            hideLabel={true}
          />
        </div>
        <div>
          <Field
            id="plate_min_height-input"
            name="plate_min_height"
            component={CustomTextField}
            type="text"
            label="Plate Min Height"
            hideLabel={true}
          />
        </div>
      </div>
      <div className="repeater-field">
        <div className="field-label">Max Height</div>
        <div>
          <Field
            id="motif_max_height-input"
            name="motif_max_height"
            component={CustomTextField}
            type="text"
            label="Max Height"
            hideLabel={true}
          />
        </div>
        <div>
          <Field
            id="plate_max_height-input"
            name="plate_max_height"
            component={CustomTextField}
            type="text"
            label="Plate Max Height"
            hideLabel={true}
          />
        </div>
      </div>
      <div className="repeater-field">
        <div className="field-label">Min Width</div>
        <div>
          <Field
            id="motif_min_width-input"
            name="motif_min_width"
            component={CustomTextField}
            type="text"
            label="Min Width"
            hideLabel={true}
          />
        </div>
        <div>
          <Field
            id="plate_min_width-input"
            name="plate_min_width"
            component={CustomTextField}
            type="text"
            label="Plate Min Height"
            hideLabel={true}
          />
        </div>
      </div>
      <div className="repeater-field">
        <div className="field-label">Max Width</div>
        <div>
          <Field
            id="motif_max_width-input"
            name="motif_max_width"
            component={CustomTextField}
            type="text"
            label="Max Width"
            hideLabel={true}
          />
        </div>
        <div>
          <Field
            id="plate_max_width-input"
            name="plate_max_width"
            component={CustomTextField}
            type="text"
            label="Plate Max Width"
            hideLabel={true}
          />
        </div>
      </div>
      <div className="repeater-field">
        <div className="field-label">Depth</div>
        <div>
          <Field id="depth-input" name="depth" component={CustomTextField} type="text" label="Depth" hideLabel={true} />
        </div>
        <div>
          <Field
            id="plate_depth-input"
            name="plate_depth"
            component={CustomTextField}
            type="text"
            label="Plate depth"
            hideLabel={true}
          />
        </div>
      </div>
    </div>
  )
}
