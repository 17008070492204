import * as React from 'react'
import { Field } from 'redux-form'
import { Tooltip, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { Uploader } from '../../form/Uploader'
import { CustomRefreshIndicator } from '../../general/CustomRefreshIndicator'

const Img = ({ input, upload }) => {
  return input.value ? (
    <div className="img-holder">
      <img src={input.value.url} alt="" />
      <div className="hover-background">
        <Tooltip title="Remove">
          <IconButton onClick={() => input.onChange(null)}>
            <Close />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  ) : (
    <div>
      <Uploader
        upload={upload}
        onStart={() => console.log('on upload start')}
        onSuccess={(image) => input.onChange(image)}
        onError={(error) => console.log(error)}
        loader={CustomRefreshIndicator}
      />
    </div>
  )
}

const Image = ({ upload, input }) => (
  <div className="flex-box">
    <div className="single-img">
      <div className="image-label label--basic">Photo</div>
      <Img input={input} upload={upload} />
    </div>
  </div>
)

const PersonPhoto = ({ upload }) => <Field name="photo" component={Image} upload={upload} />

export default PersonPhoto
