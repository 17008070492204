import React from 'react'
import { Field } from 'redux-form'
import { CustomTextField } from '../general/CustomTextField'

export const PaintingMeasurmentFields = () => {
  return (
    <div className="flex-box">
      <div className="repeater-field">
        <div className="field-label">
          <span className="gap-maker">Measurements</span>
        </div>
        <div>
          <div className="reference-holder  label--basic">Main</div>
        </div>
        <div>
          <div className="reference-holder  label--basic">Stretcher</div>
        </div>
        <div>
          <div className="reference-holder  label--basic">Frame</div>
        </div>
      </div>
      <div className="repeater-field">
        <div className="field-label">Height</div>
        <div>
          <Field
            id="height-input"
            name="height"
            component={CustomTextField}
            type="text"
            label="Height"
            hideLabel={true}
          />
        </div>
        <div>
          <Field
            id="stretcher_height-input"
            name="stretcher_height"
            component={CustomTextField}
            type="text"
            label="Stretcher height"
            hideLabel={true}
          />
        </div>
        <div>
          <Field
            id="frame_height-input"
            name="frame_height"
            component={CustomTextField}
            type="text"
            label="Frame height"
            hideLabel={true}
          />
        </div>
      </div>
      <div className="repeater-field">
        <div className="field-label">Width</div>
        <div>
          <Field id="width-input" name="width" component={CustomTextField} type="text" label="Width" hideLabel={true} />
        </div>
        <div>
          <Field
            id="stretcher_width-input"
            name="stretcher_width"
            component={CustomTextField}
            type="text"
            label="Stretcher width"
            hideLabel={true}
          />
        </div>
        <div>
          <Field
            id="frame_width-input"
            name="frame_width"
            component={CustomTextField}
            type="text"
            label="Frame width"
            hideLabel={true}
          />
        </div>
      </div>
      <div className="repeater-field">
        <div className="field-label">Depth</div>
        <div className="gap-maker">
          <Field name="motif_hidden" component={CustomTextField} type="hidden" />
        </div>
        <div className="gap-maker">
          <Field name="motif_hidden" component={CustomTextField} type="hidden" />
        </div>
        <div>
          <Field id="depth-input" name="depth" component={CustomTextField} type="text" label="Depth" hideLabel={true} />
        </div>
      </div>
    </div>
  )
}
