import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setFilter } from '../../../actions/filtersActions'
import { CustomTextField } from '../../general/CustomTextField'

const Filters = () => {
  const q = useSelector((state) => state.filters.q)
  const visible = useSelector((state) => state.filters.visible)
  const dispatch = useDispatch()

  const handleSetFilter = (type, value) => {
    dispatch(setFilter(type, value))
  }

  return (
    visible || (
      <div className="filters">
        <div className="row filter-section">
          <div className="full-field">
            <div className="field-label">Search</div>
            <CustomTextField name="q" value={q} onChange={(_, value) => handleSetFilter('q', value)} width="full" />
          </div>
        </div>
      </div>
    )
  )
}

export default Filters
