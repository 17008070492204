import { of, from } from 'rxjs'
import * as http from '../services/http'
import * as actionTypes from './actionTypes'
import { handleError } from '../utils/utils'
import * as router from '../services/router'
import { push, goBack } from 'connected-react-router'
import { loadingState } from '../utils/loadingState'
import { map, catchError, mergeMap, switchMap, debounceTime } from 'rxjs/operators'
import { ofType } from 'redux-observable'

export const loadDocuments = () => {
  return {
    type: actionTypes.DOCUMENTS_LOAD,
  }
}

export const documentsLoaded = (response) => ({
  type: actionTypes.DOCUMENTS_LOADED,
  payload: { page: response.current_page, items: response.data, total: response.last_page },
})

export const loadDocumentsAll = () => {
  return {
    type: actionTypes.DOCUMENTS_LOAD_ALL,
  }
}

export const documentsLoadedAll = (response) => {
  return {
    type: actionTypes.DOCUMENTS_LOADED_ALL,
    payload: response,
  }
}

export const loadDocumentsFailed = (error) => ({
  type: actionTypes.DOCUMENTS_LOAD_FAILED,
  payload: error,
})

const getLoadDocumentsAll = (state) => {
  let items = state.documents.items
  if (state.documents.stateAll === loadingState.LOADED) {
    return of(items).pipe(map((data) => Object.values(data)))
    //.concatMap(http.loadDocumentsAll())
  } else {
    return http.loadDocumentsAll()
  }
}

export const loadDocumentsEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.DOCUMENTS_LOAD_ALL),
    switchMap((action) =>
      getLoadDocumentsAll(state$.value).pipe(map(documentsLoadedAll), catchError(handleError(loadDocumentsFailed)))
    )
  )

//Single document
export const loadDocument = (stableId) => {
  return {
    type: actionTypes.DOCUMENT_LOAD,
    payload: stableId,
  }
}

export const documentLoaded = (data) => {
  return {
    type: actionTypes.DOCUMENT_LOADED,
    payload: data,
  }
}

export const loadDocumentFailed = (error) => {
  return {
    type: actionTypes.DOCUMENT_LOAD_FAILED,
    payload: error,
  }
}

const getLoadDocument = (id, state) => {
  //needs to load fresh to populate pages
  // let item = state.documents.items[id]
  // if (item) {
  //   return Observable.of(item).map((data) => data)
  // } else {
  return http.loadDocument(id)
  // }
}

export const loadDocumentEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.DOCUMENT_LOAD),
    switchMap((action) =>
      getLoadDocument(action.payload, state$.value).pipe(
        map(documentLoaded),
        catchError(handleError(loadDocumentFailed))
      )
    )
  )

export const updateDocument = (data) => ({
  type: actionTypes.DOCUMENT_UPDATE,
  payload: data,
})

export const documentUpdated = (data) => ({
  type: actionTypes.DOCUMENT_UPDATED,
  payload: data,
})

export const updateDocumentFailed = (error) => ({
  type: actionTypes.DOCUMENT_UPDATE_FAILED,
  payload: error,
})

export const updateDocumentEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.DOCUMENT_UPDATE),
    switchMap((action) =>
      from(http.updateDocument(action.payload.id, action.payload)).pipe(
        map(documentUpdated),
        catchError(handleError(updateDocumentFailed))
      )
    )
  )

export const createDocument = (data) => ({
  type: actionTypes.DOCUMENT_CREATE,
  payload: data,
})

export const documentCreated = (data) => ({
  type: actionTypes.DOCUMENT_CREATED,
  payload: data,
})

export const createDocumentFailed = (error) => ({
  type: actionTypes.DOCUMENT_CREATE_FAILED,
  payload: error,
})

export const createDocumentEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.DOCUMENT_CREATE),
    switchMap((action) =>
      from(http.createDocument(action.payload)).pipe(
        map(documentCreated),
        mergeMap((action) => of(action, push(router.ROUTE_DOCUMENT_EDIT + action.payload.id))),
        catchError(handleError(createDocumentFailed))
      )
    )
  )

export const deleteDocument = (data) => {
  return {
    type: actionTypes.DOCUMENT_DELETE,
    payload: data,
  }
}

export const documentDeleted = (data) => {
  return {
    type: actionTypes.DOCUMENT_DELETED,
    payload: data,
  }
}

export const deleteDocumentFailed = (error) => ({
  type: actionTypes.DOCUMENT_DELETE_FAILED,
  payload: error,
})

export const deleteDocumentEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.DOCUMENT_DELETE),
    switchMap((action) =>
      http.deleteDocument(action.payload).pipe(
        map(documentDeleted),
        mergeMap((action) => of(action, goBack())),
        catchError(handleError(deleteDocumentFailed))
      )
    )
  )

export const changeSortOrder = (sortOrder) => ({
  type: actionTypes.DOCUMENTS_CHANGE_SORT_ORDER,
  payload: sortOrder,
})

export const documentsOrder = (orderBy, order) => ({
  type: actionTypes.DOCUMENTS_ORDER,
  payload: { orderBy, order },
})

export const deleteDocumentPage = (id) => {
  return {
    type: actionTypes.DOCUMENT_PAGE_DELETE,
    payload: id,
  }
}

export const documentPageDeleted = (data) => {
  return {
    type: actionTypes.DOCUMENT_PAGE_DELETED,
    payload: data,
  }
}

export const deleteDocumentPageFailed = (error) => ({
  type: actionTypes.DOCUMENT_PAGE_DELETE_FAILED,
  payload: error,
})

export const deleteDocumentPageEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.DOCUMENT_PAGE_DELETE),
    switchMap((action) =>
      http.deleteDocumentPage(action.payload).pipe(
        map(documentPageDeleted),
        //.mergeMap((action) => of(action, goBack()))
        catchError(handleError(deleteDocumentPageFailed))
      )
    )
  )

//actions and epic for Archive (documents) references feature:
export const searchDocuments = (query) => ({
  type: actionTypes.DOCUMENTS_SEARCH,
  payload: query,
})
export const documentsError = (fieldName) => ({
  type: actionTypes.DOCUMENTS_ERROR,
  payload: fieldName,
})
export const documentsErrorRemove = (fieldName) => ({
  type: actionTypes.DOCUMENTS_REMOVE_ERROR,
  payload: fieldName,
})
export const documentsSearched = (response) => ({
  type: actionTypes.DOCUMENTS_SEARCHED,
  payload: { page: response.current_page, items: response.data, total: response.last_page },
})

export const searchDocumentsFailed = (error) => ({
  type: actionTypes.DOCUMENTS_SEARCH_FAILED,
  payload: error,
})

export const searchDocumentsEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.DOCUMENTS_SEARCH),
    debounceTime(500),
    switchMap((action) =>
      http.searchDocuments(action.payload).pipe(map(documentsSearched), catchError(handleError(searchDocumentsFailed)))
    )
  )
