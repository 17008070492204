import * as React from 'react'
import { Field, reduxForm } from 'redux-form'
import { PROFILE_FORM } from '../../services/forms'
import Avatar from '../general/Avatar'
import TextButton from '../general/TextButton'
import { CustomTextField } from '../general/CustomTextField'

const ProfileFormPresentation = ({ upload, handleSubmit, removePhoto }) => (
  <form onSubmit={handleSubmit}>
    <Field name="avatar" component={Avatar} upload={upload} removePhoto={removePhoto} />
    <div>
      <div className="field-label">Name</div>
      <Field id="name-input" name="name" component={CustomTextField} type="text" label="Name"/>
    </div>
    <div>
      <div className="field-label">Email</div>
      <Field id="email-input" name="email" component={CustomTextField}  type="email" label="Email"/>
    </div>
    <div>
      <div className="field-label">Password</div>
      <Field id="password-input" name="password" component={CustomTextField} type="password" label="Password"/>
    </div>
    <div className="save-button">
      <TextButton label="Save" type="submit" color="primary" />

    </div>
  </form>
)

const formProps = {
  form: PROFILE_FORM
}

const ProfileForm = reduxForm(formProps)(ProfileFormPresentation)

export default ProfileForm
