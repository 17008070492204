import { of, from } from 'rxjs'
import * as http from '../services/http'
import * as actionTypes from './actionTypes'
import { handleError } from '../utils/utils'
import * as router from '../services/router'
import { push, goBack } from 'connected-react-router'
import { ofType } from 'redux-observable'
import { map, catchError, mergeMap, switchMap, concatMap } from 'rxjs/operators'

export const loadInstitutions = () => ({
  type: actionTypes.INSTITUTIONS_LOAD,
})

export const institutionsLoaded = (response) => ({
  type: actionTypes.INSTITUTIONS_LOADED,
  payload: { page: response.current_page, items: response.data, total: response.last_page },
})

export const institutionsLoadedAll = (response) => {
  return {
    type: actionTypes.INSTITUTIONS_LOADED_ALL,
    payload: { items: response },
  }
}

export const loadInstitutionsFailed = (error) => ({
  type: actionTypes.INSTITUTIONS_LOAD_FAILED,
  payload: error,
})

const getLoadInstitutionsAll = (state) => {
  let items = state.institutions.items
  if (state.institutions.stateAllLoaded) {
    return of(items).pipe(
      map((data) => Object.values(data)),
      concatMap(() => http.loadInstitutionsAll())
    )
  } else {
    return http.loadInstitutionsAll()
  }
}

export const loadInstitutionsEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.INSTITUTIONS_LOAD),
    switchMap((action) =>
      getLoadInstitutionsAll(state$.value).pipe(
        map(institutionsLoadedAll),
        catchError(handleError(loadInstitutionsFailed))
      )
    )
  )

export const loadInstitution = (stableId) => ({
  type: actionTypes.INSTITUTION_LOAD,
  payload: stableId,
})

export const institutionLoaded = (data) => ({
  type: actionTypes.INSTITUTION_LOADED,
  payload: data,
})

export const loadInstitutionFailed = (error) => ({
  type: actionTypes.INSTITUTION_LOAD_FAILED,
  payload: error,
})

const getLoadInstitution = (stableId, state) => {
  let item = state.institutions.items[stableId]
  if (item) {
    return of(item).pipe(map((data) => data))
  } else {
    return http.loadInstitution(stableId)
  }
}

export const loadInstitutionEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.INSTITUTION_LOAD),
    switchMap((action) =>
      getLoadInstitution(action.payload, state$.value).pipe(
        map(institutionLoaded),
        catchError(handleError(loadInstitutionFailed))
      )
    )
  )

export const updateInstitution = (data) => ({
  type: actionTypes.INSTITUTION_UPDATE,
  payload: data,
})

export const institutionUpdated = (data) => ({
  type: actionTypes.INSTITUTION_UPDATED,
  payload: data,
})

export const updateInstitutionFailed = (error) => ({
  type: actionTypes.INSTITUTION_UPDATE_FAILED,
  payload: error,
})

export const updateInstitutionEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.INSTITUTION_UPDATE),
    switchMap((action) =>
      from(http.updateInstitution(action.payload.id, action.payload)).pipe(
        map(institutionUpdated),
        catchError(handleError(updateInstitutionFailed))
      )
    )
  )

export const createInstitution = (data) => ({
  type: actionTypes.INSTITUTION_CREATE,
  payload: data,
})

export const institutionCreated = (data) => ({
  type: actionTypes.INSTITUTION_CREATED,
  payload: data,
})

export const createInstitutionFailed = (error) => ({
  type: actionTypes.INSTITUTION_CREATE_FAILED,
  payload: error,
})

export const createInstitutionEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.INSTITUTION_CREATE),
    switchMap((action) =>
      from(http.createInstitution(action.payload)).pipe(
        map(institutionCreated),
        mergeMap((action) => of(action, push(router.MATCH_INSTITUTIONS))),
        catchError(handleError(createInstitutionFailed))
      )
    )
  )

export const deleteInstitution = (data) => {
  return {
    type: actionTypes.INSTITUTION_DELETE,
    payload: data,
  }
}

export const institutionDeleted = (data) => {
  return {
    type: actionTypes.INSTITUTION_DELETED,
    payload: data,
  }
}

export const deleteInstitutionFailed = (error) => ({
  type: actionTypes.INSTITUTION_DELETE_FAILED,
  payload: error,
})

export const deleteInstitutionEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.INSTITUTION_DELETE),
    switchMap((action) =>
      http.deleteInstitution(action.payload).pipe(
        map(institutionDeleted),
        mergeMap((action) => of(action, goBack())),
        catchError(handleError(deleteInstitutionFailed))
      )
    )
  )

export const institutionsOrder = (orderBy, order) => ({
  type: actionTypes.INSTITUTIONS_ORDER,
  payload: { orderBy, order },
})
