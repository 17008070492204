import React from 'react'
import { FormControlLabel, Radio, makeStyles } from '@material-ui/core'
import { COLOR_LIGHT_TEXT } from '../../config/colors'

const useStyles = makeStyles((theme) => ({
  radio: {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
    padding: 2,
    marginRight: 16,
  },
  label: {
    color: COLOR_LIGHT_TEXT,
    margin: "16px 16px 16px 0",
    flex: "1 1",
    width: "auto"
  },
}))

export const CustomRadio = ({ value, label }) => {
  const classes = useStyles()
  return (
    <FormControlLabel
      className={classes.label}
      label={label}
      control={<Radio className={classes.radio} value={value} />}
    />
  )
}
