import React from 'react'
import './Artwork.css'
import { getArtworkTitle, relativeTime } from '../../../utils/utils'
import { COLOR_DARK_TEXT } from '../../../config/colors'
import { useSelector } from 'react-redux'
import { COMMENTS_IN_LIST } from '../../../config/config'
import { CheckCircle } from '@material-ui/icons'

const Comments = ({ comments, users }) => {
  return (
    <div className="comments">
      {comments.length ? (
        <div>
          {comments.filter((c, i) => i < COMMENTS_IN_LIST).map((comment, i) => (
            <div key={i} className="comment">
              <div className="comment-author">
                {users && users[comment.user_id] ? users[comment.user_id].name : '...'}
              </div>
              <div className="comment-text">{comment.comment}</div>
            </div>
          ))}
        </div>
      ) : (
        <div className="empty-comments">No comments</div>
      )}
    </div>
  )
}

const GridArtwork = ({ artwork, onClick, users }) => {
  const colorAccent = useSelector(state => state.auth.colorAccent)

  return (
    <div className="grid-artwork shadow" onClick={onClick}>
      <div className="left-right-block title-block">
        <div className="">
          <div className="title">{getArtworkTitle(artwork)}</div>
          <div className="subtitle">{artwork.verk_id}</div>
        </div>
        <div className="time-block">
          <div>{relativeTime(artwork.updated_at)}</div>
        </div>
      </div>
      <div className="square-holder">
        {artwork.images[0] ? <img src={artwork.images[0].thumb_url} className="square" alt="" /> : null}
      </div>
      <div className="left-right-block state-block">
        <div className="flex-box-centered">
          Published <CheckCircle style={{ color: artwork.published ? colorAccent : COLOR_DARK_TEXT}} />
        </div>
        <div className="flex-box-centered">
          Approved <CheckCircle style={{ color: artwork.reviewed ? colorAccent : COLOR_DARK_TEXT}} />
        </div>
      </div>

      <Comments comments={artwork.comments} users={users} />
    </div>
  )
}

export default GridArtwork
