import { of, from} from 'rxjs'
import * as http from '../services/http'
import * as actionTypes from './actionTypes'
import { handleError } from '../utils/utils'
import * as router from '../services/router'
import { push, goBack } from 'connected-react-router'
import { map, catchError, mergeMap, switchMap, concatMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'

export const loadPersons = () => ({
  type: actionTypes.PERSONS_LOAD,
})

export const personsLoaded = (response) => ({
  type: actionTypes.PERSONS_LOADED,
  payload: { page: response.current_page, items: response.data, total: response.last_page },
})

export const personsLoadedAll = (response) => {
  return {
    type: actionTypes.PERSONS_LOADED_ALL,
    payload: { items: response },
  }
}

export const loadPersonsFailed = (error) => ({
  type: actionTypes.PERSONS_LOAD_FAILED,
  payload: error,
})

const getLoadPersonsAll = (state) => {
  let items = state.persons.items
  if (state.persons.stateAllLoaded) {
    return of(items).pipe(
      map((data) => Object.values(data)),
      concatMap(() => http.loadPersonsAll())
    )
  } else {
    return http.loadPersonsAll()
  }
}

export const loadPersonsEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.PERSONS_LOAD),
    switchMap((action) =>
      getLoadPersonsAll(state$.value).pipe(map(personsLoadedAll), catchError(handleError(loadPersonsFailed)))
    )
  )

export const loadPerson = (stableId) => ({
  type: actionTypes.PERSON_LOAD,
  payload: stableId,
})

export const personLoaded = (data) => ({
  type: actionTypes.PERSON_LOADED,
  payload: data,
})

export const loadPersonFailed = (error) => ({
  type: actionTypes.PERSON_LOAD_FAILED,
  payload: error,
})

const getLoadPerson = (stableId, state) => {
  //let item = state.persons.items[stableId]
  //if (item) {
  //  return Observable.of(item).map(data => data)
  //} else {
  return http.loadPerson(stableId)
  //}
}

export const loadPersonEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.PERSON_LOAD),
    switchMap((action) =>
      getLoadPerson(action.payload, state$.value).pipe(map(personLoaded), catchError(handleError(loadPersonFailed)))
    )
  )

export const updatePerson = (data) => ({
  type: actionTypes.PERSON_UPDATE,
  payload: data,
})

export const personUpdated = (data) => ({
  type: actionTypes.PERSON_UPDATED,
  payload: data,
})

export const updatePersonFailed = (error) => ({
  type: actionTypes.PERSON_UPDATE_FAILED,
  payload: error,
})

export const updatePersonEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.PERSON_UPDATE),
    switchMap((action) =>
      from(http.updatePerson(action.payload.id, action.payload)).pipe(
        map(personUpdated),
        catchError(handleError(updatePersonFailed))
      )
    )
  )

export const createPerson = (data) => ({
  type: actionTypes.PERSON_CREATE,
  payload: data,
})

export const personCreated = (data) => ({
  type: actionTypes.PERSON_CREATED,
  payload: data,
})

export const createPersonFailed = (error) => ({
  type: actionTypes.PERSON_CREATE_FAILED,
  payload: error,
})

export const createPersonEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.PERSON_CREATE),
    switchMap((action) =>
      from(http.createPerson(action.payload)).pipe(
        map(personCreated),
        mergeMap((action) => of(action, push(router.ROUTE_PERSON_EDIT + action.payload.id))),
        catchError(handleError(createPersonFailed))
      )
    )
  )

export const deletePerson = (data) => {
  return {
    type: actionTypes.PERSON_DELETE,
    payload: data,
  }
}

export const personDeleted = (data) => {
  return {
    type: actionTypes.PERSON_DELETED,
    payload: data,
  }
}

export const deletePersonFailed = (error) => ({
  type: actionTypes.PERSON_DELETE_FAILED,
  payload: error,
})

export const deletePersonEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.PERSON_DELETE),
    switchMap((action) =>
      http.deletePerson(action.payload).pipe(
        map(personDeleted),
        mergeMap((action) => of(action, goBack())),
        catchError(handleError(deletePersonFailed))
      )
    )
  )

export const changeSortOrder = (sortOrder) => ({
  type: actionTypes.PERSONS_CHANGE_SORT_ORDER,
  payload: sortOrder,
})

export const personsOrder = (orderBy, order) => ({
  type: actionTypes.PERSONS_ORDER,
  payload: { orderBy, order },
})
