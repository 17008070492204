import { merge, of, from } from 'rxjs'
import { map, catchError, mergeMap, switchMap, debounceTime } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import * as http from '../services/http'
import * as actionTypes from './actionTypes'
import { handleError } from '../utils/utils'
import { getFromCache } from '../utils/cache'
import { initialize } from 'redux-form'
import * as router from '../services/router'
import { REFERENCE_FORM } from '../services/forms'
import { push, goBack } from 'connected-react-router'

export const loadReferences = (artworkId) => ({
  type: actionTypes.REFERENCES_LOAD,
  payload: artworkId,
})

export const referencesLoaded = (response) => ({
  type: actionTypes.REFERENCES_LOADED,
  payload: { page: response.current_page, items: response.data, total: response.last_page },
})

export const loadReferencesFailed = (error) => ({
  type: actionTypes.REFERENCES_LOAD_FAILED,
  payload: error,
})

export const searchReferences = (query) => ({
  type: actionTypes.REFERENCES_SEARCH,
  payload: query,
})
export const referenceError = (fieldName) => ({
  type: actionTypes.REFERENCES_ERROR,
  payload: fieldName,
})
export const referenceErrorRemove = (fieldName) => ({
  type: actionTypes.REFERENCES_REMOVE_ERROR,
  payload: fieldName,
})
export const referencesSearched = (response) => ({
  type: actionTypes.REFERENCES_SEARCHED,
  payload: { page: response.current_page, items: response.data, total: response.last_page },
})

export const searchReferencesFailed = (error) => ({
  type: actionTypes.REFERENCES_SEARCH_FAILED,
  payload: error,
})

const getLoadReferences = (page, state) =>
  merge(
    getFromCache(state.references.pages, page).pipe(
      map((items) => ({
        current_page: page,
        data: items,
        total: state.references.total,
      }))
    ),
    http.loadReferences(page)
  )

export const loadReferencesEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.REFERENCES_LOAD),
    switchMap((action) =>
      getLoadReferences(action.payload, state$.value).pipe(
        map(referencesLoaded),
        catchError(handleError(loadReferencesFailed))
      )
    )
  )

export const loadReference = (stableId) => ({
  type: actionTypes.REFERENCE_LOAD,
  payload: stableId,
})

export const referenceLoaded = (data) => ({
  type: actionTypes.REFERENCE_LOADED,
  payload: data,
})

export const loadReferenceFailed = (error) => ({
  type: actionTypes.REFERENCE_LOAD_FAILED,
  payload: error,
})

const getLoadReference = (stableId, state) =>
  merge(getFromCache(state.references.items, stableId), from(http.loadReference(stableId)))

export const loadReferenceEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.REFERENCE_LOAD),
    switchMap((action) =>
      getLoadReference(action.payload, state$.value).pipe(
        map(referenceLoaded),
        catchError(handleError(loadReferenceFailed))
      )
    )
  )

export const updateReference = (data) => ({
  type: actionTypes.REFERENCE_UPDATE,
  payload: data,
})

export const referenceUpdated = (data) => ({
  type: actionTypes.REFERENCE_UPDATED,
  payload: data,
})

export const updateReferenceFailed = (error) => ({
  type: actionTypes.REFERENCE_UPDATE_FAILED,
  payload: error,
})

export const updateReferenceEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.REFERENCE_UPDATE),
    switchMap((action) =>
      from(http.updateReference(action.payload.id, action.payload)).pipe(
        map(referenceUpdated),
        mergeMap((action) => of(action, initialize(REFERENCE_FORM, action.payload))),
        catchError(handleError(updateReferenceFailed))
      )
    )
  )

export const createReference = (data) => ({
  type: actionTypes.REFERENCE_CREATE,
  payload: data,
})

export const referenceCreated = (data) => ({
  type: actionTypes.REFERENCE_CREATED,
  payload: data,
})

export const createReferenceFailed = (error) => ({
  type: actionTypes.REFERENCE_CREATE_FAILED,
  payload: error,
})

export const createReferenceEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.REFERENCE_CREATE),
    switchMap((action) =>
      from(http.createReference(action.payload)).pipe(
        map(referenceCreated),
        mergeMap((action) =>
          of(action, initialize(REFERENCE_FORM, action.payload), push(router.ROUTE_REFERENCE_EDIT + action.payload.id))
        ),
        catchError(handleError(createReferenceFailed))
      )
    )
  )

export const deleteReference = (data) => {
  return {
    type: actionTypes.REFERENCE_DELETE,
    payload: data,
  }
}

export const referenceDeleted = (data) => ({
  type: actionTypes.REFERENCE_DELETED,
  payload: data,
})

export const deleteReferenceFailed = (error) => ({
  type: actionTypes.REFERENCE_DELETE_FAILED,
  payload: error,
})

export const deleteReferenceEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.REFERENCE_DELETE),
    switchMap((action) =>
      http.deleteReferences(action.payload).pipe(
        map(referenceDeleted),
        mergeMap((action) => of(action, goBack())),
        catchError(handleError(deleteReferenceFailed))
      )
    )
  )

export const searchReferencesEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.REFERENCES_SEARCH),
    debounceTime(500),
    switchMap((action) =>
      http
        .searchReferences(action.payload)
        .pipe(map(referencesSearched), catchError(handleError(searchReferencesFailed)))
    )
  )

export const referencesOrder = (orderBy, order) => ({
  type: actionTypes.REFERENCES_ORDER,
  payload: { orderBy, order },
})
