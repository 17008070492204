import React from 'react'
import { Field } from 'redux-form'
import { CustomTextField } from '../general/CustomTextField'

export const PhotographyMeasurmentFields = () => {
  return (
    <div className="flex-box">
      <div className="repeater-field">
        <div className="field-label">
          <span className="gap-maker">Measurements</span>
        </div>
        <div>
          <div className="reference-holder  label--basic">Motif</div>
        </div>
        <div>
          <div className="reference-holder  label--basic">Support</div>
        </div>
      </div>
      <div className="repeater-field">
        <div className="field-label">Height</div>
        <div>
          <Field
            id="motif_height-input"
            name="motif_height"
            component={CustomTextField}
            type="text"
            label="Height"
            hideLabel={true}
          />
        </div>
        <div>
          <Field
            id="support_height-input"
            name="support_height"
            component={CustomTextField}
            type="text"
            label="Support height"
            hideLabel={true}
          />
        </div>
      </div>
      <div className="repeater-field">
        <div className="field-label">Width</div>
        <div>
          <Field
            id="motif_width-input"
            name="motif_width"
            component={CustomTextField}
            type="text"
            label="Width"
            hideLabel={true}
          />
        </div>
        <div>
          <Field
            id="support_width-input"
            name="support_width"
            component={CustomTextField}
            type="text"
            label="Support width"
            hideLabel={true}
          />
        </div>
      </div>
      <div className="repeater-field">
        <div className="field-label">Depth</div>
        <div className="gap-maker">
          <Field name="motif_hidden" component={CustomTextField} type="hidden" />
        </div>
        <div>
          <Field id="depth-input" name="depth" component={CustomTextField} type="text" label="Depth" hideLabel={true} />
        </div>
      </div>
    </div>
  )
}
