import React from 'react'
import './FormTop.css'
import { loadingState as LoadingState } from '../../utils/loadingState'
import TextButton from '../general/TextButton'

const FormTop = ({ title, button, remote, saveState, remove }) => (
  <div className="form-top left-right-block flex-box-centered shadow">
    <div className="left-title">{title}</div>
    <div className="flex-box-centered">
      {/* {remove && <TextButton labelStyle={{ color: COLOR_ERROR }} label="Remove" onClick={remove} />} */}
      {saveState === LoadingState.LOADING ? (
        <span>Saving...</span>
      ) : (
        <TextButton color="primary" onClick={remote} type="submit" label={button} />
      )}
    </div>
  </div>
)

export default FormTop
