import { concat, of } from 'rxjs'
import { map, catchError, switchMap, concatMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import * as http from '../services/http'
import * as actionTypes from './actionTypes'
import { handleError } from '../utils/utils'

export const loadProvenanceInstitutions = () => ({
  type: actionTypes.PROVENANCE_INSTITUTIONS_LOAD,
})

export const provenanceInstitutionsLoaded = (response) => ({
  type: actionTypes.PROVENANCE_INSTITUTIONS_LOADED,
  payload: { page: response.current_page, items: response.data, total: response.last_page },
})

export const provenanceInstitutionsLoadedAll = (response) => {
  return {
    type: actionTypes.PROVENANCE_INSTITUTIONS_LOADED_ALL,
    payload: { items: response },
  }
}

export const loadProvenanceInstitutionsFailed = (error) => ({
  type: actionTypes.PROVENANCE_INSTITUTIONS_LOAD_FAILED,
  payload: error,
})

const getLoadProvenanceInstitutionsAll = (state) => {
  let items = state.provenanceInstitutions.items
  if (state.provenanceInstitutions.stateAllLoaded) {
    return of(items).pipe(
      map((data) => Object.values(data)),
      concatMap(() => http.loadProvenanceInstitutionsAll())
    )
  } else {
    return http.loadProvenanceInstitutionsAll()
  }
}

export const loadProvenanceInstitutionsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(actionTypes.PROVENANCE_INSTITUTIONS_LOAD),
    switchMap((action) =>
      getLoadProvenanceInstitutionsAll(state$.value).pipe(
        map(provenanceInstitutionsLoadedAll),
        catchError(handleError(loadProvenanceInstitutionsFailed))
      )
    )
  )
}
