// noinspection JSUnresolvedVariable
const baseApiUrl = process.env.REACT_APP_BASE_API_URL
// noinspection JSUnresolvedVariable
const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY
if (baseApiUrl === undefined || baseApiUrl === null || googleMapsKey === undefined || googleMapsKey === null) {
  throw new Error('REACT_APP_BASE_API_URL or REACT_APP_GOOGLE_MAPS_KEY is undefined')
}

export const BASE_API_URL = baseApiUrl
export const GOOGLE_MAPS_KEY = googleMapsKey

export const ITEMS_PER_PAGE = 52
export const DEFAULT_LANGUAGE = 'nb'
export const SECOND_LANGUAGE = 'en'
export const COMMENTS_IN_LIST = 2

export const ARTWORK_TYPES = {
  PAINTING: 'painting',
  PRINT_PLATE: 'print_plate',
  PHOTOGRAPH: 'photograph',
  PRINT: 'print',
  BANNER: 'banner',
  DRAWING: 'drawing',
  DESIGN: 'design',
  BOOK: 'book',
}

export const MOTIFS = [
  { title: 'Illustration', value: 'illustrasjon' }, //old
  { title: 'Landscape', value: 'landskap' },
  { title: 'Interior', value: 'interior' },
  { title: 'Portrait', value: 'portrett' },
  { title: 'Still life', value: 'stilleben' },
  { title: 'Self Portrait', value: 'selvportrett' },
]
export const TECHNIQUES = {
  painting: [
    { title: 'Aquarelle', value: 'aquarelle' },
    { title: 'Underdrawing', value: 'drawing_technique' },
    { title: 'Gouache (paint) ', value: 'gouache' },
    { title: 'Oil', value: 'oil' },
    { title: 'Print', value: 'printing_technique' },
  ],
  banner: [
    { title: 'Oil', value: 'oil' },
    { title: 'Underdrawing', value: 'drawing_technique' },
  ],
  print: [
    { title: 'Underdrawing', value: 'drawing_technique', indent: '0' },
    { title: 'Hand coloring ', value: 'hand_colouring', indent: '0' },
    { title: 'Ink wash', value: 'ink_wash', indent: '1' },
    { title: 'Wash', value: 'wash', indent: '1' },
    { title: 'Intaglio print', value: 'intaglio', indent: '0' },
    { title: 'Engraving', value: 'engraving', indent: '1' },
    { title: 'Etching', value: 'etching', indent: '1' },
    { title: 'Relief print', value: 'relief_print', indent: '0' },
    { title: 'Chiaroscuro woodcut', value: 'chiaroscuro_woodcut', indent: '1' },
    { title: 'Colour woodcut', value: 'colour_woodcut', indent: '1' },
    { title: 'Woodcut', value: 'woodcut', indent: '1' },
    { title: 'Linocut', value: 'linocut', indent: '1' },
    { title: 'Transfer print', value: 'transfer_print', indent: '0' },
  ],
  print_plate: [
    { title: 'Carving tool (u-gouge)', value: 'carving_tool_u' },
    { title: 'Carving tool (v-gouge)', value: 'carving_tool_v' },
    { title: 'Chisel', value: 'chisel' },
    { title: 'Knife', value: 'knife' },
    // { title: 'Underdrawing', value: 'drawing_technique' },
    // { title: 'Hand colouring', value: 'hand_colouring' },
    // { title: 'Intaglio', value: 'intaglio' },
    // { title: 'Engraving', value: 'engraving' },
    // { title: 'Etching', value: 'etching' },
    // { title: 'Relief print', value: 'relief_print' },
    // { title: 'Chiaroscuro woodcut', value: 'chiaroscuro_woodcut' },
    // { title: 'Colour woodcut', value: 'colour_woodcut' },
    // { title: 'Linocut', value: 'linocut' },
  ],
  drawing: [
    { title: 'Aquarelle', value: 'aquarelle' },
    { title: 'Chalk stick', value: 'chalk' },
    { title: 'Charcoal stick', value: 'charcoal' },
    { title: 'Coloured pencil', value: 'coloured_pencil' },
    { title: 'Crayon', value: 'crayon' },
    { title: 'Grease pencil', value: 'grease_pencil' },
    { title: 'Gouache (paint) ', value: 'gouache' },
    { title: 'Ink', value: 'ink' },
    { title: 'Ink wash', value: 'ink_wash' },
    { title: 'Oil', value: 'oil' },
    { title: 'Oil pastel', value: 'oil_pastels' },
    { title: 'Pastel (crayon)', value: 'pastel_crayons' },
    { title: 'Pencil', value: 'pencil' },
    { title: 'Print', value: 'printing_technique' },
    //{ title: 'Reprographic copy', value: 'reprographic_copy' },
    { title: 'Underdrawing', value: 'drawing_technique' },
    { title: 'Wash', value: 'wash' },
  ],
  design: [
    { title: 'Aquarelle', value: 'aquarelle' },
    { title: 'Underdrawing', value: 'drawing_technique' },
    { title: 'Gouache (paint)', value: 'gouache' },
    { title: 'Oil', value: 'oil' },
    { title: 'Print', value: 'printing_technique' },
  ],
  photograph: [
    { title: 'Positive', value: 'positive', indent: '0' },
    { title: 'Contact print', value: 'contact_print', indent: '1' },
    { title: 'Enlargement', value: 'enlargement', indent: '1' },
    { title: 'Drawing', value: 'drawing', indent: '0' },
    { title: 'Negative', value: 'negative', indent: '0' },
  ],
  book: [
    { title: 'Aquarelle', value: 'aquarelle' },
    { title: 'Chalk stick', value: 'chalk' },
    { title: 'Charcoal stick', value: 'charcoal' },
    { title: 'Coloured pencil', value: 'coloured_pencil' },
    { title: 'Crayon', value: 'crayon' },
    { title: 'Grease pencil', value: 'grease_pencil' },
    { title: 'Gouache (paint) ', value: 'gouache' },
    { title: 'Ink', value: 'ink' },
    { title: 'Ink wash', value: 'ink_wash' },
    { title: 'Oil', value: 'oil' },
    { title: 'Oil pastel', value: 'oil_pastels' },
    { title: 'Pastel (crayon)', value: 'pastel_crayons' },
    { title: 'Pencil', value: 'pencil' },
    { title: 'Print', value: 'printing_technique' },
    //{ title: 'Reprographic copy', value: 'reprographic_copy' },
    { title: 'Underdrawing', value: 'drawing_technique' },
    { title: 'Wash', value: 'wash' },
  ],
}

export const MATERIALS = {
  painting: [
    { title: 'Burlap', value: 'burlap' },
    { title: 'Canvas', value: 'canvas' },
    { title: 'Cardboard', value: 'cardboard' },
    { title: 'Panel (wood)', value: 'panel_wood' },
    { title: 'Paper', value: 'paper' },
    { title: 'Textile material', value: 'textile_material' },
  ],
  banner: [
    { title: 'Canvas', value: 'canvas' },
    { title: 'Silk', value: 'silk' },
    { title: 'Textile material', value: 'textile_material' },
  ],
  print: [{ title: 'Paper', value: 'paper' }],
  print_plate: [
    { title: 'Linoleum block', value: 'linoleum' },
    { title: 'Wood block', value: 'woodblock' },
    { title: 'Metal plate (zink) ', value: 'zink' },
  ],
  drawing: [
    { title: 'Paper', value: 'paper' },
    { title: 'Cardboard', value: 'cardboard' },
  ],
  design: [{ title: 'Paper', value: 'paper' }],
  photograph: [
    { title: 'Positive', value: 'Positive', indent: '0' },
    //{ title: 'Paper (unidentified)', value: 'paper_unidentified', indent: '1' },
    { title: 'Developing-Out Paper (DOP)', value: 'developing_out_paper_DOP', indent: '1' },
    // { title: 'Paper (unidentified)', value: 'paper_unidentified', indent: '2' },
    // { title: 'Paper (manufactured)', value: 'paper_manufactured', indent: '2' },
    { title: 'Photographic paper', value: 'photographic_paper', indent: '2' },
    { title: 'Baryta paper', value: 'baryta_paper', indent: '2' },
    //{ title: 'Postcard (manufactured)', value: 'postcard_manufactured', indent: '2' },
    { title: 'Photographic postcards', value: 'photographic_postcards', indent: '2' },
    { title: 'Printing-Out Paper (POP)', value: 'printing_out_paper_POP', indent: '1' },
    { title: 'Albumen paper', value: 'albumen_paper', indent: '2' },
    { title: 'Salted paper', value: 'salted_paper', indent: '2' },
    //{ title: 'Emulsion paper', value: 'emulsion_paper', indent: '2' },
    { title: 'Silver gelatin paper', value: 'silver_gelatin_paper', indent: '2' },
    { title: 'Negative', value: 'negative', indent: '0' },
    { title: 'Glass plate negative', value: 'glass_plate', indent: '1' },
    { title: 'Roll film', value: 'roll_film', indent: '1' },
  ],
  book: [
    { title: 'Paper', value: 'paper' },
    { title: 'Cardboard', value: 'cardboard' },
  ],
}

export const TYPES = [
  { title: 'Painting', value: 'painting' },
  { title: 'Banner', value: 'banner' },
  { title: 'Print', value: 'print' },
  { title: 'Printing block', value: 'print_plate' },
  { title: 'Drawing', value: 'drawing' },
  { title: 'Design', value: 'design' },
  { title: 'Photograph', value: 'photograph' },
  { title: 'Book', value: 'book' },
]

export const AUTHENTICITY = [
  { title: 'Authentic', value: 'authentic' },
  { title: 'Uncertain', value: 'uncertain' },
  { title: 'Forgery', value: 'forgery' },
]

export const LOCATIONS = {
  mysql: [
    { title: 'Ålhus', value: 'alhus' },
    { title: 'Myklebust', value: 'myklebust' },
    { title: 'Sandalstrand', value: 'sandalstrand' },
    { title: 'Sunde', value: 'sunde' },
    { title: 'Undefined', value: '' },
    { title: 'No place', value: 'no place' },
    { title: 'Other', value: 'other' },
  ],
  default: [
    { title: 'Undefined', value: '' },
    { title: 'No place', value: 'no place' },
    { title: 'Other', value: 'other' },
  ],
}

export const FORMATS = [
  { title: 'cm', value: 'cm' },
  { title: 'mm', value: 'mm' },
]

export const DATE_TYPE_UNDATED = 1
export const DATE_TYPE_CIRCA = 2
export const DATE_TYPE_BEFORE = 3
export const DATE_TYPE_AFTER = 4
export const DATE_TYPE_UNCERTAIN = 6
export const DATE_TYPE_BETWEEN = 5 // Uncertain
export const DATE_TYPE_CERTAIN = 7
export const DATE_TYPE_YEAR = 8
export const DATE_TYPE_YEAR_MONTH = 9
export const DATE_TYPE_YEAR_MONTH_DAY = 10
export const DATE_TYPE_NO_DATE = 11
export const DATE_TYPE_UNKNOWN = 12

export const DATE_TYPES = [
  { title: '', value: 0, titleNb: '' },
  //{ title: 'Undated', value: 1, titleNb: 'Undated' }, // ont used anymore?
  //{ title: 'Cirka', value: 2, titleNb: 'Cirka' },
  //{ title: 'Before', value: 3, titleNb: 'Før' },
  //{ title: 'After', value: 4, titleNb: 'Etter' },
  //{ title: 'Between', value: 5, titleNb: 'Mellom' },
  { title: 'Uncertain', value: 5, titleNb: 'Usikker' },
  //{ title: 'Certain', value: 7, titleNb: 'Sikker' },
  { title: 'Year', value: 8, titleNb: 'År' },
  { title: 'Year date', value: 10, titleNb: 'Års dato' },
]

export const ARTWORK_DATE_TYPES = [
  { title: '', value: 0, titleNb: '' },
  { title: 'Cirka', value: 2, titleNb: 'Ca.' },
  { title: 'Before', value: 3, titleNb: 'Før' },
  { title: 'After', value: 4, titleNb: 'Etter' },
  { title: 'Uncertain', value: 5, titleNb: 'Usikker' },
  { title: 'Certain', value: 7, titleNb: 'Sikker' },
  { title: 'Year', value: 8, titleNb: 'År' },
  { title: 'Unknown', value: 12, titleNb: 'Ukjent' },
]

export const DATE_TYPES_PROVENANCE = [
  { title: '', value: 0, titleNb: '' },
  { title: 'Uncertain', value: 5, titleNb: 'Usikker' },
  { title: 'Year', value: 8, titleNb: 'År' },
  { title: 'No date', value: 11, titleNb: 'Ingen dato' },
]

export const DATE_TYPES_PROVENANCE_CURRENT = [
  { title: '', value: 0, titleNb: '' },
  { title: 'Uncertain', value: 5, titleNb: 'Usikker' },
  { title: 'Year', value: 8, titleNb: 'År' },
  { title: 'No date', value: 11, titleNb: 'Ingen dato' },
  { title: 'Current', value: 12, titleNb: 'Nåværende' },
]

export const DATE_TYPES_PERSON = [
  { title: '', value: 0, titleNb: '' },
  { title: 'Circa year', value: 2, titleNb: 'Cirka' },
  { title: 'Uncertain', value: 6, titleNb: 'Usikker' },
  { title: 'Year only', value: 8, titleNb: 'Kun år' },
  { title: 'Year and month', value: 9, titleNb: 'År og måned' },
  { title: 'Year, month and day', value: 10, titleNb: 'År og måned' },
]

export const DATE_ACCURACY_EXHIBITIONS = [
  { title: '', value: 0, titleNb: '' },
  { title: 'Year only', value: 8, titleNb: 'Kun år' },
  { title: 'Year and month', value: 9, titleNb: 'År og måned' },
  { title: 'Year, month and day', value: 10, titleNb: 'År og måned' },
]

export const TITLE_STATUS_ESTABLISHED = 'established'
export const TITLE_STATUS = {
  mysql: [
    { title: 'Established', value: TITLE_STATUS_ESTABLISHED, titleNb: 'Etablert' },
    { title: 'Previous', value: 'previous', titleNb: 'Tidlegare' },
    { title: 'Wrong', value: 'wrong', titleNb: 'Feilaktig' },
    { title: "Astrup's title", value: 'astrups_title', titleNb: 'Astrups tittel' },
  ],
  default: [
    { title: 'Established', value: TITLE_STATUS_ESTABLISHED, titleNb: 'Etablert' },
    { title: 'Previous', value: 'previous', titleNb: 'Tidlegare' },
    { title: 'Wrong', value: 'wrong', titleNb: 'Feilaktig' },
    { title: "Artists's title", value: 'artists_title', titleNb: 'Kunstnerens tittel' },
  ],
}

export const SIGNATURE_TYPES = [
  { title: 'Primary', value: 'primary', titleNb: 'Primær' },
  { title: 'Secondary', value: 'secondary', titleNb: 'Sekundær' },
]

export const SIGNATURE_LOCATIONS = [
  { title: 'Lower left', value: 'bottom_left', titleNb: 'Nede til høgre' },
  { title: 'Lower center', value: 'bottom_center', titleNb: 'Nede i senter' },
  { title: 'Lower right', value: 'bottom_right', titleNb: 'Nede til venstre' },
  { title: 'Upper left', value: 'upper_left', titleNb: 'Oppe til høgre' },
  { title: 'Upper center', value: 'upper_center', titleNb: 'Oppe i senter' },
  { title: 'Upper right', value: 'upper_right', titleNb: 'Oppe til venstre' },
]

export const INSCRIPTION_LOCATIONS = [
  { title: 'Recto', value: 'recto', titleNb: 'Recto' },
  { title: 'Verso', value: 'verso', titleNb: 'Verso' },
]

export const INSCRIPTION_TECHNIQUES = [
  { title: 'Inscription', value: 'inscription', titleNb: 'Inskripsjon' },
  { title: 'Stamp', value: 'stamp', titleNb: 'Stempel' },
  { title: 'Mark', value: 'mark', titleNb: 'Etikett' },
]

export const OWNER_TYPE_PERSON = 'person'
export const OWNER_TYPE_INSTITUTION = 'institution'
export const OWNER_TYPES = [
  { title: 'Person', value: OWNER_TYPE_PERSON, titleNb: 'person_nb' },
  { title: 'Institution', value: OWNER_TYPE_INSTITUTION, titleNb: 'institution_nb' },
]

export const ACQUISITION_METHOD = [
  { value: 'producer', title: 'Producer' },
  { value: 'purchased_by', title: 'Purchased by' },
  { value: 'on_deposit_to', title: 'On deposit to' },
  { value: 'by_descent_to', title: 'By descent to' },
  { value: 'privately_purchased_by', title: 'Privately purchased by' },
  { value: 'purchased_at_exhibition_by', title: 'Purchased at exhibition by' },
  { value: 'purchased_at_auction_by', title: 'Purchased at auction by' },
  { value: 'gift_to', title: 'Gift to' },
  { value: 'won_by', title: 'Won by' },
  { value: 'in_custody_of', title: 'In custody of' },
  { value: 'forced_sale_to', title: 'Forced sale, to' },
  { value: 'by_exchange_to', title: 'By exchange, to' },
  { value: 'destroyed_or_lost', title: 'Destroyed or lost' },
  { value: 'unknown_aquisition_method', title: 'Unknown aquisition method' },
]

export const REFERENCE_TYPES = [
  { title: 'Book', value: 'book', titleNb: 'Book' },
  { title: 'Book section', value: 'book_section', titleNb: 'Book section' },
  { title: 'Catalog', value: 'catalog', titleNb: 'Catalog' },
  { title: 'Edited book', value: 'edited_book', titleNb: 'Edited book' },
  { title: 'Film or broadcast', value: 'film_broadcast', titleNb: 'Film or broadcast' },
  { title: 'Journal article', value: 'journal_article', titleNb: 'Journal article' },
  { title: 'Manuscript', value: 'manuscript', titleNb: 'Manuskript' },
  { title: 'Newspaper article', value: 'newspaper_article', titleNb: 'Newspaper article' },
  { title: 'Pamphlet', value: 'pamphlet', titleNb: 'Pamphlet' },
  { title: 'Personal correspondance', value: 'personal_communication', titleNb: 'Personlig korrespondanse' },
  { title: 'Thesis', value: 'thesis', titleNb: 'Thesis' },
  { title: 'Unpublished work', value: 'unpublished_work', titleNb: 'Unpublished work' },
]

export const DOCUMENT_TYPES = [
  { title: 'Manuscript', value: 'manuscript', titleNb: 'Manuskript' },
  { title: 'Personal communication', value: 'personal_communication', titleNb: 'Personlig korrespondanse' },
]

export const DOCUMENT_SUBTYPES = {
  manuscript: [
    { title: 'Document', value: 'document', titleNb: 'Dokument' },
    { title: 'Manuscript', value: 'manuscript', titleNb: 'Manuskript' },
    { title: 'Manuscript fragment', value: 'fragment', titleNb: 'Manuskriptfragment' },
    { title: 'Notebook', value: 'notebook', titleNb: 'Notisbok' },
    { title: 'Manuscript with drawing', value: 'document_drawing', titleNb: 'Manuskript med tegning' },
  ],
  personal_communication: [
    { title: 'Letter', value: 'letter', titleNb: 'Brev' },
    { title: 'Letter draft', value: 'letter_draft', titleNb: 'Brevutkast' },
    { title: 'Letter fragment', value: 'letter_fragment', titleNb: 'Brevfragment' },
    { title: 'Postcard', value: 'postcard', titleNb: 'Postkort' },
    { title: 'Envelope', value: 'envelope', titleNb: 'Konvolutt' },
  ],
}

export const TITLES = [
  { title: 'Mr', value: 1, titleNb: 'Mr' },
  { title: 'Mrs', value: 2, titleNb: 'Mrs' },
  { title: 'Ms', value: 3, titleNb: 'Ms' },
  { title: 'Sir', value: 4, titleNb: 'Sir' },
  { title: 'Dr', value: 5, titleNb: 'Dr' },
  { title: 'Lady', value: 6, titleNb: 'Lady' },
  { title: 'Lord ', value: 7, titleNb: 'Lord' },
]

export const GENDERS = [
  { title: 'Male', value: 1, titleNb: 'Male' },
  { title: 'Female', value: 2, titleNb: 'Female' },
  { title: 'Other', value: 3, titleNb: 'Other' },
]

export const THEMES = [
  { title: 'Theme A', value: 'theme-a' },
  { title: 'Theme B', value: 'theme-b' },
  { title: 'Theme C', value: 'theme-c' },
  { title: 'Theme D', value: 'theme-d' },
]

export const WHEREABOUTS = [{ title: 'Unknown', value: '1' }]

export const KEYWORDS_CATEGORIES = [
  { title: 'Architecture', value: 'architecture', titleNb: 'Arkitektur' },
  { title: 'Animals', value: 'animals', titleNb: 'Dyr' },
  { title: 'Farming', value: 'farming', titleNb: 'Gårdsbruk' },
  { title: 'Interior', value: 'interior', titleNb: 'Interiør' },
  { title: 'Nature', value: 'nature', titleNb: 'Natur' },
  { title: 'Figure', value: 'figure', titleNb: 'Figur' },
  { title: 'Religion / Life and manners', value: 'religion_life', titleNb: 'Religion / Folkeliv' },
  { title: 'Mood', value: 'mood', titleNb: 'Stemning' },
  { title: 'Colours', value: 'colours', titleNb: 'Fargar' },
]

export const KEYWORDS_SUBCATEGORIES = {
  architecture: [
    { title: 'Buildings', value: 'buildings', titleNb: 'Bygninger' },
    { title: 'Created environments', value: 'created_environments', titleNb: 'Konstruert/skapt miljø?' },
    { title: 'Transport', value: 'transport', titleNb: 'Transport' },
  ],
  animals: [
    { title: 'Farm animals', value: 'farm_animals', titleNb: 'Husdyr / Gårdsdyr?' },
    { title: 'Birds', value: 'birds', titleNb: 'Fugl' },
    { title: 'Wild animals', value: 'wild_animals', titleNb: 'Ville dyr' },
    { title: 'Fish', value: 'fish', titleNb: 'Fisk' },
  ],
  farming: [
    { title: 'Garden', value: 'garden', titleNb: 'Hage' },
    { title: 'Hey', value: 'hey', titleNb: 'Høy' },
    { title: 'Harvest', value: 'harvest', titleNb: 'Hauste' },
    { title: 'Stooks', value: 'stooks', titleNb: 'Kornstaur' },
    { title: 'Hay pile', value: 'hay_pile', titleNb: 'Trehesje' },
    { title: 'Willow', value: 'willow', titleNb: 'Styving' },
    { title: 'Sow', value: 'sow', titleNb: 'Så' },
    { title: 'Field', value: 'field', titleNb: 'Åker' },
  ],
  interior: [
    { title: 'Architecture', value: 'architecture', titleNb: 'Arkitektur' },
    { title: 'Furniture', value: 'furniture', titleNb: 'Møbel' },
    { title: 'Still life', value: 'still_life', titleNb: 'Stilleben' },
  ],
  nature: [
    { title: 'Flower', value: 'flower', titleNb: 'Blomst' },
    { title: 'Bodies of water', value: 'bodies_of_water', titleNb: 'Vann' },
    { title: 'Mountains', value: 'mountains', titleNb: 'Fjell' },
    { title: 'Trees', value: 'trees', titleNb: 'Tre' },
    { title: 'Fruit', value: 'fruit', titleNb: 'Frukt' },
    { title: 'Vegetable', value: 'vegetable', titleNb: 'Grønnsak' },
    { title: 'Formation', value: 'formation', titleNb: 'Formasjon' },
    { title: 'Event', value: 'event', titleNb: 'Naturfenomen' },
  ],
  figure: [
    { title: 'Person', value: 'person', titleNb: 'Person' },
    { title: 'Child', value: 'child', titleNb: 'Barn' },
    { title: 'Portrait', value: 'portrait', titleNb: 'Portrett' },
    { title: 'Named person', value: 'named_person', titleNb: 'Navngitt person' },
  ],
  religion_life: [
    { title: 'Ceremony', value: 'ceremony', titleNb: 'Seremoni' },
    { title: 'Biblical motif', value: 'biblical_motif', titleNb: 'Bibelsk motiv' },
    { title: 'Everyday life', value: 'everyday_life', titleNb: '' },
    { title: 'Holiday', value: 'holiday', titleNb: 'Høytid' },
  ],
  mood: [
    { title: 'Season', value: 'season', titleNb: 'Årstid' },
    { title: 'Time of day', value: 'Time_of_day', titleNb: 'Tid' },
    { title: 'Weather', value: 'weather', titleNb: 'Værfenomen' },
  ],
  colours: [
    { title: 'Green', value: 'season', titleNb: 'Grønn' },
    { title: 'Blue', value: 'Time_of_day', titleNb: 'Blå' },
    { title: 'White', value: 'white', titleNb: 'Kvit' },
    { title: 'Red', value: 'red', titleNb: 'Raud' },
    { title: 'Purple', value: 'purple', titleNb: 'Fiolett' },
    { title: 'Yellow', value: 'yellow', titleNb: 'Gul' },
    { title: 'Grey', value: 'grey', titleNb: 'Grå' },
    { title: 'Brown', value: 'brown', titleNb: 'Brun' },
  ],
}

export const DATABASES = [
  { title: 'Nikolai Astrup', id: 'mysql', accent_color: '#21cbaa' },
  { title: 'Theodor Kittelsen', id: 'mysql2', accent_color: '#948ff9' },
  { title: 'Harriet Backer', id: 'mysql3', accent_color: '#fc8549' },
]

export const ARTWORK_IDS = {
  mysql: [
    { title: 'NAM', value: 'id_nam' },
    { title: 'CR', value: 'cr_number' },
    { title: 'Museum', value: 'verk_id' },
    { title: 'Loge', value: 'nr_loge_k' },
    { title: 'Astrup', value: 'astrups_list' },
    { title: 'Greve', value: 'greve_cat_nr' },
    { title: 'Askeland', value: 'nr_askeland' },
    { title: 'Private', value: 'id_privat' },
    { title: 'Accession', value: 'accession_nr' },
  ],
  mysql2: [
    { title: 'CR', value: 'cr_number' },
    { title: 'CR WIP', value: 'cr_wip_number' },
    { title: 'Museum', value: 'verk_id' },
    { title: 'Private', value: 'id_privat' },
    { title: 'Accession', value: 'accession_nr' },
    { title: 'Artists', value: 'artists_id' },
    { title: 'Previous 1', value: 'previous_1_id' },
    { title: 'Previous 2', value: 'previous_2_id' },
    { title: 'Previous 3', value: 'previous_3_id' },
  ],
  default: [
    { title: 'CR', value: 'cr_number' },
    { title: 'CR WIP', value: 'cr_wip_number' },
    { title: 'Museum', value: 'verk_id' },
    { title: 'Private', value: 'id_privat' },
    { title: 'Accession', value: 'accession_nr' },
    { title: 'Artists', value: 'artists_id' },
    { title: 'Previous 1', value: 'previous_1_id' },
    { title: 'Previous 2', value: 'previous_2_id' },
    { title: 'Previous 3', value: 'previous_3_id' },
  ],
}

export const ARTWORK_IDS_FOR_CATALOGUES = {
  mysql: [
    { title: 'Askeland', value: 'nr_askeland' },
    { title: 'Astrup', value: 'astrups_list' },
    { title: 'Greve', value: 'greve_cat_nr' },
    { title: 'Loge', value: 'nr_loge_k' },
  ],
  default: [
    { title: 'Artists', value: 'artists_id' },
    { title: 'Previous 1', value: 'previous_1_id' },
    { title: 'Previous 2', value: 'previous_2_id' },
    { title: 'Previous 3', value: 'previous_3_id' },
  ],
}
