import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getDatabase } from '../../services/auth'

const useHasFocus = () => {
  // get the initial state
  const [focus, setFocus] = useState(document.hasFocus())

  useEffect(() => {
    // helper functions to update the status
    const onFocus = () => setFocus(true)
    const onBlur = () => setFocus(false)

    // assign the listener
    // update the status on the event
    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur)

    // remove the listener
    return () => {
      window.removeEventListener('focus', onFocus)
      window.removeEventListener('blur', onBlur)
    }
  }, [])

  // return the status
  return focus
}
export const FocusListener = () => {
  const focus = useHasFocus()
  const databaseState = useSelector((state) => state.auth.database)
  const reloadIfChanged = async () => {
    const databaseLocalStorage = await getDatabase()

    // console.log('databaseState', databaseState)
    // console.log('databaseLocalStorage', databaseLocalStorage)

    if (databaseLocalStorage != databaseState) {
      window.location.reload()
    }
  }

  useEffect(() => {
    reloadIfChanged()
  }, [focus])

  return null
}
