//

const artworkTranslationOf = language => ({
  title: '',
  body: '',
  alternative_titles: '',
  signature: '',
  inscription: '',
  verso_inscription: '',
  collection: '',
  acquisitions: '',
  comments: '',
  subject: '',
  subtitle: '',
  artwork_id: undefined,
  language: language
})

export const artwork = {
  id: undefined,
  stable_id: undefined,
  width: undefined,
  height: undefined,
  format: 'cm',
  signature_type: undefined,
  signature: undefined,
  latitude: undefined,
  longitude: undefined,
  other_date: undefined,
  location: undefined,
  date_year: undefined,
  year_from: undefined,
  year_to: undefined,
  cr_number: undefined,
  cr_wip_number: undefined,
  accession_nr: undefined,
  astrups_list: undefined,
  artists_id: undefined,
  illus_1: undefined,
  illus_2: undefined,
  greve_cat_nr: undefined,
  nr_askeland: undefined,
  nr_loge_k: undefined,
  nr_loge_ny: undefined,
  previous_1_id: undefined,
  previous_2_id: undefined,
  previous_3_id: undefined,
  ref_id: undefined,
  sbs_nr: undefined,
  verk_id: undefined,
  bkm_dep: undefined,
  translations: {
    nb: artworkTranslationOf('nb'),
    en: artworkTranslationOf('en')
  },
  images: [],
  exhibitions: [],
  motifs: [],
  types: [],
  materials: [],
  techniques: [],
  alternative_titles: [],
  reviewed: false,
  published: false,
  created_at: undefined,
  updated_at: undefined
}
