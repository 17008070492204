import * as React from 'react'
import { Field, formValueSelector } from 'redux-form'
import { OTHER } from '../../utils/constants'
import { ARTWORK_FORM } from '../../services/forms'
import { useSelector } from 'react-redux'
import { CustomRadioGroup } from '../general/CustomRadioGroup'
import { CustomRadio } from '../general/CustomRadio'
import { CustomTextField } from '../general/CustomTextField'

const RadioList = ({
  name,
  items,
  fieldNameOther = '',
  labelOther = 'Specify',
  relevantOption = OTHER,
  formName = "",
}) => {
  const selector = formValueSelector(formName)
  const relevantValue = useSelector((state) => selector(state, 'location'))

  return (
    <div className="light-radio location-radio">
      <Field name={name} item={items} component={CustomRadioGroup}>
        {items && items.map((item, i) => <CustomRadio key={`${i}-${item.value}`} value={item.value} label={item.title} />)}
      </Field>
      {relevantOption === relevantValue && (
        <div>
          
          <Field
            id={`${fieldNameOther}-input`}
            name={fieldNameOther}
            label={labelOther}
            component={CustomTextField}
            type="text"
            width="full"
          />
        </div>
      )}
    </div>
  )
}

export default RadioList
