import { COLOR_GRAY_MID, COLOR_LIGHT_TEXT, COLOR_GRAY_BCG } from "../../config/colors"

export const fieldStyle = {
  underlineStyle: { bottom: '0px', borderWidth: '3px', borderColor: 'transparent' },
  style: { background: COLOR_GRAY_MID, maxWidth: '100%', border: '1px solid #2D2D2D', margin: '0px' },
  inputStyle: { paddingLeft: '16px', color: COLOR_LIGHT_TEXT },
  hintStyle: { paddingLeft: '16px', color: COLOR_LIGHT_TEXT, display: 'none' },
}

export const textFieldStyle = {
  underlineStyle: { bottom: '0px', borderWidth: '3px', borderColor: 'transparent', height: '48px' },
  style: { background: COLOR_GRAY_MID, maxWidth: '100%', border: '1px solid #2D2D2D', margin: '0px', height: '48px', },
  inputStyle: { padding: '0 0 0 16px', color: COLOR_LIGHT_TEXT, height: '48px', },
  hintStyle: { padding: '0 0 0 16px', color: COLOR_LIGHT_TEXT, display: 'none' },
}

export const textareaStyle = {
  style: { background: COLOR_GRAY_MID, maxWidth: '100%', border: '1px solid #2D2D2D', margin: '0px', width: '100%' },
  underlineStyle: { bottom: '19px', borderWidth: '3px', borderColor: 'transparent' },
  textareaStyle: { margin: '0px' },
}

export const modalStyle = {
  className: {
    base: 'modal',
    afterOpen: 'modal-open',
    beforeClose: 'modal-close',
  },
  overlayClassName: {
    base: 'modal-overlay',
    afterOpen: 'modal-overlay-open',
    beforeClose: 'modal-overlay-close',
  },
}

export const accordionStyle = {
  style: { background: COLOR_GRAY_BCG },
}
