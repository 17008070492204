import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setFilter } from '../../../actions/filtersActions'
import { CustomTextField } from '../../general/CustomTextField'

const Filters = () => {
  const dispatch = useDispatch()
  const filters = useSelector((state) => state.filters)
  const visible = useSelector((state) => state.filters.visible)

  const handleFilterChange = (field, value) => {
    dispatch(setFilter(field, value))
  }

  return (
    visible || (
      <div className="filters">
        <div className="row filter-section">
          <div className="full-field">
            <div className="field-label">Search</div>
            <CustomTextField
              width="full"
              name="q"
              value={filters.q}
              onChange={(_, value) => handleFilterChange('q', value)}
            />
          </div>
        </div>
      </div>
    )
  )
}

export default Filters
