import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { DOCUMENT_FORM, documentFormSelector } from '../../../services/forms'
import { deleteDocument, loadDocument, updateDocument } from '../../../actions/documentsActions'
import DocumentForm from '../form/DocumentForm'
import { change, formValueSelector, submit } from 'redux-form'
import FormTop from '../../form/FormTop'
import { uploadFile } from '../../../services/http'
import { documentModel } from '../../../models/documentModel'
import { EditTop } from '../../general/EditTop'
import { loadProvenancePersons } from '../../../actions/provenancePersonsActions'
import { loadProvenanceInstitutions } from '../../../actions/provenanceInstitutionsActions'
import { loadExhibitionsBasic } from '../../../actions/exhibitionsActions'
import { loadArtworksAll } from '../../../actions/artworksActions'
import DataProviderFunctional from '../../loader/DataProviderFunctional'
import { PublishedStatus } from '../../general/PublishedStatus'

const DocumentEditPresentation = () => {
  let { id } = useParams()
  const documents = useSelector((state) => state.documents.items)
  const lang = useSelector((state) => state.translations.language)
  const initialValues = documents[id] ? documents[id] : documentModel
  const saveState = useSelector((state) => state.documents.saveState)
  const selector = formValueSelector(DOCUMENT_FORM)
  const type = useSelector((state) => selector(state, 'type'))
  const dispatch = useDispatch()
  const onSubmit = (data) => {
    const documents_pages = data.documents_pages.map((p) => {
      const transcription = p.transcription ? p.transcription : EditorState.createEmpty()
      const translation = p.translation ? p.translation : EditorState.createEmpty()
      const rawStateTranscription = convertToRaw(transcription.getCurrentContent())
      const rawStateTranslation = convertToRaw(translation.getCurrentContent())

      return {
        ...p,
        transcription_state: JSON.stringify(rawStateTranscription),
        transcription_html: draftToHtml(rawStateTranscription),
        translation_state: JSON.stringify(rawStateTranslation),
        translation_html: draftToHtml(rawStateTranslation),
      }
    })
    dispatch(updateDocument({ ...data, documents_pages: documents_pages, id: id }))
  }
  const remoteSubmit = () => dispatch(submit(DOCUMENT_FORM))
  const remove = () => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteDocument(id))
    }
  }
  const changeFieldValue = (field, value) => dispatch(change(DOCUMENT_FORM, field, value))
  const upload = uploadFile

  return (
    <div>
      <div className="Exhibition-controls">
        <EditTop title="Edit Document" remove={remove} />
        <div className="controls">
          <FormTop
            title={initialValues.title ? initialValues.title : 'Edit Document'}
            button="Save Document"
            remote={remoteSubmit}
            remove={remove}
            saveState={saveState}
          />
          <PublishedStatus changeFieldValue={changeFieldValue} formName={DOCUMENT_FORM} />
        </div>
      </div>

      <div className="Exhibition-grid">
        {/* <NavigationPrompt when={dirty} /> */}
        <DocumentForm
          lang={lang}
          onSubmit={onSubmit}
          changeFieldValue={changeFieldValue}
          initialValues={initialValues}
          upload={upload}
          type={type}
        />
      </div>

      <div />
    </div>
  )
}

const DocumentEdit = () => {
  let { id } = useParams()
  const error = useSelector((state) => state.documents.error)
  const loading = useSelector((state) => state.documents.state)
  const dispatch = useDispatch()
  const watch = 'id'
  const load = () => {
    dispatch(loadDocument(id))
    dispatch(loadProvenancePersons())
    dispatch(loadExhibitionsBasic())
    dispatch(loadProvenanceInstitutions())
    dispatch(loadArtworksAll())
  }
  return (
    <DataProviderFunctional
      error={error}
      load={load}
      loading={loading}
      watch={watch}
      component={DocumentEditPresentation}
    />
  )
}

export default DocumentEdit
