import React from 'react'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { COLOR_GRAY_DARK, COLOR_GRAY_MID } from '../../config/colors'

const useStyles = makeStyles({
  root: {
    background: COLOR_GRAY_MID,
    maxWidth: '100%',
    border: `1px solid ${COLOR_GRAY_DARK}`,
    margin: '0px',
    width: '100%',
    '& .MuiInputBase-multiline': {
      padding: 0,
    },
  },
  underline: {
    '&:before': {
      borderBottom: `3px solid transparent`,
    },
    '&&:hover:not($disabled):before': {
      borderBottom: `3px solid transparent`,
    },
    '&:after': {
      borderBottomWidth: `3px`,
    },
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
})

export const CustomTextArea = ({
  id,
  name,
  label,
  hideLabel,
  value,
  onChange,
  light,
  input,
  minRows,
  maxRows,
  disabled,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <>
      {label && !hideLabel && (
        <label className="field-label" htmlFor={id}>
          {label}
        </label>
      )}
      <TextField
        id={id}
        name={name}
        //label={label}
        InputProps={{
          classes: {
            underline: classes.underline,
          },
        }}
        inputProps={{ 'aria-label': `${label}` }}
        classes={{
          root: `${classes.root} ${light ? classes.light : ''} ${disabled ? classes.disabled : ''}`,
        }}
        value={value}
        onChange={(event) => onChange(name, event.target.value, event)}
        multiline
        minRows={minRows}
        maxRows={maxRows}
        disabled={disabled}
        {...input}
        {...rest}
      />
    </>
  )
}
