import React from 'react'
import { change, Field } from 'redux-form'
import { Tooltip, IconButton } from '@material-ui/core'
import { fieldStyle } from '../../form/style'
import { CATALOGUE_FORM } from '../../../services/forms'
import { Close } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { AutoComplete } from './AutoComplete'
import { COLOR_LIGHT_TEXT } from '../../../config/colors'

export const AddInstitutionsField = ({ member, index, fields }) => {
  const institutions = useSelector((state) => state.provenanceInstitutions.items)
  const catalogueInstitutions = Object.values(institutions).map((i) => ({
    id: i.id,
    name: `${i.name} (${i.founded})`,
  }))
  const dispatch = useDispatch()
  const changeFieldValue = (field, value) => {
    dispatch(change(CATALOGUE_FORM, field, value))
  }

  return (
    <div key={index} className="flex-box select-reference artwork-row repeater-field">
      <div className="field">
        <Field
          name={`${member}.id`}
          items={catalogueInstitutions}
          changeFieldValue={changeFieldValue}
          {...fieldStyle}
          component={AutoComplete}
        />
      </div>
      <Tooltip title="Remove">
        <IconButton className="repeater-remove" onClick={() => fields.remove(index)}>
          <Close style={{ color: COLOR_LIGHT_TEXT}} />
        </IconButton>
      </Tooltip>
    </div>
  )
}
