const translationOf = lang => ({
  title: '',
  language: lang
})

export const title = {
  status: 'established',
  translations: {
    nb: translationOf('nb'),
    en: translationOf('en')
  }
}

export const emptyTitle = {
  status: '',
  translations: {
    nb: translationOf('nb'),
    en: translationOf('en')
  }
}

export const titleExhibition = {
  status: 0,
  translations: {
    nb: translationOf('nb'),
    en: translationOf('en')
  }
}
