import { loadingState } from '../utils/loadingState'
import * as actionTypes from '../actions/actionTypes'

const initialState = {
  id: undefined,
  state: loadingState.IDLE,
  error: undefined,
  saveState: loadingState.IDLE,
  saveError: undefined
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.ARTWORK_LOAD:
      return { ...state, state: loadingState.LOADING, id: undefined }
    case actionTypes.ARTWORK_LOADED:
      return { ...state, state: loadingState.LOADED, id: payload.id }
    case actionTypes.ARTWORK_LOAD_FAILED:
      return { ...state, state: loadingState.FAILED, error: payload }
    case actionTypes.ARTWORK_UPDATE:
    case actionTypes.ARTWORK_CREATE:
      return { ...state, saveState: loadingState.LOADING, error: undefined }
    case actionTypes.ARTWORK_UPDATED:
    case actionTypes.ARTWORK_CREATED:
      return { ...state, saveState: loadingState.LOADED, error: undefined }
    case actionTypes.ARTWORK_UPDATE_FAILED:
    case actionTypes.ARTWORK_CREATE_FAILED:
      return { ...state, saveState: loadingState.FAILED, error: payload }
    default:
      return state
  }
}
