import React from 'react'
import { useDispatch } from 'react-redux'
import { Field, change, reduxForm } from 'redux-form'
import Select from 'react-select'
import propOr from 'crocks/helpers/propOr'
import { fieldStyle, textareaStyle } from '../../form/style'
import { EXHIBITION_FORM } from '../../../services/forms'
import './ExhibitionForm.css'
import { ExhibitionArtworks } from './ExhibitionArtworks'
import ExhibitionCurrators from './ExhibitionCurrators'
import { ReferenceField } from '../../form/ReferenceField'
import { AccurateDate } from '../../form/AccurateDate'
import { DATE_ACCURACY_EXHIBITIONS } from '../../../config/config'
import MarkupEditor from '../../form/MarkupEditor'
import { CustomRadioGroup } from '../../general/CustomRadioGroup'
import { CustomRadio } from '../../general/CustomRadio'
import { CustomTextField } from '../../general/CustomTextField'

// const validate = values => {
//   const errors = {}
//   const requiredFields = ['location']
//   requiredFields.forEach(field => {
//     if (!values[field]) {
//       errors[field] = 'Required'
//     }
//   })

//   return errors
// }

const wrapperStyle = {
  width: '256px',
  zIndex: 1000,
}

const AutoComplete = ({ input, items }) => {
  const dispatch = useDispatch()
  const institutions = Object.keys(items)
    .map((id, i) => ({ id: parseInt(id, 10), name: items[id].name }))
    .sort(function (a, b) {
      var textA = a.name.toUpperCase()
      var textB = b.name.toUpperCase()
      return textA < textB ? -1 : textA > textB ? 1 : 0
    })

  const changeFieldValue = (field, value) => {
    dispatch(change(EXHIBITION_FORM, field, value))
  }

  return (
    <Select
      wrapperStyle={wrapperStyle}
      name="institutions"
      options={institutions}
      labelKey="name"
      valueKey="id"
      value={input.value}
      onChange={(value) => changeFieldValue(input.name, propOr(undefined, 'id', value))}
      searchable={true}
      clearable={false}
    />
  )
}

const InstitutionLocation = ({ institution }) => (
  <div className="padded-left">{institution ? `${institution.street} (${institution.city})` : '-'}</div>
)

const ExhibitionFormPresentation = ({ lang, persons, institutions }) => {
  if (!persons) persons = {}

  return (
    <form className="form-container">
      <div className="row">
        <div>
          <Field
            name="is_separate"
            component={CustomRadioGroup}
            normalize={(val) => parseInt(val, 10)}
            style={{ maxWidth: 460 }}
          >
            <CustomRadio value={1} label="Separate Exhibition" />
            <CustomRadio value={0} label="Group Exhibition" />
          </Field>
        </div>
      </div>

      <div className="row">
        <Field id="title-input" name="title" component={CustomTextField} label="Title" width="full" />
      </div>

      <div className="row">
        <div className="field-label">Title translations</div>
        <div className="flex-box">
          <div className="repeater-field">
            <div>
              <div className="reference-holder  label--basic">Title EN</div>
            </div>
            <div>
              <div className="reference-holder  label--basic">Title NB</div>
            </div>
          </div>
          <div className="repeater-field">
            <div>
              <Field
                id="title_en-input"
                name="title_en"
                component={CustomTextField}
                label="Title EN"
                hideLabel={true}
              />
            </div>
            <div>
              <Field
                id="title_nb-input"
                name="title_nb"
                component={CustomTextField}
                label="Title NB"
                hideLabel={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="flex">
          <AccurateDate
            accuracyTitle="From accuracy"
            accuracyField="from_accuracy"
            dateField="from"
            accuracies={DATE_ACCURACY_EXHIBITIONS}
          />
          <AccurateDate
            accuracyTitle="To accuracy"
            accuracyField="to_accuracy"
            dateField="to"
            accuracies={DATE_ACCURACY_EXHIBITIONS}
          />
        </div>
      </div>
      <div className="row">
        <div className="field-label">Institution</div>
        <Field
          className="repeater-field"
          hintText="Museum"
          name={`gallery_id`}
          items={institutions}
          {...fieldStyle}
          component={AutoComplete}
        />
      </div>
      <div className="row">
        <div className="field-label">Description with rich text</div>
        <div className="rich-text-editor">
          <Field
            name={`translations.nb.rich_description`}
            {...textareaStyle}
            type="text"
            multiLine={true}
            minRows={3}
            component={MarkupEditor}
          />
        </div>
      </div>
      <div className="row">
        <div className="field-label">English description with rich text</div>
        <div className="rich-text-editor">
          <Field
            name={`translations.en.rich_description`}
            {...textareaStyle}
            type="text"
            multiLine={true}
            minRows={3}
            component={MarkupEditor}
          />
        </div>
      </div>

      <div className="row">
        <div className="field-label">References</div>
        <div className="references">
          <ReferenceField name={`references.exhibition_general`} formType={EXHIBITION_FORM} />
        </div>
      </div>

      <div className="row">
        <div className="field-label">Catalogue</div>
        <div className="references">
          <ReferenceField name={`references.exhibition_catalogue`} formType={EXHIBITION_FORM} />
        </div>
      </div>

      <div className="row">
        <div className="field-label">Location</div>
        <Field
          name={`gallery_id`}
          component={({ input }) => <InstitutionLocation institution={institutions[input.value]} />}
        />
      </div>

      <div className="row">
        <ExhibitionCurrators persons={persons} />
      </div>

      <div className="row">
        <div className="section-title">Artworks</div>
        <ExhibitionArtworks lang={lang} />
      </div>
    </form>
  )
}

const formProps = {
  form: EXHIBITION_FORM,
  destroyOnUnmount: false,
  enableReinitialize: true,
  //validate
}

const ExhibitionForm = reduxForm(formProps)(ExhibitionFormPresentation)

export default ExhibitionForm
