import * as actionTypes from './actionTypes'
import { isDefined } from '../utils/utils'
import * as auth from '../services/auth'
import { push } from 'connected-react-router'
import * as router from '../services/router'
import { of } from 'rxjs'
import { map, catchError, mergeMap, switchMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'

export const login = (credentials) => ({
  type: actionTypes.AUTH_LOGIN,
  payload: credentials,
})

const loggedIn = (payload) => ({
  type: actionTypes.AUTH_LOGGED_IN,
  payload: payload,
})

const loginFailed = (error) => ({
  type: actionTypes.AUTH_LOGIN_FAILED,
  payload: error,
})

export const loginEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.AUTH_LOGIN),
    switchMap(({ payload }) =>
      auth.login(payload).pipe(
        mergeMap((data) => of(loggedIn({ ...data, database: payload.database }), push(router.ROUTE_ARTWORKS))),
        catchError((error) => {
          const errorMsg = error.response?.body?.message || 'Login failed. Check your credentials'
          return of(loginFailed(errorMsg))
        })
      )
    )
  )

export const logout = () => ({
  type: actionTypes.AUTH_LOGOUT,
})

const loggedOut = () => ({
  type: actionTypes.AUTH_LOGGED_OUT,
})

export const logoutEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.AUTH_LOGOUT),
    mergeMap(auth.logout),
    mergeMap(() => of(loggedOut(), push(router.ROUTE_LOGIN)))
  )

// export const logoutEpic = (action$) =>
//   action$
//     .ofType(actionTypes.AUTH_LOGOUT)
//     .mergeMap(auth.logout)
//     .mergeMap(() => Observable.of(loggedOut(), push(router.ROUTE_LOGIN)))

export const authInit = () => ({
  type: actionTypes.AUTH_INIT,
})

export const authInitEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.AUTH_INIT),
    mergeMap(auth.getAuthData),
    map((authData) => (isDefined(authData) ? loggedIn(authData) : loggedOut()))
  )

// export const authInitEpic = action$ =>
//   action$
//     .ofType(actionTypes.AUTH_INIT)
//     .mergeMap(auth.getAuthData)
//     .map(authData => (isDefined(authData) ? loggedIn(authData) : loggedOut()))
