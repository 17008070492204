import authReducer from './authReducer'
import artworksReducer from './artworksReducer'
import usersReducer from './usersReducer'
import translationsReducer from './translationsReducer'
import artworkReducer from './artworkReducer'
import commentsReducer from './commentsReducer'
import historyReducer from './historyReducer'
import userReducer from './userReducer'
import referencesReducer from './referencesReducer'
import exhibitionsReducer from './exhibitionsReducer'
import personsReducer from './personsReducer'
import personsProvenanceReducer from './provenancePersonsReducer'
import institutionsProvenanceReducer from './provenanceInstitutionsReducer'
import institutionsReducer from './institutionsReducer'
import archivesReducer from './archivesReducer'
import literaturesReducer from './literaturesReducer'
import filtersReducer from './filtersReducer'
import professionsReducer from './professionsReducer'
import countriesReducer from './countriesReducer'
import keywordsReducer from './keywordsReducer'
import documentsReducer from './documentsReducer'
import cataloguesReducer from './cataloguesReducer'
import forgotPasswordReducer from './forgotPasswordReducer'
import resetPasswordReducer from './resetPasswordReducer'


export default {
  auth: authReducer,
  artworks: artworksReducer,
  artwork: artworkReducer,
  users: usersReducer,
  translations: translationsReducer,
  comments: commentsReducer,
  history: historyReducer,
  user: userReducer,
  references: referencesReducer,
  persons: personsReducer,
  provenancePersons: personsProvenanceReducer,
  provenanceInstitutions: institutionsProvenanceReducer,
  archives: archivesReducer,
  literatures: literaturesReducer,
  exhibitions: exhibitionsReducer,
  filters: filtersReducer,
  professions: professionsReducer,
  countries: countriesReducer,
  institutions: institutionsReducer,
  keywords: keywordsReducer,
  documents: documentsReducer,
  catalogues: cataloguesReducer,
  forgotPassword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer
}
