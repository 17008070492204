import { formValueSelector } from 'redux-form'

export const AUTH_FORM = 'auth'
export const COMMENT_FORM = 'comment'
export const PROFILE_FORM = 'profile'
export const USER_FORM = 'user'
export const ARTWORK_FORM = 'artwork'
export const ARCHIVE_FORM = 'archive'
export const EXHIBITION_FORM = 'exhibition'
export const LITERATURE_FORM = 'literature'
export const PERSON_FORM = 'person'
export const INSTITUTION_FORM = 'institution'
export const REFERENCE_FORM = 'reference'
export const KEYWORD_FORM = 'keyword'
export const DOCUMENT_FORM = 'document'
export const CATALOGUE_FORM = 'catalogue'
export const FORGOT_PASSWORD_FORM = 'forgotPassword'
export const RESET_PASSWORD_FORM = 'resetPassword'

export const personFormSelector = formValueSelector(PERSON_FORM)
export const authFormSelector = formValueSelector(AUTH_FORM)
export const userFormSelector = formValueSelector(USER_FORM)
export const profileFormSelector = formValueSelector(PROFILE_FORM)
export const commentFormSelector = formValueSelector(COMMENT_FORM)
export const artworkFormSelector = formValueSelector(ARTWORK_FORM)
export const keywordFormSelector = formValueSelector(KEYWORD_FORM)
export const documentFormSelector = formValueSelector(DOCUMENT_FORM)
export const catalogueFormSelector = formValueSelector(CATALOGUE_FORM)
