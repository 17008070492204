import * as React from 'react'
import { DATE_TYPE_BETWEEN, DATE_TYPE_UNDATED, DATE_TYPE_YEAR_MONTH_DAY, DATE_TYPE_YEAR, DATE_TYPE_BEFORE, DATE_TYPE_AFTER, DATE_TYPE_UNKNOWN, DATE_TYPE_CIRCA, DATE_TYPE_CERTAIN } from '../../config/config'
import { Field } from 'redux-form'
import { CustomTextField } from '../general/CustomTextField'

const DateFromTo = ({ input }) => {
  switch (input.value) {
    case DATE_TYPE_UNDATED:
      return null
    case DATE_TYPE_BETWEEN:
    case  DATE_TYPE_CIRCA:
    case DATE_TYPE_BEFORE:
    case DATE_TYPE_AFTER:
    case DATE_TYPE_CERTAIN:
    case DATE_TYPE_UNKNOWN:
      return (
        <div className="flex-box">
          <Field
            className="repeater-field"
            id="year_from-input"
            name="year_from"
            min="1800"
            component={CustomTextField}
            type="number"
            label="Year from"
            hideLabel={true}
          />
          <Field
            className="repeater-field"
            id="year_to-input"
            name="year_to"
            component={CustomTextField}
            type="number"
            min="1800"
            label="Year to"
            hideLabel={true}
          />
        </div>
      )
    case DATE_TYPE_YEAR:
      return (
        <div className="flex-box">
          <Field
            className="repeater-field"
            id="date_year-input"
            name="date_year"
            component={CustomTextField}
            label="Year"
            hideLabel={true}
            min="1800"
            type="number"
          />
          <Field
            className="repeater-field"
            id="year_to-input"
            name="year_to"
            component={CustomTextField}
            type="number"
            min="1800"
            label="Year to"
            hideLabel={true}
          />
        </div>
      )
    // case DATE_TYPE_YEAR_MONTH_DAY:
    //   return (
    //     <Field
    //       className="repeater-field"
    //       id="year_date-input"
    //       name="year_date"
    //       component={CustomTextField}
    //       type="date"
    //       clearable={false}
    //       min="1800-01-01"
    //       label="Year from"
    //       hideLabel={true}
    //     />
    //   )
    default:
      return (
        <Field
          className="repeater-field"
          id="date_year-input"
          name="date_year"
          component={CustomTextField}
          type="number"
          min="1800"
          label="Year"
          hideLabel={true}
        />
      )
  }
}

export default DateFromTo
