export const AUTH_INIT = 'AUTH_INIT'
export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGGED_IN = 'AUTH_LOGGED_IN'
export const AUTH_LOGIN_FAILED = 'AUTH_LOGIN_FAILED'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_LOGGED_OUT = 'AUTH_LOGGED_OUT'

export const USERS_LOAD = 'USERS_LOAD'
export const USERS_LOADED = 'USERS_LOADED'
export const USERS_LOAD_FAILED = 'USERS_LOAD_FAILED'
export const USER_LOAD = 'USER_LOAD'
export const USER_LOADED = 'USER_LOADED'
export const USER_LOAD_FAILED = 'USER_LOAD_FAILED'
export const USER_CREATE = 'USER_CREATE'
export const USER_CREATED = 'USER_CREATED'
export const USER_DELETE = 'USER_DELETE'
export const USER_DELETE_FAILED = 'USER_DELETE_FAILED'
export const USER_DELETED = 'USER_DELETED'
export const USER_CREATE_FAILED = 'USER_CREATE_FAILED'
export const USER_UPDATE = 'USER_UPDATE'
export const USER_UPDATED = 'USER_UPDATED'
export const USER_UPDATE_FAILED = 'USER_UPDATE_FAILED'
export const USERS_ORDER = 'USERS_ORDER'

export const HISTORY_LOAD = 'HISTORY_LOAD'
export const HISTORY_LOADED = 'HISTORY_LOADED'
export const HISTORY_LOAD_FAILED = 'HISTORY_LOAD_FAILED'
export const HISTORY_USER_LOAD = 'HISTORY_USER_LOAD'
export const HISTORY_USER_LOADED = 'HISTORY_USER_LOADED'
export const HISTORY_USER_LOAD_FAILED = 'HISTORY_USER_LOAD_FAILED'

export const COMMENTS_LOAD = 'COMMENTS_LOAD'
export const COMMENTS_LOADED = 'COMMENTS_LOADED'
export const COMMENTS_LOAD_FAILED = 'COMMENTS_LOAD_FAILED'

export const COMMENT_CREATE = 'COMMENT_CREATE'
export const COMMENT_CREATED = 'COMMENT_CREATED'
export const COMMENT_CREATE_FAILED = 'COMMENT_CREATE_FAILED'

export const ARTWORKS_LOAD = 'ARTWORKS_LOAD'
export const ARTWORKS_LOAD_ALL = 'ARTWORKS_LOAD_ALL'
export const ARTWORKS_LOADED = 'ARTWORKS_LOADED'
export const ARTWORKS_LOADED_ALL = 'ARTWORKS_LOADED_ALL'
export const ARTWORKS_LOAD_FAILED = 'ARTWORKS_LOAD_FAILED'
export const ARTWORKS_SEARCH = 'ARTWORKS_SEARCH'
export const ARTWORKS_SEARCHED = 'ARTWORKS_SEARCHED'
export const ARTWORKS_SEARCH_FAILED = 'ARTWORKS_SEARCH_FAILED'
export const ARTWORKS_TOGGLE_VIEW = 'ARTWORKS_TOGGLE_VIEW'
export const ARTWORKS_TOGGLE_SORT = 'ARTWORKS_TOGGLE_SORT'
export const ARTWORKS_ORDER = 'ARTWORKS_ORDER'

export const ARTWORK_LOAD = 'ARTWORK_LOAD'
export const ARTWORK_LOADED = 'ARTWORK_LOADED'
export const ARTWORK_LOAD_FAILED = 'ARTWORK_LOAD_FAILED'
export const ARTWORK_CREATE = 'ARTWORK_CREATE'
export const ARTWORK_CREATED = 'ARTWORK_CREATED'
export const ARTWORK_CREATE_FAILED = 'ARTWORK_CREATE_FAILED'
export const ARTWORK_UPDATE = 'ARTWORK_UPDATE'
export const ARTWORK_UPDATED = 'ARTWORK_UPDATED'
export const ARTWORK_UPDATE_FAILED = 'ARTWORK_UPDATE_FAILED'
export const ARTWORK_DELETE = 'ARTWORK_DELETE'
export const ARTWORK_DELETED = 'ARTWORK_DELETED'
export const ARTWORK_DELETE_FAILED = 'ARTWORK_DELETE_FAILED'

export const ARTWORK_VERSIONS_LOAD = 'ARTWORK_VERSIONS_LOAD'
export const ARTWORK_VERSIONS_LOADED = 'ARTWORK_VERSIONS_LOADED'
export const ARTWORK_VERSIONS_LOAD_FAILED = 'ARTWORK_VERSIONS_LOAD_FAILED'

export const TRANSLATIONS_CHANGE_LANGUAGE = 'TRANSLATIONS_CHANGE_LANGUAGE'

export const FILES_UPLOAD = 'FILES_UPLOAD'
export const FILES_UPLOADED = 'FILES_UPLOADED'
export const FILES_UPLOAD_FAILED = 'FILES_UPLOAD_FAILED'

export const FILTERS_TOGGLE = 'FILTERS_TOGGLE'
export const FILTERS_SET = 'FILTERS_SET'
export const FILTERS_VISIBILITY_TOGGLE = 'FILTERS_VISIBILITY_TOGGLE'

export const REFERENCES_LOAD = 'REFERENCES_LOAD'
export const REFERENCES_LOADED = 'REFERENCES_LOADED'
export const REFERENCES_LOAD_FAILED = 'REFERENCES_LOAD_FAILED'
export const REFERENCES_SEARCH = 'REFERENCES_SEARCH'
export const REFERENCES_ERROR = 'REFERENCES_ERROR'
export const REFERENCES_REMOVE_ERROR = 'REFERENCES_REMOVE_ERROR'
export const REFERENCES_SEARCHED = 'REFERENCES_SEARCHED'
export const REFERENCES_SEARCH_FAILED = 'REFERENCES_SEARCH_FAILED'
export const REFERENCE_LOAD = 'REFERENCE_LOAD'
export const REFERENCE_LOADED = 'REFERENCE_LOADED'
export const REFERENCE_LOAD_FAILED = 'REFERENCE_LOAD_FAILED'
export const REFERENCE_CREATE = 'REFERENCE_CREATE'
export const REFERENCE_CREATED = 'REFERENCE_CREATED'
export const REFERENCE_CREATE_FAILED = 'REFERENCE_CREATE_FAILED'
export const REFERENCE_UPDATE = 'REFERENCE_UPDATE'
export const REFERENCE_UPDATED = 'REFERENCE_UPDATED'
export const REFERENCE_UPDATE_FAILED = 'REFERENCE_UPDATE_FAILED'
export const REFERENCES_ORDER = 'REFERENCES_ORDER'
export const REFERENCE_DELETE = 'REFERENCE_DELETE'
export const REFERENCE_DELETED = 'REFERENCE_DELETED'
export const REFERENCE_DELETE_FAILED = 'REFERENCE_DELETE_FAILED'

export const PERSONS_LOAD = 'PERSONS_LOAD'
export const PERSONS_LOADED = 'PERSONS_LOADED'
export const PERSONS_LOADED_ALL = 'PERSONS_LOADED_ALL'
export const PERSONS_LOAD_FAILED = 'PERSONS_LOAD_FAILED'
export const PERSONS_SEARCH = 'PERSONS_SEARCH'
export const PERSONS_SEARCHED = 'PERSONS_SEARCHED'
export const PERSONS_SEARCH_FAILED = 'PERSONS_SEARCH_FAILED'
export const PERSON_LOAD = 'PERSON_LOAD'
export const PERSON_LOADED = 'PERSON_LOADED'
export const PERSON_LOAD_FAILED = 'PERSON_LOAD_FAILED'
export const PERSON_CREATE = 'PERSON_CREATE'
export const PERSON_CREATED = 'PERSON_CREATED'
export const PERSON_CREATE_FAILED = 'PERSON_CREATE_FAILED'
export const PERSON_UPDATE = 'PERSON_UPDATE'
export const PERSON_UPDATED = 'PERSON_UPDATED'
export const PERSON_UPDATE_FAILED = 'PERSON_UPDATE_FAILED'
export const PERSON_DELETE = 'PERSON_DELETE'
export const PERSON_DELETED = 'PERSON_DELETED'
export const PERSON_DELETE_FAILED = 'PERSON_DELETE_FAILED'
export const PERSONS_CHANGE_SORT_ORDER = 'PERSONS_CHANGE_SORT_ORDER'
export const PERSONS_ORDER = 'PERSONS_ORDER'

export const PROVENANCE_PERSONS_LOAD = 'PROVENANCE_PERSONS_LOAD'
export const PROVENANCE_PERSONS_LOADED = 'PROVENANCE_PERSONS_LOADED'
export const PROVENANCE_PERSONS_LOADED_ALL = 'PROVENANCE_PERSONS_LOADED_ALL'
export const PROVENANCE_PERSONS_LOAD_FAILED = 'PROVENANCE_PERSONS_LOAD_FAILED'
export const PROVENANCE_PERSONS_SEARCH = 'PROVENANCE_PERSONS_SEARCH'
export const PROVENANCE_PERSONS_SEARCHED = 'PROVENANCE_PERSONS_SEARCHED'
export const PROVENANCE_PERSONS_SEARCH_FAILED = 'PROVENANCE_PERSONS_SEARCH_FAILED'
export const PROVENANCE_PERSON_LOAD = 'PROVENANCE_PERSON_LOAD'
export const PROVENANCE_PERSON_LOADED = 'PROVENANCE_PERSON_LOADED'
export const PROVENANCE_PERSON_LOAD_FAILED = 'PROVENANCE_PERSON_LOAD_FAILED'
export const PROVENANCE_PERSON_CREATE = 'PROVENANCE_PERSON_CREATE'
export const PROVENANCE_PERSON_CREATED = 'PROVENANCE_PERSON_CREATED'
export const PROVENANCE_PERSON_CREATE_FAILED = 'PROVENANCE_PERSON_CREATE_FAILED'
export const PROVENANCE_PERSON_UPDATE = 'PROVENANCE_PERSON_UPDATE'
export const PROVENANCE_PERSON_UPDATED = 'PROVENANCE_PERSON_UPDATED'
export const PROVENANCE_PERSON_UPDATE_FAILED = 'PROVENANCE_PERSON_UPDATE_FAILED'
export const PROVENANCE_PERSON_DELETE = 'PROVENANCE_PERSON_DELETE'
export const PROVENANCE_PERSON_DELETED = 'PROVENANCE_PERSON_DELETED'
export const PROVENANCE_PERSON_DELETE_FAILED = 'PROVENANCE_PERSON_DELETE_FAILED'
export const PROVENANCE_PERSONS_CHANGE_SORT_ORDER = 'PROVENANCE_PERSONS_CHANGE_SORT_ORDER'
export const PROVENANCE_PERSONS_ORDER = 'PROVENANCE_PERSONS_ORDER'

export const PROVENANCE_INSTITUTIONS_LOAD = 'PROVENANCE_INSTITUTIONS_LOAD'
export const PROVENANCE_INSTITUTIONS_LOADED = 'PROVENANCE_INSTITUTIONS_LOADED'
export const PROVENANCE_INSTITUTIONS_LOADED_ALL = 'PROVENANCE_INSTITUTIONS_LOADED_ALL'
export const PROVENANCE_INSTITUTIONS_LOAD_FAILED = 'PROVENANCE_INSTITUTIONS_LOAD_FAILED'
export const PROVENANCE_INSTITUTIONS_SEARCH = 'PROVENANCE_INSTITUTIONS_SEARCH'
export const PROVENANCE_INSTITUTIONS_SEARCHED = 'PROVENANCE_INSTITUTIONS_SEARCHED'
export const PROVENANCE_INSTITUTIONS_SEARCH_FAILED = 'PROVENANCE_INSTITUTIONS_SEARCH_FAILED'
export const PROVENANCE_INSTITUTION_LOAD = 'PROVENANCE_INSTITUTION_LOAD'
export const PROVENANCE_INSTITUTION_LOADED = 'PROVENANCE_INSTITUTION_LOADED'
export const PROVENANCE_INSTITUTION_LOAD_FAILED = 'PROVENANCE_INSTITUTION_LOAD_FAILED'
export const PROVENANCE_INSTITUTION_CREATE = 'PROVENANCE_INSTITUTION_CREATE'
export const PROVENANCE_INSTITUTION_CREATED = 'PROVENANCE_INSTITUTION_CREATED'
export const PROVENANCE_INSTITUTION_CREATE_FAILED = 'PROVENANCE_INSTITUTION_CREATE_FAILED'
export const PROVENANCE_INSTITUTION_UPDATE = 'PROVENANCE_INSTITUTION_UPDATE'
export const PROVENANCE_INSTITUTION_UPDATED = 'PROVENANCE_INSTITUTION_UPDATED'
export const PROVENANCE_INSTITUTION_UPDATE_FAILED = 'PROVENANCE_INSTITUTION_UPDATE_FAILED'
export const PROVENANCE_INSTITUTION_DELETE = 'PROVENANCE_INSTITUTION_DELETE'
export const PROVENANCE_INSTITUTION_DELETED = 'PROVENANCE_INSTITUTION_DELETED'
export const PROVENANCE_INSTITUTION_DELETE_FAILED = 'PROVENANCE_INSTITUTION_DELETE_FAILED'
export const PROVENANCE_INSTITUTIONS_CHANGE_SORT_ORDER = 'PROVENANCE_INSTITUTIONS_CHANGE_SORT_ORDER'
export const PROVENANCE_INSTITUTIONS_ORDER = 'PROVENANCE_INSTITUTIONS_ORDER'

export const INSTITUTIONS_LOAD = 'INSTITUTIONS_LOAD'
export const INSTITUTIONS_LOADED = 'INSTITUTIONS_LOADED'
export const INSTITUTIONS_LOADED_ALL = 'INSTITUTIONS_LOADED_ALL'
export const INSTITUTIONS_LOAD_FAILED = 'INSTITUTIONS_LOAD_FAILED'
export const INSTITUTIONS_SEARCH = 'INSTITUTIONS_SEARCH'
export const INSTITUTIONS_SEARCHED = 'INSTITUTIONS_SEARCHED'
export const INSTITUTIONS_SEARCH_FAILED = 'INSTITUTIONS_SEARCH_FAILED'
export const INSTITUTION_LOAD = 'INSTITUTION_LOAD'
export const INSTITUTION_LOADED = 'INSTITUTION_LOADED'
export const INSTITUTION_LOAD_FAILED = 'INSTITUTION_LOAD_FAILED'
export const INSTITUTION_CREATE = 'INSTITUTION_CREATE'
export const INSTITUTION_CREATED = 'INSTITUTION_CREATED'
export const INSTITUTION_CREATE_FAILED = 'INSTITUTION_CREATE_FAILED'
export const INSTITUTION_UPDATE = 'INSTITUTION_UPDATE'
export const INSTITUTION_UPDATED = 'INSTITUTION_UPDATED'
export const INSTITUTION_UPDATE_FAILED = 'INSTITUTION_UPDATE_FAILED'
export const INSTITUTION_DELETE = 'INSTITUTION_DELETE'
export const INSTITUTION_DELETED = 'INSTITUTION_DELETED'
export const INSTITUTION_DELETE_FAILED = 'INSTITUTION_DELETE_FAILED'
export const INSTITUTIONS_CHANGE_SORT_ORDER = 'INSTITUTIONS_CHANGE_SORT_ORDER'
export const INSTITUTIONS_ORDER = 'INSTITUTIONS_ORDER'

export const ARCHIVES_LOAD = 'ARCHIVES_LOAD'
export const ARCHIVES_LOADED = 'ARCHIVES_LOADED'
export const ARCHIVES_LOAD_FAILED = 'ARCHIVES_LOAD_FAILED'
export const ARCHIVE_LOAD = 'ARCHIVE_LOAD'
export const ARCHIVE_LOADED = 'ARCHIVE_LOADED'
export const ARCHIVE_LOAD_FAILED = 'ARCHIVE_LOAD_FAILED'
export const ARCHIVE_CREATE = 'ARCHIVE_CREATE'
export const ARCHIVE_CREATED = 'ARCHIVE_CREATED'
export const ARCHIVE_CREATE_FAILED = 'ARCHIVE_CREATE_FAILED'
export const ARCHIVE_UPDATE = 'ARCHIVE_UPDATE'
export const ARCHIVE_UPDATED = 'ARCHIVE_UPDATED'
export const ARCHIVE_UPDATE_FAILED = 'ARCHIVE_UPDATE_FAILED'
export const ARCHIVE_ORDER = 'ARCHIVE_ORDER'

export const LITERATURES_LOAD = 'LITERATURES_LOAD'
export const LITERATURES_LOADED = 'LITERATURES_LOADED'
export const LITERATURES_LOAD_FAILED = 'LITERATURES_LOAD_FAILED'
export const LITERATURE_LOAD = 'LITERATURE_LOAD'
export const LITERATURE_LOADED = 'LITERATURE_LOADED'
export const LITERATURE_LOAD_FAILED = 'LITERATURE_LOAD_FAILED'
export const LITERATURE_CREATE = 'LITERATURE_CREATE'
export const LITERATURE_CREATED = 'LITERATURE_CREATED'
export const LITERATURE_CREATE_FAILED = 'LITERATURE_CREATE_FAILED'
export const LITERATURE_UPDATE = 'LITERATURE_UPDATE'
export const LITERATURE_UPDATED = 'LITERATURE_UPDATED'
export const LITERATURE_UPDATE_FAILED = 'LITERATURE_UPDATE_FAILED'
export const LITERATURE_ORDER = 'LITERATURE_ORDER'

export const EXHIBITIONS_LOAD = 'EXHIBITIONS_LOAD'
export const EXHIBITIONS_LOADED = 'EXHIBITIONS_LOADED'
export const EXHIBITIONS_LOADED_ALL = 'EXHIBITIONS_LOADED_ALL'
export const EXHIBITIONS_LOAD_FAILED = 'EXHIBITIONS_LOAD_FAILED'
export const EXHIBITIONS_BASIC_LOAD = 'EXHIBITIONS_BASIC_LOAD'
export const EXHIBITIONS_BASIC_LOADED = 'EXHIBITIONS_BASIC_LOADED'
export const EXHIBITIONS_BASIC_LOADED_ALL = 'EXHIBITIONS_BASIC_LOADED_ALL'
export const EXHIBITIONS_BASIC_LOAD_FAILED = 'EXHIBITIONS_BASIC_LOAD_FAILED'
export const EXHIBITION_LOAD = 'EXHIBITION_LOAD'
export const EXHIBITION_LOADED = 'EXHIBITION_LOADED'
export const EXHIBITION_LOAD_FAILED = 'EXHIBITION_LOAD_FAILED'
export const EXHIBITION_CREATE = 'EXHIBITION_CREATE'
export const EXHIBITION_CREATED = 'EXHIBITION_CREATED'
export const EXHIBITION_CREATE_FAILED = 'EXHIBITION_CREATE_FAILED'
export const EXHIBITION_UPDATE = 'EXHIBITION_UPDATE'
export const EXHIBITION_UPDATED = 'EXHIBITION_UPDATED'
export const EXHIBITION_UPDATE_FAILED = 'EXHIBITION_UPDATE_FAILED'
export const EXHIBITION_DELETE = 'EXHIBITION_DELETE'
export const EXHIBITION_DELETED = 'EXHIBITION_DELETED'
export const EXHIBITION_DELETE_FAILED = 'EXHIBITION_DELETE_FAILED'
export const EXHIBITIONS_CHANGE_SORT_ORDER = 'EXHIBITIONS_CHANGE_SORT_ORDER'
export const EXHIBITIONS_ORDER = 'EXHIBITIONS_ORDER'

export const PROFESSIONS_LOAD = 'PROFESSIONS_LOAD'
export const PROFESSIONS_LOADED = 'PROFESSIONS_LOADED'
export const PROFESSIONS_LOAD_FAILED = 'PROFESSIONS_LOAD_FAILED'

export const COUNTRIES_LOAD = 'COUNTRIES_LOAD'
export const COUNTRIES_LOADED = 'COUNTRIES_LOADED'
export const COUNTRIES_LOAD_FAILED = 'COUNTRIES_LOAD_FAILED'

export const KEYWORDS_LOAD = 'KEYWORDS_LOAD'
export const KEYWORDS_LOADED = 'KEYWORDS_LOADED'
export const KEYWORDS_LOADED_ALL = 'KEYWORDS_LOADED_ALL'
export const KEYWORDS_LOAD_FAILED = 'KEYWORDS_LOAD_FAILED'
export const KEYWORDS_SEARCH = 'KEYWORDS_SEARCH'
export const KEYWORDS_SEARCHED = 'KEYWORD_SEARCHED'
export const KEYWORDS_SEARCH_FAILED = 'KEYWORD_SEARCH_FAILED'
export const KEYWORD_LOAD = 'KEYWORD_LOAD'
export const KEYWORD_LOADED = 'KEYWORD_LOADED'
export const KEYWORD_LOAD_FAILED = 'KEYWORD_LOAD_FAILED'
export const KEYWORD_CREATE = 'KEYWORD_CREATE'
export const KEYWORD_CREATED = 'KEYWORD_CREATED'
export const KEYWORD_CREATE_FAILED = 'KEYWORD_CREATE_FAILED'
export const KEYWORD_UPDATE = 'KEYWORD_UPDATE'
export const KEYWORD_UPDATED = 'KEYWORD_UPDATED'
export const KEYWORD_UPDATE_FAILED = 'KEYWORD_UPDATE_FAILED'
export const KEYWORD_DELETE = 'KEYWORD_DELETE'
export const KEYWORD_DELETED = 'KEYWORD_DELETED'
export const KEYWORD_DELETE_FAILED = 'KEYWORD_DELETE_FAILED'
export const KEYWORDS_CHANGE_SORT_ORDER = 'KEYWORDS_CHANGE_SORT_ORDER'
export const KEYWORDS_ORDER = 'KEYWORDS_ORDER'

export const DOCUMENTS_LOAD = 'DOCUMENTS_LOAD'
export const DOCUMENTS_LOADED = 'DOCUMENTS_LOADED'
export const DOCUMENTS_LOAD_ALL = 'DOCUMENTS_LOAD_ALL'
export const DOCUMENTS_LOADED_ALL = 'DOCUMENTS_LOADED_ALL'
export const DOCUMENTS_LOAD_FAILED = 'DOCUMENTS_LOAD_FAILED'
export const DOCUMENTS_SEARCH = 'DOCUMENTS_SEARCH'
export const DOCUMENTS_SEARCHED = 'DOCUMENT_SEARCHED'
export const DOCUMENTS_SEARCH_FAILED = 'DOCUMENT_SEARCH_FAILED'
export const DOCUMENTS_ERROR = 'DOCUMENTS_ERROR'
export const DOCUMENTS_REMOVE_ERROR = 'DOCUMENTS_REMOVE_ERROR'
export const DOCUMENT_LOAD = 'DOCUMENT_LOAD'
export const DOCUMENT_LOADED = 'DOCUMENT_LOADED'
export const DOCUMENT_LOAD_FAILED = 'DOCUMENT_LOAD_FAILED'
export const DOCUMENT_CREATE = 'DOCUMENT_CREATE'
export const DOCUMENT_CREATED = 'DOCUMENT_CREATED'
export const DOCUMENT_CREATE_FAILED = 'DOCUMENT_CREATE_FAILED'
export const DOCUMENT_UPDATE = 'DOCUMENT_UPDATE'
export const DOCUMENT_UPDATED = 'DOCUMENT_UPDATED'
export const DOCUMENT_UPDATE_FAILED = 'DOCUMENT_UPDATE_FAILED'
export const DOCUMENT_DELETE = 'DOCUMENT_DELETE'
export const DOCUMENT_DELETED = 'DOCUMENT_DELETED'
export const DOCUMENT_DELETE_FAILED = 'DOCUMENT_DELETE_FAILED'
export const DOCUMENTS_CHANGE_SORT_ORDER = 'DOCUMENTS_CHANGE_SORT_ORDER'
export const DOCUMENTS_ORDER = 'DOCUMENTS_ORDER'
export const DOCUMENT_PAGE_DELETE = 'DOCUMENT_PAGE_DELETE'
export const DOCUMENT_PAGE_DELETED = 'DOCUMENT_PAGE_DELETED'
export const DOCUMENT_PAGE_DELETE_FAILED = 'DOCUMENT_PAGE_DELETE_FAILED'

export const CATALOGUES_LOAD = 'CATALOGUES_LOAD'
export const CATALOGUES_LOADED = 'CATALOGUES_LOADED'
export const CATALOGUES_LOAD_ALL = 'CATALOGUES_LOAD_ALL'
export const CATALOGUES_LOADED_ALL = 'CATALOGUES_LOADED_ALL'
export const CATALOGUES_LOAD_FAILED = 'CATALOGUES_LOAD_FAILED'
export const CATALOGUES_SEARCH = 'CATALOGUES_SEARCH'
export const CATALOGUES_SEARCHED = 'CATALOGUES_SEARCHED'
export const CATALOGUES_SEARCH_FAILED = 'CATALOGUES_SEARCH_FAILED'
export const CATALOGUE_LOAD = 'CATALOGUE_LOAD'
export const CATALOGUE_LOADED = 'CATALOGUE_LOADED'
export const CATALOGUE_LOAD_FAILED = 'CATALOGUE_LOAD_FAILED'
export const CATALOGUE_CREATE = 'CATALOGUE_CREATE'
export const CATALOGUE_CREATED = 'CATALOGUE_CREATED'
export const CATALOGUE_CREATE_FAILED = 'CATALOGUE_CREATE_FAILED'
export const CATALOGUE_UPDATE = 'CATALOGUE_UPDATE'
export const CATALOGUE_UPDATED = 'CATALOGUE_UPDATED'
export const CATALOGUE_UPDATE_FAILED = 'CATALOGUE_UPDATE_FAILED'
export const CATALOGUE_DELETE = 'CATALOGUE_DELETE'
export const CATALOGUE_DELETED = 'CATALOGUE_DELETED'
export const CATALOGUE_DELETE_FAILED = 'CATALOGUE_DELETE_FAILED'
export const CATALOGUES_CHANGE_SORT_ORDER = 'CATALOGUES_CHANGE_SORT_ORDER'
export const CATALOGUES_ORDER = 'CATALOGUES_ORDER'

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'
