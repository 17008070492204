import React from 'react'
import { Field } from 'redux-form'
import { CustomTextField } from '../general/CustomTextField'

export const PrintMeasurmentFields = () => {
  return (
    <div className="flex-box">
      <div className="repeater-field">
        <div className="field-label">
          <span className="gap-maker">Measurements</span>
        </div>
        <div>
          <div className="reference-holder  label--basic">Print</div>
        </div>
        <div>
          <div className="reference-holder  label--basic">Paper</div>
        </div>
      </div>
      <div className="repeater-field">
        <div className="field-label">Height</div>
        <div>
          <Field
            id="print_height-input"
            name="print_height"
            component={CustomTextField}
            type="text"
            label="Print height"
            hideLabel={true}
          />
        </div>
        <div>
          <Field
            id="paper_height-input"
            name="paper_height"
            component={CustomTextField}
            type="text"
            label="Paper height"
            hideLabel={true}
          />
        </div>
      </div>
      <div className="repeater-field">
        <div className="field-label">Width</div>
        <div>
          <Field
            id="print_width-input"
            name="print_width"
            component={CustomTextField}
            type="text"
            label="Print width"
            hideLabel={true}
          />
        </div>
        <div>
          <Field
            id="paper_width-input"
            name="paper_width"
            component={CustomTextField}
            type="text"
            label="Paper width"
            hideLabel={true}
          />
        </div>
      </div>
    </div>
  )
}
