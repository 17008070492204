import * as React from 'react'
import { Field } from 'redux-form'
import { DATE_TYPE_UNCERTAIN, DATE_TYPE_YEAR_MONTH, DATE_TYPE_YEAR_MONTH_DAY } from '../../config/config'
import { MenuItem } from '@material-ui/core'
import { CustomSelect } from '../general/CustomSelect'
import { CustomTextField } from '../general/CustomTextField'

export const AccurateDate = ({ accuracyField, dateField, accuracyTitle, accuracies }) => (
  <Field
    name={accuracyField}
    component={({ input }) => (
      <React.Fragment>
        <div className="repeater-field">
          <div className="field-label">{accuracyTitle}</div>
          <Field name={accuracyField} component={CustomSelect} >
            {accuracies.map((item, i) => (
              <MenuItem key={i} value={item.value}>
                {item.title}
              </MenuItem>
            ))}
          </Field>
        </div>
        {input.value !== DATE_TYPE_UNCERTAIN ? (
          <div className="repeater-field short">
            <Field
              id={`${dateField}_year-input`}
              name={`${dateField}_year`}
              label="Year"
              component={CustomTextField}
              width="short"
            />
          </div>
        ) : null}

        {input.value === DATE_TYPE_YEAR_MONTH || input.value === DATE_TYPE_YEAR_MONTH_DAY ? (
          <div className="repeater-field short">
            <Field
              id={`${dateField}_month-input`}
              name={`${dateField}_month`}
              label="Month"
              component={CustomTextField}
              width="short"
            />
          </div>
        ) : null}

        {input.value === DATE_TYPE_YEAR_MONTH_DAY ? (
          <div className="repeater-field short">
            <Field
              id={`${dateField}_day-input`}
              name={`${dateField}_day`}
              label="Day"
              component={CustomTextField}
              width="short"
            />
          </div>
        ) : null}
      </React.Fragment>
    )}
  />
)
