import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FieldArray } from 'redux-form'
import { Close } from '@material-ui/icons'
import { Tooltip, IconButton, TextField } from '@material-ui/core'
import Modal from 'react-modal'
import Downshift from 'downshift'
import { RepeaterDefaultAdd } from './Repeater'
import { ReferenceField } from './ReferenceField'
import { searchArtworks } from '../../actions/artworksActions'
import { modalStyle } from './style'
import { getArtworkImage, getArtworkTitle, getArtworkTitleFromTranslationsArr } from '../../utils/utils'
import { loadingState } from '../../utils/loadingState'
import OpenClose from '../general/OpenClose'
import { ARTWORK_FORM } from '../../services/forms'
import TextButton from '../general/TextButton'
import { useStyles } from '../general/CustomTextField'

const List = ({ items, getItemProps }) => (
  <div>
    {items.map((item, index) => (
      <div {...getItemProps({ item })} key={index} className="related-item flex-box flex-box-centered">
        <img className="table-img-holder" src={getArtworkImage(item)} alt="" />
        {getArtworkTitle(item)}
      </div>
    ))}
  </div>
)

const ArtworksAutoComplete = ({ items, search, onChange, searchState }) => {
  const classes = useStyles()
  return (
    <Downshift itemToString={(x) => (x ? x.id : '')} onInputValueChange={search} onChange={onChange}>
      {({ getInputProps, getItemProps, getLabelProps, isOpen }) => (
        <div>
          <label className="field-label" htmlFor="related-artwork-input" {...getLabelProps()}>
            Search artworks
          </label>
          <TextField
            id="related-artwork-input"
            {...getInputProps()}
            type="text"
            InputProps={{
              classes: {
                input: classes.input,
                underline: classes.underline,
              },
            }}
            classes={{
              root: `${classes.root} ${classes.fullWidth}`,
            }}
          />
          {isOpen ? (
            searchState === loadingState.LOADING ? (
              <span>Searching...</span>
            ) : items === undefined || items.length === 0 ? (
              <span>No results</span>
            ) : (
              <List getItemProps={getItemProps} items={items} />
            )
          ) : null}
        </div>
      )}
    </Downshift>
  )
}

const ArtworkThumbnails = ({ fields, artworks, search, searchState, index, remove }) => (
  <div key={index} className="select-reference repeater-field">
    <OpenClose>
      {({ isOpen, open, close }) => (
        <div>
          {!isOpen && (
            <div>
              <p>{getArtworkTitleFromTranslationsArr(fields.get(index)) || getArtworkTitle(fields.get(index))}</p>
              <div className="img-holder">
                <img src={getArtworkImage(fields.get(index))} alt="" />
                <div className="hover-background" onClick={open}>
                  <Tooltip title="Remove">
                    <IconButton onClick={remove}>
                      <Close />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              <ReferenceField name={`references.related_artwork_${index}`} formType={ARTWORK_FORM} />
            </div>
          )}
          {isOpen && (
            <Modal isOpen={isOpen} onRequestClose={close} {...modalStyle}>
              <ArtworksAutoComplete
                items={artworks}
                search={search}
                searchState={searchState}
                onChange={(item) => {
                  remove()
                  fields.push(item)
                  close()
                }}
              />
              <div className="right-controls">
                <TextButton label="Done" color="primary" onClick={close} type="button" />
              </div>
            </Modal>
          )}
        </div>
      )}
    </OpenClose>
  </div>
)

const FieldsContainer = ({ fields, isOpen, open, close }) => {
  const artworks = useSelector((state) => state.artworks.searchItems)
  const error = useSelector((state) => state.artworks.searchError)
  const searchState = useSelector((state) => state.artworks.searchState)
  const dispatch = useDispatch()
  const search = (query) => {
    dispatch(searchArtworks(query))
  }

  return (
    <div className="repeater">
      {fields.map((field, index) => (
        <ArtworkThumbnails
          key={index}
          fields={fields}
          index={index}
          isOpen={isOpen}
          open={open}
          close={close}
          artworks={artworks}
          search={search}
          searchState={searchState}
          remove={() => fields.remove(index)}
        />
      ))}
      <RepeaterDefaultAdd fields={fields} addFn={open} label="Add related artwork" />
      <Modal isOpen={isOpen} onRequestClose={close} {...modalStyle}>
        <ArtworksAutoComplete
          items={artworks}
          search={search}
          searchState={searchState}
          onChange={(item) => {
            fields.push(item)
            close()
          }}
        />
        <div className="right-controls">
          <TextButton label="Done" color="primary" onClick={close} type="button" />
        </div>
      </Modal>
    </div>
  )
}

const RelatedArtworks = (props) => (
  <OpenClose>
    {({ isOpen, open, close }) => (
      <div className="flex-box">
        <FieldArray
          name="related_artworks"
          {...props}
          isOpen={isOpen}
          open={open}
          close={close}
          component={FieldsContainer}
        />
      </div>
    )}
  </OpenClose>
)

export default RelatedArtworks
