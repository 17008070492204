import * as actionTypes from '../actions/actionTypes'
import { loadingState } from '../utils/loadingState'
import { removeFromCache, updateMapCache, updatePagesCache } from '../utils/cache'
import { itemsOrder } from './crud'

const initialState = {
  items: {},
  pages: {},
  page: 0,
  total: 0,
  state: loadingState.IDLE,
  deleteState: loadingState.IDLE,
  deleteError: undefined,
  error: undefined
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USERS_LOAD:
      return { ...state, state: loadingState.LOADING, page: action.payload }
    case actionTypes.USERS_LOADED:
      return {
        ...state,
        state: loadingState.LOADED,
        page: action.payload.page,
        total: action.payload.total,
        items: updateMapCache(action.payload.items, state.items),
        pages: updatePagesCache(action.payload.page, action.payload.items, state.pages)
      }
    case actionTypes.USER_LOADED:
      return {
        ...state,
        items: updateMapCache([action.payload], state.items)
      }
    case actionTypes.USER_DELETE:
      return { ...state, deleteState: loadingState.LOADING, deleteError: undefined }
    case actionTypes.USER_DELETED:
      return {
        ...state,
        items: removeFromCache(action.payload, state.items),
        state: loadingState.IDLE, //so redux form does not reload
        deleteState: loadingState.LOADED,
        deleteError: undefined
      }
    case actionTypes.USERS_LOAD_FAILED:
      return { ...state, state: loadingState.FAILED, error: action.payload }
    case actionTypes.USERS_ORDER:
      return itemsOrder(state, action.payload)
    default:
      return state
  }
}
