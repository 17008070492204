import { InstitutionForm } from '../form/InstitutionForm'
import { INSTITUTION_FORM } from '../../../services/forms'
import { createInstitution } from '../../../actions/institutionsActions'
import { makeCreate } from '../../general/Create'
import { uploadOwnerPhoto } from '../../../services/http'

const options = {
  model: 'institutions',
  component: InstitutionForm,
  form: INSTITUTION_FORM,
  initialValues: {},
  upload: uploadOwnerPhoto,
  submitFn: createInstitution,
  title: 'Create Institution'
}

export const InstitutionCreate = makeCreate(options)
