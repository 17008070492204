import React from 'react'
import ExhibitionForm from '../form/ExhibitionForm'
import { createExhibition } from '../../../actions/exhibitionsActions'
import { loadPersons } from '../../../actions/personsActions'
import { exhibitionModel } from '../../../models/exhibitionModel'
import { EXHIBITION_FORM } from '../../../services/forms'
import { change, isDirty, submit } from 'redux-form'
import FormTop from '../../form/FormTop'
import NavigationPrompt from '../../general/NavigationPrompt'
import { EditTop } from '../../general/EditTop'
import { loadInstitutions } from '../../../actions/institutionsActions'
import { useDispatch, useSelector } from 'react-redux'
import DataProviderFunctional from '../../loader/DataProviderFunctional'

const ExhibitionEditPresentation = () => {
  const initialValues = exhibitionModel
  const lang = useSelector((state) => state.translations.language)
  const dirty = useSelector((state) => isDirty(EXHIBITION_FORM)(state))
  const saveState = useSelector((state) => state.exhibitions.saveState)
  const persons = useSelector((state) => state.persons.items)
  const institutions = useSelector((state) => state.institutions.items)
  const dispatch = useDispatch()
  const onSubmit = (data) => dispatch(createExhibition(data))
  const changeFieldValue = (field, value) => dispatch(change(EXHIBITION_FORM, field, value))
  const remoteSubmit = () => dispatch(submit(EXHIBITION_FORM))

  return <div className="">
    <div className="Exhibition-controls">
      <EditTop title="Create Exhibition" />
      <div className="controls">
        <FormTop title="" button="Save exhibition" remote={remoteSubmit} saveState={saveState} />
      </div>
    </div>

    <div className="exhibition-grid">
      <NavigationPrompt when={dirty} />
      <ExhibitionForm
        lang={lang}
        onSubmit={onSubmit}
        changeFieldValue={changeFieldValue}
        initialValues={initialValues}
        persons={persons}
        institutions={institutions}
      />
    </div>
  </div>
}

const ExhibitionCreate = () => {
  const loading = useSelector((state) => state.exhibitions.state)
  const error = useSelector((state) => state.exhibitions.error)
  const dispatch = useDispatch()
  const load = () => {
    dispatch(loadPersons())
    dispatch(loadInstitutions())
  }

  return <DataProviderFunctional error={error} load={load} loading={loading} component={ExhibitionEditPresentation} />
}

export default ExhibitionCreate
