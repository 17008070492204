import React from 'react'
import { Field, Fields, reduxForm } from 'redux-form'
import { MenuItem } from '@material-ui/core'
import MapField from '../../form/MapField'
import { ARTWORK_FORM } from '../../../services/forms'
import { ArtworkExhibitions } from '../../form/ArtworkExhibitions'
import CheckBoxList from '../../form/CheckBoxList'
import { LOCATIONS, MATERIALS, MOTIFS, TECHNIQUES, TYPES } from '../../../config/config'
import RadioList from '../../form/RadioList'
import ArtworkTitles from '../../form/ArtworkTitles'
import ArtworkSignatures from '../../form/ArtworkSignatures'
import ArtworkInscriptions from '../../form/ArtworkInscriptions'
import './ArtworkForm.css'
import ArtworkDate from '../../form/ArtworkDate'
import RelatedArtworks from '../../form/RelatedArtworks'
import IdList from '../../form/IdList'
import ArtworkPhotos from '../../form/ArtworkPhotos'
import { textareaStyle } from '../../form/style'
import ArtworkCommentaries from '../../form/ArtworkCommentaries'
import { TechnicalDescription } from '../../form/TechnicalDescription'
import TechnicalCommentaries from '../../form/TechnicalCommentaries'
import ArtworkAuthenticity from '../../form/ArtworkAuthenticity'
import { ReferenceFieldList } from '../../form/ReferencesListField'
import { ArtworkProvenanceField } from '../../form/ArtworkProvenance'
import ArtworkWhereabouts from '../../form/ArtworkWhereabouts'
import { MeasurmentFields } from '../../form/MeasurementFields'
import MarkupEditor from '../../form/MarkupEditor'
import ArtworkBookImages from '../../form/ArtworkBookImages'
import { ArtworkKeywordField } from '../../form/ArtworkKeyword'
import { ArchiveReferenceFieldList } from '../../form/ArchiveReferencesListField'
import { useSelector } from 'react-redux'
import { DEFAULT, OTHER } from '../../../utils/constants'
import { CustomSelect } from '../../general/CustomSelect'

const ArtworkFormPresentation = ({ handleSubmit, lang, upload, changeFieldValue, type, initialValues }) => {
  const database = useSelector((state) => state.auth.database)
  const locations = LOCATIONS[database] ?? LOCATIONS[DEFAULT]

  return (
    <form id="artwork-form" className="artwork-form" onSubmit={handleSubmit}>
      <div id="basics" className="section-inside">
        <h1>Basics</h1>
        <div className="row">
          <div className="field-label" htmlFor="type-select">Type</div>
          <Field
            onChange={() => {
              changeFieldValue('techniques', [])
              changeFieldValue('materials', [])
            }}
            name="type"
            component={CustomSelect}
          >
            {TYPES.map((item, i) => (
              <MenuItem key={i} value={item.value}>
                {item.title}
              </MenuItem>
            ))}
          </Field>
        </div>
        <div className="row">
          <h3 className="section-title">IDs</h3>
          <IdList />
        </div>
        <div className="row">
          <h3 className="section-title">Titles</h3>
          <ArtworkTitles lang={lang} />
        </div>

        <div className="row">
          <h3 className="section-title">Date</h3>
          <ArtworkDate />
        </div>

        <div className="row">
          <ArtworkSignatures />
        </div>

        <div className="row">
          <ArtworkInscriptions />
        </div>
        <ArtworkAuthenticity />
        <ArtworkWhereabouts />
        {/* <ArtworkThemes /> */}
      </div>
      <div id="technical" className="section-inside">
        <h1>Technical description</h1>
        <div className="row">
          <div>
            <div className="field-label">Techniques</div>
            <CheckBoxList name="techniques" items={TECHNIQUES[type]} titleField="technique" />
          </div>
          <div>
            <div className="field-label">Support</div>
            <CheckBoxList name="materials" items={MATERIALS[type]} titleField="material" />
          </div>

          <div>
            <TechnicalDescription />
          </div>

          <div className="row">
            <div className="field-label">Technical commentary</div>
            <TechnicalCommentaries lang={lang} />
          </div>

          <div className="section-title">Measurements</div>
          <MeasurmentFields type={type} />
        </div>
      </div>
      <div id="description" className="section-inside">
        <h1>Description</h1>
        <div>
          <div className="field-label">Motifs</div>
          <CheckBoxList name="motifs" items={MOTIFS} titleField="motif" />
        </div>
        <div className="row">
          <div className="field-label">Keywords</div>
          <ArtworkKeywordField />
        </div>
        <div className="row">
          <div className="field-label">Norwegian Description</div>
          <div className="rich-text-editor">
            <Field
              name={`translations.nb.body`}
              multiLine={true}
              {...textareaStyle}
              minRows={3}
              component={MarkupEditor}
            />
          </div>
        </div>
        <div className="row">
          <div className="field-label">English Description</div>
          <div className="rich-text-editor">
            <Field
              name={`translations.en.body`}
              multiLine={true}
              {...textareaStyle}
              minRows={3}
              component={MarkupEditor}
            />
          </div>
        </div>

        <div className="row">
          <ArtworkCommentaries lang={lang} />
        </div>

        <div className="row">
          <h3 className="section-title">Location</h3>
          <RadioList
            name="location"
            items={locations}
            fieldNameOther="location_other_text"
            labelOther="Other location, specify:"
            relevantValue={OTHER}
            formName={ARTWORK_FORM}
          />
          <Fields names={['latitude', 'longitude']} component={MapField} changeFieldValue={changeFieldValue} />
        </div>
      </div>

      <div id="images" className="section-inside">
        {type === 'book' ? (
          <ArtworkBookImages upload={upload} lang={lang} />
        ) : (
          <ArtworkPhotos upload={upload} lang={lang} />
        )}
        <div className="row">
          <div className="section-title">Related artworks</div>
          <RelatedArtworks />
        </div>
      </div>

      <div id="history" className="section-inside">
        <h1>History</h1>

        <div className="row section-spacing ">
          <ArtworkProvenanceField />
        </div>

        <div className="row section-spacing">
          <ArtworkExhibitions />
        </div>
      </div>

      <div id="references" className="section-inside">
        <h1>References</h1>
        <ReferenceFieldList name={`references.references`} formType={ARTWORK_FORM} />
        <ArchiveReferenceFieldList name={`references_documents.general`} formType={ARTWORK_FORM} />
      </div>
    </form>
  )
}

const formProps = {
  form: ARTWORK_FORM,
  enableReinitialize: true,
  destroyOnUnmount: false,
  fields: ['published', 'reviewed'],
  //references: references,
}

const ArtworkForm = reduxForm(formProps)(ArtworkFormPresentation)

export default ArtworkForm
