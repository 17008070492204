import React from 'react'
import { Field, FieldArray } from 'redux-form'
import { Uploader, ReUploader } from './Uploader'
import OpenClose from '../general/OpenClose'
import Modal from 'react-modal'
import { PhotoHotSpots } from './PhotoHotspots'
import { IconButton, Tooltip } from '@material-ui/core'
import { Close, Delete } from '@material-ui/icons'
import OutlinedButton from '../general/OutlinedButton'
import { CustomRefreshIndicator } from '../general/CustomRefreshIndicator'
import { CustomTextField } from '../general/CustomTextField'
import { CustomTextArea } from '../general/CustomTextArea'

const findByType = (fields, type) => (fields ? fields.find((x) => x.type === type) : null)
const findIndexByType = (fields, type) => (fields ? fields.findIndex((x) => x.type === type) : null)

const createImage = (image, type) => ({ ...image, type })

const ProgressBarSmall = () => (
  <div className="img-holder upload-loader">
    <CustomRefreshIndicator size={43} small={true} />
  </div>
)

const Image = ({ fields, upload, image, onRemove, onUpload, onReUpload, title, lang }) => {
  return image === null || image === undefined || image.url === undefined || image.url === null ? (
    <div>
      <Uploader
        style={{ margin: '0 auto' }}
        upload={upload}
        onSuccess={onUpload}
        loader={CustomRefreshIndicator}
        onStart={console.log}
        onError={console.log}
      />
    </div>
  ) : (
    <OpenClose>
      {({ isOpen, open, close }) => (
        <div>
          <div className="img-holder">
            <img src={image.thumb_url} alt="" />
            <div className="hover-background" onClick={open}></div>
          </div>

          {isOpen && (
            <Modal
              isOpen={isOpen}
              onRequestClose={close}
              className={{
                base: 'modal',
                afterOpen: 'modal-open reference-modal photo-modal',
                beforeClose: 'modal-close',
              }}
              overlayClassName={{
                base: 'modal-overlay',
                afterOpen: 'modal-overlay-open',
                beforeClose: 'modal-overlay-close',
              }}
            >
              <ImagePreview
                upload={upload}
                title={title}
                image={image}
                close={close}
                remove={onRemove}
                onUpload={onReUpload}
                member={fields && image ? fields.getAll().findIndex((x) => x.id === image.id) : null}
                lang={lang}
              />
            </Modal>
          )}
        </div>
      )}
    </OpenClose>
  )
}

const Img = ({ upload, fields, title, type, lang }) => {
  const image = findByType(fields.getAll(), type)
  const index = findIndexByType(fields.getAll(), type)
  const onUpload = (image) => {
    fields.push(createImage(image, type))
  }
  const onReUpload = (image) => {
    fields.remove(index)
    fields.push(createImage(image, type))
  }
  const onRemove = () => fields.remove(index)
  return (
    <div className="single-img">
      <div className="image-label">{title}</div>
      <Image
        fields={fields}
        upload={upload}
        image={image}
        onUpload={onUpload}
        onReUpload={onReUpload}
        onRemove={onRemove}
        title={title}
        lang={lang}
      />
      {index >= 0 ? (
        <div>
          <Field
            id={`images[${index}].translations.text_nb-input`}
            name={`images[${index}].translations.text_nb`}
            minRows={2}
            maxRows={10}
            component={CustomTextArea}
            label="Additional information (NB)"
            type="text"
          />
          <Field
            id={`images[${index}].translations.text_en-input`}
            name={`images[${index}].translations.text_en`}
            minRows={2}
            maxRows={10}
            component={CustomTextArea}
            label="Additional information (EN)"
            type="text"
          />
        </div>
      ) : null}
      {index >= 0 ? (
        <div>
          <Field
            id={`images[${index}].translations.photo_credit_${lang}-input`}
            name={`images[${index}].translations.photo_credit_${lang}`}
            minRows={1}
            maxRows={4}
            component={CustomTextArea}
            label="Photo credit"
            type="text"
          />
        </div>
      ) : null}
    </div>
  )
}
const PageImg = ({ upload, member, index, fields, image, lang }) => {
  const onRemove = () => fields.remove(index)
  return (
    <div className="single-img">
      <Image
        image={image}
        fields={fields}
        upload={upload}
        onUpload={(x) => {
          fields.remove(index)
          fields.push(createImage(x, 'page'))
        }}
        onRemove={onRemove}
        onReUpload={(x) => {
          fields.remove(index)
          fields.push(createImage(x, 'page'))
        }}
      />
      <div>
        <div>
          <Field
            id={`${member}.page_number-input`}
            name={`${member}.page_number`}
            label="Page Number"
            component={CustomTextField}
          />
        </div>
        <Field
          id={`${member}.translations.text_nb-input`}
          name={`${member}.translations.text_nb`}
          minRows={2}
          maxRows={10}
          component={CustomTextArea}
          label="Additional information (NB)"
          type="text"
        />
        <Field
          id={`${member}.translations.text_en-input`}
          name={`${member}.translations.text_en`}
          minRows={2}
          maxRows={10}
          component={CustomTextArea}
          label="Additional information (EN)"
          type="text"
        />
      </div>
      <div>
        <Field
          id={`${member}.translations.photo_credit_${lang}-input`}
          name={`${member}.translations.photo_credit_${lang}`}
          minRows={1}
          maxRows={4}
          component={CustomTextArea}
          label="Photo credit"
          type="text"
        />
      </div>
    </div>
  )
}

const ImagePreview = ({ upload, title, image, close, remove, onUpload, member, lang }) => {
  return (
    <div className="image-preview">
      <div className="left-right-block flex-box-centered">
        <div className="image-label">{title}</div>
        <div className="right-controls flex-box-centered">
          <ReUploader
            upload={upload}
            onSuccess={onUpload}
            loader={ProgressBarSmall}
            onStart={console.log}
            onError={console.log}
          />
          <Tooltip title="Remove">
            <IconButton
              onClick={remove}
              style={{
                padding: 0,
                marginLeft: '12px',
                marginRight: '12px',
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
          <PhotoHotSpots key={member} image={image} member={`images[${member}]`} lang={lang} />
          <Tooltip title="Close">
            <IconButton
              onClick={close}
              style={{
                padding: 0,
              }}
            >
              <Close />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <img src={image.thumb_url} alt="" />
    </div>
  )
}

const isMainImage = (image) => image.type === 'photo_1' || image.type === 'photo_2' || image.type === 'photo_3'

const Images = ({ upload, fields, lang }) => (
  <div>
    <h1>Images (Covers)</h1>
    <div className="row">
      <div className="flex-box">
        <Img upload={upload} fields={fields} type="photo_1" title="Front side" lang={lang} />
        {/* <Img upload={upload} fields={fields} type="photo_2" title="Front side with frame" lang={lang} /> */}
        <Img upload={upload} fields={fields} type="photo_3" title="Back side" lang={lang} />
      </div>
    </div>
    <h1>Images (Pages): </h1>
    <div className="row">
      <div className="flex-box">
        {fields.map((member, index, fields) => {
          const image = fields.get(index)
          if (isMainImage(image)) {
            return null
          }
          return (
            <PageImg
              key={index}
              upload={upload}
              member={member}
              image={image}
              index={index}
              fields={fields}
              lang={lang}
            />
          )
        })}
        <div style={{ margin: '10px' }}>
          <OutlinedButton color="secondary" label="Add new image" onClick={() => fields.push({ type: 'page' })} />
        </div>
      </div>
    </div>
  </div>
)

const ArtworkBookImages = ({ upload, lang }) => (
  <FieldArray name="images" component={Images} upload={upload} lang={lang} />
)

export default ArtworkBookImages
