import * as React from 'react'
import Modal from 'react-modal'
import { FieldArray } from 'redux-form'
import TextButton from '../general/TextButton'
import OutlinedButton from '../general/OutlinedButton'
import { Chip, Checkbox, makeStyles, FormControlLabel } from '@material-ui/core'
import { COLOR_GREY, COLOR_LIGHT_TEXT } from '../../config/colors'

const findIndex = (items, item, titleField) => (items ? items.findIndex((i) => i[titleField] === item) : -1)
const isChecked = (items, item, titleField) => findIndex(items, item, titleField) !== -1
const toggle = (fields, item, titleField) => {
  const index = findIndex(fields.getAll(), item, titleField)
  index === -1 ? fields.push({ [titleField]: item }) : fields.remove(index)
}

const useStyles = makeStyles((theme) => ({
  chip: {
    backgroundColor: COLOR_GREY,
    '& .MuiChip-label': {
      color: COLOR_LIGHT_TEXT,
    },
  },
  checkbox: {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
    padding: 2,
    marginRight: 16,
  },
  labelCheckbox: {
    color: COLOR_LIGHT_TEXT,
    marginLeft: 0,
  },
}))

const List = ({ fields, titleField, list }) => {
  const classes = useStyles()

  return (
    <div className="flex-box" style={{ marginBottom: '10px' }}>
      {list && fields.length > 0 ? (
        fields.map((member, index, fields) => {
          const field = list.filter((i) => i.value === fields.get(index)[titleField])[0] ?? {}
          return (
            <Chip
              key={index}
              className={`${classes.chip} repeater-field`}
              onDelete={() => fields.remove(index)}
              label={list ? field['title'] : ''}
            ></Chip>
          )
        })
      ) : (
        <div className="dark-text">None selected</div>
      )}
    </div>
  )
}

const SelectList = ({ fields, items, titleField }) => {
  const classes = useStyles()
  const handleCheckboxChange = (item) => {
    toggle(fields, item.value, titleField)
  }

  return (
    <div>
      {items
        ? items.map((item, i) => (
            <div key={i} className={item.indent ? `indent-${item.indent}` : ''}>
              {item.parent ? (
                <div className="parent-box">{item.title}</div>
              ) : (
                <FormControlLabel
                  className={classes.labelCheckbox}
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      checked={isChecked(fields.getAll(), item.value, titleField)}
                      onChange={() => handleCheckboxChange(item)}
                    />
                  }
                  label={item.title}
                />
              )}
            </div>
          ))
        : 'Select type first'}
    </div>
  )
}

class CheckBoxList extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: false }
  }

  open = () => this.setState({ isOpen: true })

  close = () => this.setState({ isOpen: false })

  render() {
    return (
      <div className="row">
        <FieldArray
          name={this.props.name}
          component={List}
          titleField={this.props.titleField}
          list={this.props.items}
        />
        <div className="margin-top">
          <OutlinedButton color="secondary" onClick={this.open} label="Edit" />
        </div>
        <Modal
          isOpen={this.state.isOpen}
          onRequestClose={this.close}
          className={{
            base: 'modal',
            afterOpen: 'modal-open',
            beforeClose: 'modal-close',
          }}
          overlayClassName={{
            base: 'modal-overlay',
            afterOpen: 'modal-overlay-open',
            beforeClose: 'modal-overlay-close',
          }}
        >
          <FieldArray name={this.props.name} component={SelectList} {...this.props} />
          <div className="right-controls">
            <TextButton label="Done" color="primary" onClick={this.close} type="button" />
          </div>
        </Modal>
      </div>
    )
  }
}

export default CheckBoxList
