import React from 'react' 
import { useDispatch, useSelector } from 'react-redux' 
import ArtworkForm from '../form/ArtworkForm' 
import { createArtwork } from '../../../actions/artworkActions' 
import { artwork } from '../../../models/artworkModel' 
import { ARTWORK_FORM } from '../../../services/forms' 
import { change, formValueSelector, isDirty, submit } from 'redux-form' 
import { uploadFile } from '../../../services/http' 
import FormTop from '../../form/FormTop' 
import NavigationPrompt from '../../general/NavigationPrompt' 
import Tabs from '../edit/Tabs' 
import { EditTop } from '../../general/EditTop' 
import { artworkToServer } from '../../../utils/utils' 

const ArtworkCreatePresentation = () => {
  const dispatch = useDispatch() 
  const initialValues = artwork
  const lang = useSelector(state => state.translations.language) 
  const type = useSelector(state => formValueSelector(ARTWORK_FORM)(state, 'type')) 
  const dirty = useSelector(state => isDirty(ARTWORK_FORM)(state)) 
  const saveState = useSelector(state => state.artwork.saveState) 

  const onSubmit = data => {
    dispatch(createArtwork(artworkToServer(data))) 
  } 

  const changeFieldValue = (field, value) => {
    dispatch(change(ARTWORK_FORM, field, value)) 
  }

  const remoteSubmit = () => {
    dispatch(submit(ARTWORK_FORM)) 
  } 

  return (
    <div className="page-content">
      <EditTop title="Create artwork" />
      <div className="controls">
        <FormTop
          title={initialValues.translations[lang].title}
          button="Save artwork"
          remote={remoteSubmit}
          saveState={saveState}
        />
        <Tabs />
      </div>
      <div className="artwork-grid">
        <NavigationPrompt when={dirty} />
        <ArtworkForm
          type={type}
          lang={lang}
          onSubmit={onSubmit}
          changeFieldValue={changeFieldValue}
          initialValues={initialValues}
          upload={uploadFile}
          remoteSubmit={remoteSubmit}
          dirty={dirty}
          saveState={saveState}
        />
      </div>
    </div>
  ) 
} 

export default ArtworkCreatePresentation 