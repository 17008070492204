import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Link, scroller } from 'react-scroll'
import { COLOR_LIGHT_TEXT } from '../../../config/colors'

const Tabs = () => {
  const history = useHistory()
  const [activeHash, setActiveHash] = useState(history.location.hash)
  const headerOffset = -300
  const colorAccent = useSelector((state) => state.auth.colorAccent)


  useEffect(() => {
    const unlisten = history.listen((location) => {
      setActiveHash(location.hash)
    })

    const hash = location.hash.substring(1);
    if (hash) {
      setTimeout(() => {
        scrollToSection(hash)
      }, 600)
    }

    return () => {
      unlisten()
    }
  }, [history])

  const scrollToSection = (sectionId) => {
    scroller.scrollTo(sectionId, {
      smooth: true,
      duration: 600,
      offset: headerOffset,
    })
  }

  const links = [
    { id: 'basics', label: 'Basics' },
    { id: 'technical', label: 'Technical description' },
    { id: 'description', label: 'Description' },
    { id: 'images', label: 'Images' },
    { id: 'history', label: 'History' },
    { id: 'references', label: 'References' },
  ]

  const tabActiveStyles = {
    borderBottom: `2px solid ${colorAccent}`,
    color: colorAccent
  }
  const tabStyles = {
    borderBottom: 'transparent',
    color: COLOR_LIGHT_TEXT
  }

  return (
    <div className="flex-box-centered tabs">
      {links.map((link) => (
        <Link
          key={link.id}
          to={link.id}
          smooth={true}
          duration={600}
          offset={headerOffset}
          className={`tabs__link ${activeHash === `#${link.id}` ? 'active' : ''}`}
          style={activeHash === `#${link.id}` ? tabActiveStyles : tabStyles }
          onClick={() => history.push(`#${link.id}`)}
        >
          {link.label}
        </Link>
      ))}
    </div>
  )
}

export default Tabs
