import * as actionTypes from '../actions/actionTypes'
import { COLOR_ACCENT_DEFAULT } from '../config/colors'
import { getAccentColor } from '../utils/utils'

export const AUTH_STATE_UNKNOWN = 'AUTH_STATE_UNKNOWN'
export const AUTH_STATE_LOGGED_IN = 'AUTH_STATE_LOGGED_IN'
export const AUTH_STATE_LOGGED_OUT = 'AUTH_STATE_LOGGED_OUT'

const initialState = {
  state: AUTH_STATE_UNKNOWN,
  loading: false,
  error: undefined,
  token: undefined,
  user: undefined,
  database: undefined,
  colorAccent: COLOR_ACCENT_DEFAULT,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_LOGIN:
      return { ...state, error: undefined, loading: true }
    case actionTypes.AUTH_LOGGED_IN:
      const database = action.payload.database
      const colorAccent = getAccentColor(database)
      return {
        ...state,
        error: undefined,
        loading: false,
        token: action.payload.token,
        user: action.payload.user,
        database,
        colorAccent,
        state: AUTH_STATE_LOGGED_IN
      }
    case actionTypes.AUTH_LOGOUT:
      return { ...state, error: undefined, loading: true }
    case actionTypes.AUTH_LOGGED_OUT:
      return {
        ...state,
        error: undefined,
        loading: false,
        token: undefined,
        user: undefined,
        database: undefined,
        colorAccent: COLOR_ACCENT_DEFAULT,
        state: AUTH_STATE_LOGGED_OUT
      }
    case actionTypes.AUTH_LOGIN_FAILED:
      return { ...state, error: action.payload, loading: false }
    default:
      return state
  }
}
